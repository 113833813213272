/* eslint-disable react/no-unstable-nested-components */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Alert } from 'components/Alert';
import { Col, Row, Text, type ColSize, type ColOffset } from 'components/Layout';

import { generateId } from 'utilsTS/string';
import {
    cloneOrRender,
    isElementOfComponent,
    mergeProps,
    omitProps,
    type ElementOrComponent,
} from 'utilsTS/react';
import { elementOfType } from 'utilsTS/prop-types';

import { FormAlerts } from './components';
import { FormContext } from './FormContext';
import { FormLabel } from './FormLabel';
import styles from './Form.scss';

// TODO: refactor after Input migrated to TS - Partial<TextInputProps>
type NativeTextInput = React.FC<React.ComponentPropsWithoutRef<'input'>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputPropsObject = Record<string, any>;

export enum FORM_FIELD_VARIANT {
    DEFAULT = 'default',
    NO_LAYOUT = 'no-layout',
    LARGE = 'large',
}

export enum FORM_FIELD_LABEL_POSITION {
    ASIDE = 'aside',
    ABOVE = 'above',
    INSIDE = 'inside',
}

export interface FormFieldProps<T = NativeTextInput> extends React.ComponentPropsWithoutRef<'div'> {
    /** Defines the visual variant of the field */
    variant?: FORM_FIELD_VARIANT;
    /** Defines the position of the label */
    labelPosition?: FORM_FIELD_LABEL_POSITION;
    /** Accepts the input component to be rendered */
    input?: ElementOrComponent | React.ComponentType<T>;
    /** Accepts additional props to be spread to the input component */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputProps?: T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
        ? React.ComponentProps<T>
        : InputPropsObject;
    /** Defines whether the field should be required */
    required?: boolean;
    /** Defines whether the field should be disabled */
    disabled?: boolean;
    /** Defines name attribute passed to the input */
    name?: string;
    /** Defines whether the field should be visually compact */
    compact?: boolean;
    /** Defines the label of the field */
    label?: React.ReactNode;
    /** Defines the string replacing the "optional" label */
    optionalLabel?: React.ReactNode;
    /** Defines placeholder attribute passed to the input  */
    placeholder?: string;
    /** Defines whether the input is invalid */
    invalid?: boolean;
    /** Accepts an Alert component to be rendered */
    alert?: React.ReactNode;
    /** Defines the wording on an error Alert */
    error?: React.ReactNode;
    /** Defines the wording on an warning Alert */
    warning?: React.ReactNode;
    /** Defines the wording on an info Alert */
    info?: React.ReactNode;
    /** Defines URL of an image on an info Alert */
    infoFlagUrl?: string;
    /** Defines description to be rendered below the input */
    description?: React.ReactNode;
    /** Defines the column width of the label */
    labelColumnWidth?: ColSize;
    /** Accepts additional className for the label */
    labelClassName?: string;
    /** Accepts additional className for the input container */
    contentClassName?: string;
    /** Accepts additional className for the description */
    descriptionClassName?: string;
    /** Accepts additional className for the Alert */
    alertClassName?: string;
}

export interface FormFieldStatics {
    VARIANT: typeof FORM_FIELD_VARIANT;
    LABEL_POSITION: typeof FORM_FIELD_LABEL_POSITION;
}

export const FormField = <T,>({
    variant = FORM_FIELD_VARIANT.DEFAULT,
    labelPosition = FORM_FIELD_LABEL_POSITION.ASIDE,
    input,
    inputProps,
    id: defaultId = '',
    required,
    disabled: defaultDisabled,
    compact,
    label = '',
    optionalLabel,
    invalid,
    alert,
    error = '',
    warning = '',
    info = '',
    infoFlagUrl = '',
    description = '',
    labelColumnWidth = 4,
    labelClassName,
    contentClassName,
    descriptionClassName,
    alertClassName,
    className,
    children,
    ...rest
}: FormFieldProps<T>): React.ReactElement => {
    const [id, setId] = React.useState(defaultId || generateId('form-field'));
    React.useEffect(() => {
        setId(defaultId || generateId('form-field'));
    }, [defaultId]);

    const cloneInput = (inputClone?: ElementOrComponent | React.ComponentType<T>) => {
        const describedBy = cx(
            alert && `${id}-alert`,
            error && `${id}-error`,
            warning && `${id}-warning`,
            info && `${id}-info`,
        );

        return cloneOrRender(
            inputClone as ElementOrComponent,
            omitProps(rest, FormField),
            inputProps as InputPropsObject,
            {
                id: Array.isArray(inputClone) ? undefined : id,
                required,
                disabled: defaultDisabled,
                'aria-required': required,
                'aria-invalid': typeof invalid === 'boolean' ? invalid : !!error,
                'aria-describedby': describedBy || undefined,
                'aria-details': description ? `${id}-description` : undefined,
            },
        );
    };

    const getLabelColumnWidth = () => {
        if (labelPosition !== FORM_FIELD_LABEL_POSITION.ASIDE) {
            return 0;
        }
        if (typeof labelColumnWidth === 'number') {
            return labelColumnWidth;
        }
        if (typeof labelColumnWidth === 'string' && /^\d+$/.test(labelColumnWidth)) {
            return Number.parseInt(labelColumnWidth, 10) as ColSize;
        }
        return labelColumnWidth;
    };

    const isOfComponent = (component: string | RegExp) => {
        if (!component) return false;
        return isElementOfComponent(input as React.ReactNode, component);
    };

    const getGroupId = () => {
        const isWithinGroup = isOfComponent(/(^Checkbox|^Radio)/);
        return label && isWithinGroup ? `${id}-heading` : undefined;
    };

    const isLarge = () => {
        if (typeof input === 'string') return false;
        if (isOfComponent(/(^Checkbox|^Radio|^((?!Filtered).)*Select$)/)) return false;

        return variant === FORM_FIELD_VARIANT.LARGE;
    };

    const isPseudoInput = () => isOfComponent('PseudoInput');

    const hasLabelInside = () =>
        isLarge() &&
        !isPseudoInput() &&
        label &&
        labelPosition === FORM_FIELD_LABEL_POSITION.INSIDE;

    const renderLabel = () => {
        if (!label) return null;

        const isInput = input && typeof input !== 'string' && !isPseudoInput();
        const labelledby = getGroupId();

        return (
            <FormLabel
                id={labelledby}
                inputId={id}
                required={required}
                columnWidth={getLabelColumnWidth()}
                className={labelClassName}
                optionalLabel={optionalLabel}
                noLayout={variant === FORM_FIELD_VARIANT.NO_LAYOUT}
                fake={!isInput || !!labelledby}
            >
                {label}
            </FormLabel>
        );
    };

    const renderAlerts = () => (
        <FormAlerts
            alert={alert}
            inputId={id}
            error={error}
            warning={warning}
            info={info}
            flagUrl={infoFlagUrl}
            className={alertClassName}
        />
    );

    const renderDescription = () => {
        if (!description) return null;

        const Description = typeof description === 'string' ? Text : 'div';
        const classNames = cx(
            'g-form-description',
            styles.description,
            'g-small',
            descriptionClassName,
        );

        return (
            <Description id={`${id}-description`} className={classNames}>
                {description}
            </Description>
        );
    };

    const renderInput = () => {
        if (!input) return null;
        if (typeof input === 'string') return <Text>{input}</Text>;

        if (isLarge()) {
            const isPseudoInputOutcome = isPseudoInput();

            return (
                <div
                    className={cx(
                        isPseudoInputOutcome ? styles.pseudoInput : styles.control,
                        'form-pseudo-control',
                    )}
                >
                    {isPseudoInputOutcome ? (input as React.ReactNode) : cloneInput(input)}
                </div>
            );
        }

        return cloneInput(input);
    };

    const renderChildren = () => {
        const { disabled } = mergeProps(
            { disabled: defaultDisabled },
            (input as React.ReactElement)?.props,
            inputProps || {},
        );
        const labelColSize = getLabelColumnWidth();

        const isNumericColumn = typeof labelColSize === 'number';
        const useColumns = labelColSize && labelColSize !== Col.MAX_WIDTH;
        const useContentOffset = !label && useColumns && isNumericColumn;

        const classNames = cx(
            'form-group',
            compact && 'g-form-compact',
            isLarge() && styles.large,
            disabled && styles.disabled,
            description && styles.hasDescription,
            hasLabelInside() && styles.hasLabelInside,
            !useColumns && styles.noColumns,
            className,
        );

        const colProps = {
            className: cx(styles.group, contentClassName),
            xs: Col.MAX_WIDTH,
            md: useColumns && {
                size: isNumericColumn ? ((Col.MAX_WIDTH - labelColSize) as ColSize) : true,
                offset: useContentOffset ? (labelColSize as ColOffset) : 0,
            },
        };

        if (variant === FORM_FIELD_VARIANT.NO_LAYOUT) {
            return (
                <>
                    {renderLabel()}
                    {renderInput()}
                    {renderAlerts()}
                    {renderDescription()}
                    {children}
                </>
            );
        }

        const labelledby = getGroupId();

        return (
            <Row
                role={labelledby ? 'group' : undefined}
                aria-labelledby={labelledby}
                className={classNames}
            >
                {renderLabel()}
                <Col {...colProps}>
                    {renderInput()}
                    {renderAlerts()}
                    {renderDescription()}
                    {children}
                </Col>
            </Row>
        );
    };

    const context = React.useMemo(
        () => ({
            formField: true,
            isLargeFormField: variant === FORM_FIELD_VARIANT.LARGE,
        }),
        [variant],
    );

    return <FormContext.Provider value={context}>{renderChildren()}</FormContext.Provider>;
};

FormField.VARIANT = FORM_FIELD_VARIANT;
FormField.LABEL_POSITION = FORM_FIELD_LABEL_POSITION;

FormField.displayName = 'FormField';
FormField.propTypes = {
    variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT)),
    labelPosition: PropTypes.oneOf(Object.values(FORM_FIELD_LABEL_POSITION)),
    input: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node, PropTypes.func]),
    inputProps: PropTypes.shape({}),
    id: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    compact: PropTypes.bool,
    label: PropTypes.node,
    optionalLabel: PropTypes.string,
    invalid: PropTypes.bool,
    alert: elementOfType([Alert]),
    error: PropTypes.node,
    warning: PropTypes.node,
    info: PropTypes.node,
    infoFlagUrl: PropTypes.string,
    description: PropTypes.node,
    labelColumnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]) as
        | React.Validator<ColSize | undefined>
        | undefined,
    labelClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    alertClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};
