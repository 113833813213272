import { checkDOM } from './checkDOM';

export function isIE11(): boolean {
    /* istanbul ignore if */
    if (!checkDOM()) return false;

    // from https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    // @ts-expect-error custom data attached to window
    return !!window.MSInputMethodContext && !!document.documentMode;
}
