import React from 'react';

import { getDisplayName } from './getDisplayName';
import { findElement } from './findElement';

export function isElementOfComponent(
    element: React.ReactNode,
    nameOrComponent:
        | string
        | RegExp
        | React.ReactNode
        | React.ComponentType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
        | React.ForwardRefExoticComponent<React.RefAttributes<unknown>>,
    {
        maxDepth = undefined,
        diveIntoChildren = true,
        onlySingleChildren = true,
    }: { maxDepth?: number; diveIntoChildren?: boolean; onlySingleChildren?: boolean } = {},
): boolean {
    const componentDisplayName = getDisplayName(nameOrComponent);

    const predicate = (item: React.ReactNode) => {
        const elementDisplayName = getDisplayName(item);

        if (!componentDisplayName || !elementDisplayName) return false;
        if (typeof nameOrComponent === 'string') return elementDisplayName === nameOrComponent;
        if (nameOrComponent instanceof RegExp) return nameOrComponent.test(elementDisplayName);
        if (elementDisplayName === componentDisplayName) return true;
        return componentDisplayName.includes(`(${elementDisplayName})`);
    };

    const matchingElement = findElement(element, predicate, {
        maxDepth: diveIntoChildren ? maxDepth : 0,
        onlySingleChildren,
    });

    return !!matchingElement;
}
