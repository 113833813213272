import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { VisuallyHidden } from 'components/Utilities/VisuallyHidden';

import { COLOR, getColorClassName } from 'utilsTS/color';
import { ICON } from 'utilsTS/icon';
import { deprecate } from 'utilsTS/error';

import { AVATAR_VARIANT, AVATAR_SIZE, AVATAR_HOVER_ICON } from './AvatarEnums';
import { AvatarContext } from './AvatarContext';
import {
    AvatarHoverIcon,
    AvatarFallbackIcon,
    AvatarImage,
    AvatarInitials,
    AvatarBadgeIcon,
} from './components';

interface AvatarBaseProps extends Omit<React.ComponentPropsWithoutRef<'span'>, 'color'> {
    children?: never;

    /** Defines main icon (shown in the center) */
    icon?: ICON;
    /** Defines initials (shown in the center) */
    initials?: string;
    /** Defines visual variant */
    variant?: AVATAR_VARIANT;
    /** Defines colour of the main icon or initials */
    color?: COLOR;
    /** Defines background colour */
    backgroundColor?: COLOR;
    /** Defines the size of the component in px */
    size?: AVATAR_SIZE;
    /** Defines an image to be shown instead of the icon */
    imageUrl?: string;
    /** Defines whether the image should stretch or be contained in the component */
    imageContain?: boolean;
    /** Accepts additional className for the main icon */
    iconClassName?: string;
    /** Accepts additional className for the image */
    imageClassName?: string;
    /** Defines badge icon (shown in the bottom right) */
    badgeIcon?: ICON;
    /** Defines colours of the badge icon */
    badgeColor?: COLOR;
}

interface AvatarStaticProps extends AvatarBaseProps {
    hoverIcon?: never;
    hoverIconLabel?: never;
    hoverIconProps?: never;
}

/** @deprecated */
interface AvatarHoverableProps extends AvatarBaseProps {
    /**
     * Defines hover icon (shown over the center on hover)
     * @deprecated wrap inside an unstyled Button with `icon` instead
     */
    hoverIcon?: AVATAR_HOVER_ICON; // DEPRECATED
    /**
     * Defines a11y label of the hover icon
     * @deprecated wrap inside an unstyled Button with `aria-label` instead
     */
    hoverIconLabel?: string; // DEPRECATED
    /**
     * Accepts additional props for the hover icon
     * @deprecated wrap inside an unstyled Button with custom props instead
     */
    hoverIconProps?: React.HTMLAttributes<HTMLButtonElement>; // DEPRECATED
}

export type AvatarProps = AvatarStaticProps | AvatarHoverableProps;

export interface AvatarStatics {
    ICON: typeof ICON;
    VARIANT: typeof AVATAR_VARIANT;
    COLOR: typeof COLOR;
    SIZE: typeof AVATAR_SIZE;
    HOVER_ICON: typeof AVATAR_HOVER_ICON;
    BADGE_ICON: typeof ICON;
    BADGE_COLOR: typeof COLOR;
}

export const Avatar: React.FC<AvatarProps> & AvatarStatics = ({
    icon = ICON.PLACEHOLDER,
    initials,
    variant = AVATAR_VARIANT.DEFAULT,
    color,
    backgroundColor,
    imageUrl,
    imageContain = false,
    className,
    iconClassName,
    imageClassName,
    size = AVATAR_SIZE.SIZE_48,
    hoverIcon,
    hoverIconLabel,
    hoverIconProps = {},
    badgeIcon,
    badgeColor,
    ...rest
}) => {
    deprecate(
        !!hoverIconLabel,
        'Avatar.hoverIconLabel',
        'Please ensure the wrapper component provides the label.',
    );

    const [isValidImageUrl, setIsValidImageUrl] = useState(true);

    React.useEffect(() => {
        setIsValidImageUrl(true);
    }, [imageUrl]);

    const context = React.useMemo(
        () => ({
            icon,
            initials,
            color,
            imageUrl,
            imageContain,
            iconClassName,
            imageClassName,
            size,
            hoverIcon,
            hoverIconProps,
            badgeIcon,
            badgeColor,
            isValidImageUrl,
            setIsValidImageUrl,
        }),
        [
            icon,
            initials,
            color,
            imageUrl,
            imageContain,
            iconClassName,
            imageClassName,
            size,
            hoverIcon,
            hoverIconProps,
            badgeIcon,
            badgeColor,
            isValidImageUrl,
            setIsValidImageUrl,
        ],
    );

    const label = hoverIconLabel ? <VisuallyHidden>{hoverIconLabel}</VisuallyHidden> : null;

    return (
        <AvatarContext.Provider value={context}>
            <span {...rest} className={cx('g-avatar', `g-image-${size}`, className)}>
                <span
                    className={cx(
                        'g-avatar-content',
                        variant === AVATAR_VARIANT.DARK && 'g-avatar-content-dark',
                        variant === AVATAR_VARIANT.DEFAULT && 'g-avatar-content-border',
                        getColorClassName(backgroundColor),
                    )}
                >
                    <AvatarHoverIcon />
                    <AvatarFallbackIcon />
                    <AvatarImage />
                    <AvatarInitials />
                    <AvatarBadgeIcon />
                    {label}
                </span>
            </span>
        </AvatarContext.Provider>
    );
};

Avatar.ICON = ICON;
Avatar.VARIANT = AVATAR_VARIANT;
Avatar.COLOR = COLOR;
Avatar.SIZE = AVATAR_SIZE;
Avatar.HOVER_ICON = AVATAR_HOVER_ICON;
Avatar.BADGE_ICON = ICON;
Avatar.BADGE_COLOR = COLOR;

Avatar.displayName = 'Avatar';
Avatar.propTypes = {
    icon: PropTypes.oneOf(Object.values(Avatar.ICON)),
    initials: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(Avatar.VARIANT)),
    color: PropTypes.oneOf(Object.values(Avatar.COLOR)),
    backgroundColor: PropTypes.oneOf(Object.values(Avatar.COLOR)),
    imageUrl: PropTypes.string,
    imageContain: PropTypes.bool,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    size: PropTypes.oneOf(Object.values(Avatar.SIZE)),
    hoverIcon: PropTypes.oneOf(Object.values(Avatar.HOVER_ICON) as AVATAR_HOVER_ICON[]),
    hoverIconLabel: PropTypes.string, // DEPRECATED
    hoverIconProps: PropTypes.shape({}),
    badgeIcon: PropTypes.oneOf(Object.values(Avatar.BADGE_ICON)),
    badgeColor: PropTypes.oneOf(Object.values(Avatar.BADGE_COLOR)),
};
