import { get } from 'lodash';

import { DEV } from 'utilsTS/constants';
import { warning, deprecate } from 'utilsTS/error';

import { KEYS, KEY_MIGRATION_MAP, STRUCTURE, SEPARATOR } from './constants';

const WARNING = '"%s" is not a valid key in LanguageProvider strings';

function getStringOrKey(string?: string | null, key?: string, warn = false) {
    if (string === undefined || string === null) {
        warning(
            warn,
            '"%s" language string was requested by a component, but it has not been set through LanguageProvider nor a comnponent-specific string',
            key,
        );
        if (DEV) return key;
        return null;
    }
    return string;
}

function isValidKey(key: string) {
    const parts = key.split(SEPARATOR);
    const stringKey = parts.slice(-1).join('');
    const parentKey = parts.slice(0, -1).join(SEPARATOR);
    const parent = get(STRUCTURE, parentKey);

    const isArray = Array.isArray(parent);
    const isWildcard = isArray && parent.length === 0;
    const hasString = isArray && parent.includes(stringKey);

    if (!hasString && !isWildcard) return false;
    return true;
}

// DEPRECATED
function getRealKey(key: string, isLegacyObject: boolean): string {
    const isLegacyKey = typeof key === 'string' && !key.includes('.');

    deprecate(
        isLegacyKey && KEYS.includes(key),
        `LanguageProvider key "${key}"`,
        `Please use "${KEY_MIGRATION_MAP[key as keyof typeof KEY_MIGRATION_MAP]}" instead`,
    );

    // nothing to do, as key and structure match
    if (isLegacyObject === isLegacyKey) return key;

    const realKey = Object.entries(KEY_MIGRATION_MAP).reduce<string | null>(
        (previous, [legacyKey, newKey]) => {
            if (previous) return previous;
            if (key === newKey) return legacyKey;
            if (key === legacyKey) return newKey;
            return null;
        },
        null,
    );

    return realKey || key;
}

// DEPRECATED
function getKeyFromLegacyStrings(key: string, strings: Record<string, string>) {
    const realKey = getRealKey(key, true);
    const string = strings[realKey];

    deprecate(true, `flat LanguageProvider strings`, `Please use a structured object instead`);

    if (!KEYS.includes(realKey)) {
        warning(true, WARNING, key);
        return getStringOrKey(string, key, false);
    }

    return getStringOrKey(string, key);
}

function getKeyFromStructuredStrings(key: string, strings: Record<string, string>) {
    const realKey = getRealKey(key, false);

    if (!isValidKey(realKey)) {
        warning(true, WARNING, key);
        return getStringOrKey(null, key, false);
    }

    const string = get(strings, realKey, null);
    return getStringOrKey(string, key);
}

export function getKeyFromStrings(
    key: string,
    strings: Record<string, string>,
): string | null | undefined {
    const isLegacyObject = Object.values(strings).some((string) => typeof string === 'string');

    // DEPRECATED
    if (isLegacyObject) return getKeyFromLegacyStrings(key, strings);
    return getKeyFromStructuredStrings(key, strings);
}
