import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Card, CardProps } from 'components/Card';

import { FunctionCardContent, FunctionCardContentProps } from './FunctionCardContent';
import {
    FunctionCardIndicator,
    FunctionCardIndicatorProps,
    FunctionCardIndicatorStatics,
} from './FunctionCardIndicator';
import { FunctionCardButtons, FunctionCardButtonsProps } from './FunctionCardButtons';

export interface FunctionCardContainerProps
    extends CardProps,
        FunctionCardContentProps,
        FunctionCardIndicatorProps,
        FunctionCardButtonsProps {
    /** Accepts additional className passed to the content wrapper */
    contentClassName?: string;
    /** Accepts additional className passed to the indicator */
    indicatorClassName?: string;
    /** Accepts custom content to be shown instead of the regular indicator */
    indicator?: React.ReactNode;
}

export type FunctionCardContainerStatics = FunctionCardIndicatorStatics;

export const FunctionCardContainer: React.FC<FunctionCardContainerProps> &
    FunctionCardContainerStatics = ({
    heading,
    headingTag = 'h4',
    indicator,
    indicatorText,
    indicatorActive,
    indicatorClassName,
    buttons,
    children,
    buttonsText,
    icon,
    iconClassName,
    imageUrl,
    contentClassName,
    className,
    ...rest
}) => {
    const classNames = cx('g-card-function', className);

    return (
        <Card className={classNames} useCardBody={false} {...rest}>
            <FunctionCardContent
                heading={heading}
                headingTag={headingTag}
                className={contentClassName}
            >
                {children}
            </FunctionCardContent>
            <FunctionCardIndicator
                icon={icon}
                imageUrl={imageUrl}
                indicatorText={indicatorText}
                indicatorActive={indicatorActive}
                className={indicatorClassName}
                iconClassName={iconClassName}
            >
                {indicator}
            </FunctionCardIndicator>
            <FunctionCardButtons buttons={buttons} buttonsText={buttonsText} />
        </Card>
    );
};

FunctionCardContainer.ICON = FunctionCardIndicator.ICON;

FunctionCardContainer.displayName = 'FunctionCardContainer';
FunctionCardContainer.propTypes = {
    ...FunctionCardContent.propTypes,
    ...FunctionCardIndicator.propTypes,
    ...FunctionCardButtons.propTypes,
    children: PropTypes.node,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    indicator: PropTypes.node,
    indicatorClassName: PropTypes.string,
};
