export function checkDOM(): boolean {
    /* istanbul ignore if */
    if (typeof window === 'undefined') return false;
    /* istanbul ignore if */
    if (typeof document === 'undefined') return false;
    /* istanbul ignore else */
    if (document && document.body) return true;
    /* istanbul ignore next */
    return false;
}
