import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'components/Alert';
import { TransitionGroup, TransitionCollapseHeight } from 'components/Transition';

import { elementOfType } from 'utilsTS/prop-types';
import { cloneElement } from 'utilsTS/react';

export interface FormAlertsProps {
    inputId: string;
    alert?: React.ReactNode;
    error?: React.ReactNode;
    warning?: React.ReactNode;
    info?: React.ReactNode;
    flagUrl?: string;
    className?: string;
}

export const FormAlerts: React.FC<FormAlertsProps> = ({
    inputId,
    alert,
    error = '',
    warning = '',
    info = '',
    flagUrl = '',
    className,
}) => {
    const { ERROR, WARNING, INFO } = Alert.VARIANT;

    const alerts = [
        { variant: ERROR, children: error, id: `${inputId}-error` },
        { variant: WARNING, children: warning, id: `${inputId}-warning` },
        {
            variant: INFO,
            children: info,
            id: `${inputId}-info`,
            flagUrl,
        },
    ].filter((item) => item.children);

    return (
        <TransitionGroup component={null}>
            {alerts.map((item) => (
                <TransitionCollapseHeight key={item.variant} quick>
                    <div className={className}>
                        <Alert size={Alert.SIZE.SMALL} {...item} />
                    </div>
                </TransitionCollapseHeight>
            ))}
            {alert && (
                <TransitionCollapseHeight key="alert" quick>
                    <div>
                        {cloneElement(alert, { id: `${inputId}-alert`, size: Alert.SIZE.SMALL })}
                    </div>
                </TransitionCollapseHeight>
            )}
        </TransitionGroup>
    );
};

FormAlerts.displayName = 'FormAlerts';
FormAlerts.propTypes = {
    inputId: PropTypes.string.isRequired,
    alert: elementOfType([Alert]),
    error: PropTypes.node,
    warning: PropTypes.node,
    info: PropTypes.node,
    flagUrl: PropTypes.string,
    className: PropTypes.string,
};
