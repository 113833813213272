import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { LanguageContext } from 'containers/LanguageProvider';
import { TransitionFade } from 'components/Transition';
import { Group, Text } from 'components/Layout';

import { Spinner, SpinnerProps } from './Spinner';
import { SPINNER_SIZE } from './SpinnerSvg';
import styles from './Spinner.scss';

export interface ConditionalSpinnerProps extends Omit<SpinnerProps, 'children'> {
    children?: React.ReactNode;

    /** Defines whether spinner should be shown */
    active?: boolean;
    /** Defines the loading text of the spinner */
    text?: React.ReactNode;
    /** Accepts a tag or a component replacing the wrapper */
    tag?: React.ElementType;
    /** Accepts a tag or a component replacing the outermost wrapper */
    outerTag?: React.ElementType;
    /** Defines whether spinner should be inline */
    inline?: boolean;
}

export interface ConditionalSpinnerStatics {
    SIZE: typeof SPINNER_SIZE;
    COLOR: typeof Spinner.COLOR;
}

export const ConditionalSpinner: React.FC<ConditionalSpinnerProps> & ConditionalSpinnerStatics = ({
    active,
    text,
    tag = 'h3',
    outerTag: OuterTag = Group,
    inline,
    className,
    children,
    ...rest
}) => {
    const { getString } = React.useContext(LanguageContext);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!active) return <>{children}</>; // fragment needed because of React.FC return type, can't return children directly

    return (
        <TransitionFade show appear>
            <OuterTag className={cx(styles.conditional, 'text-center', className)}>
                <Spinner center={!inline} {...rest} />
                <Text always tag={inline ? 'span' : tag} className={styles.text}>
                    {/* TODO: find a way to unify the types with FE passing `t()` here */}
                    {getString('spinner.message', text as string)}
                </Text>
            </OuterTag>
        </TransitionFade>
    );
};

ConditionalSpinner.SIZE = SPINNER_SIZE;
ConditionalSpinner.COLOR = Spinner.COLOR;

ConditionalSpinner.displayName = 'ConditionalSpinner';
ConditionalSpinner.propTypes = {
    active: PropTypes.bool,
    text: PropTypes.node,
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
    outerTag: PropTypes.elementType as React.Validator<React.ElementType>,
    inline: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
