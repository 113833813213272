import React from 'react';
import { isElementOfComponent } from './isElementOfComponent';

export function isElementInChildren(
    children: React.ReactNode,
    nameOrComponent:
        | string
        | RegExp
        | React.ReactNode
        | React.ComponentType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
        | React.ForwardRefExoticComponent<React.RefAttributes<unknown>>,
    { diveIntoChildren = true, maxDepth = 10 } = {},
): boolean {
    return isElementOfComponent(React.Children.toArray(children), nameOrComponent, {
        maxDepth,
        diveIntoChildren,
        onlySingleChildren: false,
    });
}
