import React from 'react';

import { useBrowser } from './useBrowser';

export type ScrollPosition = {
    scrollX: number;
    scrollY: number;
    deltaX: number;
    deltaY: number;
};

const getScrollPosition = (window?: Window): ScrollPosition => ({
    scrollX: window?.scrollX ?? window?.pageXOffset ?? 0,
    scrollY: window?.scrollY ?? window?.pageYOffset ?? 0,
    deltaX: 0,
    deltaY: 0,
});

export function useWindowScroll(): ScrollPosition {
    const { window } = useBrowser();
    const [position, setPosition] = React.useState(getScrollPosition(window));

    React.useEffect(() => {
        if (!window) {
            return () => {};
        }

        const handleScroll = () => {
            window.requestAnimationFrame(() => {
                const { scrollX, scrollY } = getScrollPosition(window);

                setPosition((previousPosition) => ({
                    scrollX,
                    scrollY,
                    deltaX: scrollX - previousPosition.scrollX,
                    deltaY: scrollY - previousPosition.scrollY,
                }));
            });
        };

        window.addEventListener('scroll', handleScroll, {
            capture: false,
            passive: true,
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [window]);

    return position;
}
