import { GLOBAL_INFO } from './constants';
import * as styles from './styles';

type GlobalInfoType = {
    VERSION_GDS: string;
    VERSION_REACT: string;
    ENV: string;
};

export function displayGlobalInfo(): void {
    if (document.querySelector(`#${GLOBAL_INFO}`)) return;

    // @ts-expect-error custom data attached to window
    const info: GlobalInfoType = window[GLOBAL_INFO];
    const div = document.createElement('div');

    div.setAttribute('id', GLOBAL_INFO);
    div.innerHTML = Object.keys(info)
        .map((key) => {
            const version = info[key as keyof GlobalInfoType];
            const type = key.replace('VERSION_', '');
            return `${type}: <strong>${version}</strong><br />`;
        })
        .join('');

    Object.assign(div.style, styles.info);

    document.body.appendChild(div);
}
