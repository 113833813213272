import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MatchMediaContext, TIER } from 'containers/MatchMediaProvider';
import { cloneChildren } from 'utilsTS/react';
import styles from './MatchMedia.scss';

export enum MATCH_MEDIA_DISPLAY {
    BLOCK = 'block',
    FLEX = 'flex',
    INLINE = 'inline',
}

export type MatchMediaRenderProp = (options: {
    isMatching: boolean;
    matchingTier: TIER;
    props: {
        className: string;
        'aria-hidden'?: boolean;
    };
}) => React.ReactNode;

export interface MatchMediaProps {
    /** Defines the minimum tier at which contents will be rendered */
    min?: TIER;
    /** Defines the maximum tier at which contents will be rendered */
    max?: TIER;
    /** Defines the CSS display value if `alwaysRender` is set to true */
    display?: MATCH_MEDIA_DISPLAY;
    /** Defines whether to always render contents and hide via CSS media queries */
    alwaysRender?: boolean;
    /**
     * A function child can be used instead of a React node. This function is
     * called with the current matching tier status ({ isMatching, matchingTier })
     * and can use that information to conditionally return content
     *
     * ```jsx
     *    <MatchMedia min={MatchMedia.TIER.MD}>
     *        {({ isMatching, matchingTier }) => (
     *            isMatching ? 'big screen' : 'small screen'
     *        )}
     *    </MatchMedia>
     * ```
     */
    children?: React.ReactNode | MatchMediaRenderProp;
}

export interface MatchMediaStatics {
    TIER: typeof TIER;
    DISPLAY: typeof MATCH_MEDIA_DISPLAY;
}

export const MatchMedia: React.FC<MatchMediaProps> & MatchMediaStatics = ({
    min = TIER.XS,
    max = TIER.XXL,
    display = MATCH_MEDIA_DISPLAY.BLOCK,
    alwaysRender = false,
    children = null,
}) => {
    const { matchingTier, useExtendedTiers } = React.useContext(MatchMediaContext);

    const tiers = Object.values(TIER);
    const minIndex = tiers.indexOf(min);
    const maxIndex = tiers.indexOf(max);

    const getRangeTiers = () =>
        tiers.filter((_, index) => {
            const isAboveMin = minIndex <= index;
            const isBelowMax = index <= maxIndex;
            return isAboveMin && isBelowMax;
        });

    if (!children) return children as null;

    const range = getRangeTiers();
    const isMatching = range.includes(matchingTier);

    const getMatchingClassName = () => {
        const extended = useExtendedTiers ? '-extended' : '';
        return cx(
            styles[`${display}-${min}${extended}-up`],
            max === TIER.XXL ? '' : styles[`none-${tiers[maxIndex + 1]}${extended}-up`],
        );
    };

    const props = {
        className: getMatchingClassName(),
        'aria-hidden': isMatching ? undefined : true,
    };

    if (typeof children === 'function') {
        return children({ isMatching, matchingTier, props }) as React.ReactElement;
    }

    if (!isMatching && !alwaysRender) return null;

    if (alwaysRender) {
        return cloneChildren(children, props) as React.ReactElement;
    }
    return children as React.ReactElement;
};

MatchMedia.TIER = TIER;
MatchMedia.DISPLAY = MATCH_MEDIA_DISPLAY;

MatchMedia.displayName = 'MatchMedia';
MatchMedia.propTypes = {
    min: PropTypes.oneOf(Object.values(TIER)),
    max: PropTypes.oneOf(Object.values(TIER)),
    display: PropTypes.oneOf(Object.values(MATCH_MEDIA_DISPLAY)),
    alwaysRender: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};
