import { DEV } from 'utilsTS/constants';
import { warning } from 'utilsTS/error';

import { type LanguageGetString } from './types';

export const getStringFallback: LanguageGetString = (key, value = null) => {
    if (value !== null) return value;

    warning(
        true,
        '"%s" language string was requested by a component, but there is no LanguageProvider or component-specific string present',
        key,
    );

    if (DEV) return key;
    return null;
};
