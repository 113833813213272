import { Requireable, shape, oneOfType, InferType } from 'prop-types';
import { completeTierPropObject, OptionalTiersObject } from 'utilsTS/react';

type TieredPropValidator<T> = Requireable<
    NonNullable<InferType<Requireable<T> | Requireable<OptionalTiersObject<T>>>>
>;

export function tieredProp<T>(validator: Requireable<T>): TieredPropValidator<T> {
    return oneOfType([validator.isRequired, shape(completeTierPropObject(validator)).isRequired]);
}
