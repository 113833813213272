import { COLORS, COLORS_SHADED, ColorShadeKey } from './colors';

export function getColorValue(name?: string): string {
    if (!name) return '';

    const isShaded = COLORS_SHADED.some((color) => name.startsWith(color.name));
    const colorName = isShaded ? name.slice(0, -4) : name;

    const color = COLORS.find((c) => c.name === (isShaded ? colorName : name));

    if (!color) return '';
    if (typeof color.value === 'string') return color.value;

    const shade = Number.parseInt(name.slice(-3), 10);
    return color.value[shade as ColorShadeKey] || '';
}
