// *******************************************************************
// * This file is automatically generated with each and every build, *
// * your edits will vanish and your efforts will be in vain... 🤷   *
// *******************************************************************

/* eslint-disable max-lines */

export const components = [
    'Alert',
    'CollapsibleAlert',
    'CTAAlert',
    'CTAAlertButton',
    'ModalAlert',
    'InputAlert',
    'Amount',
    'PrimaryButton',
    'SecondaryButton',
    'DangerButton',
    'TransparentButton',
    'PrimaryLinkButton',
    'SecondaryLinkButton',
    'PrimaryIconButton',
    'SecondaryIconButton',
    'TransparentIconButton',
    'UIButton',
    'SegmentedButton',
    'SegmentedButtonGroup',
    'ButtonGroup',
    'Button',
    'Card',
    'CardBody',
    'CardFooter',
    'CardFooterTotal',
    'CardHeader',
    'LargeCardHeader',
    'CardGroup',
    'InfoCardHeader',
    'Carousel',
    'Collapsible',
    'CollapsibleButton',
    'CollapsibleHeader',
    'CollapsibleContent',
    'CollapsibleContentPreview',
    'ContactInfo',
    'DateTime',
    'Dropdown',
    'ColorDropdown',
    'DropdownToggle',
    'MenuDropdown',
    'MonthDropdown',
    'YearDropdown',
    'DropdownFooter',
    'DropdownActionButton',
    'SplitButton',
    'StatusInfo',
    'EmptyState',
    'FixedButton',
    'FixedFilter',
    'FixedToBottom',
    'Form',
    'FormButtonGroup',
    'FormCol',
    'FormLabel',
    'FormField',
    'FormRow',
    'ActivatedPluginCard',
    'FunctionCard',
    'HorizontalFunctionCard',
    'HorizontalFunctionCardGroup',
    'VerticalFunctionCard',
    'VerticalFunctionCardGroup',
    'PromoCard',
    'Group',
    'Text',
    'Grid',
    'Row',
    'Col',
    'Handover',
    'Hint',
    'Icon',
    'Image',
    'Flag',
    'Avatar',
    'Animation',
    'StructuredInfo',
    'StructuredInfoItem',
    'AmountInput',
    'HiddenInput',
    'CheckboxInput',
    'ClearableTextInput',
    'DateInput',
    'DateRangeInput',
    'NewDateInput',
    'NewDateRangeInput',
    'HashtagInput',
    'RadioInput',
    'RadioInputGroup',
    'TextareaInput',
    'TextInput',
    'InputGroup',
    'FileInput',
    'FileDropzone',
    'FileList',
    'FileListButton',
    'CountedTextInput',
    'VerificationInput',
    'PasswordInput',
    'PseudoInput',
    'SliderInput',
    'TimeInput',
    'LabeledList',
    'LabeledListItem',
    'Legend',
    'LegendItem',
    'Meter',
    'AccountNumber',
    'Badge',
    'ColorDecoration',
    'Pill',
    'TextHighlighter',
    'UnreadIndicator',
    'Modal',
    'ConfirmationModal',
    'DrawerModal',
    'SmallModal',
    'TeaserModal',
    'ModalContainer',
    'ModalHeader',
    'ModalBody',
    'ModalImage',
    'ModalFooter',
    'ModalSpinner',
    'AnchorNavigation',
    'BreadcrumbNavigation',
    'PaginationNavigation',
    'PrimaryNavigation',
    'PrimaryNavigationButton',
    'SecondaryNavigation',
    'SkipLinkNavigation',
    'OverviewCard',
    'OverviewCardAmount',
    'OverviewCardButton',
    'OverviewCardGroup',
    'OverviewCardMenu',
    'OverviewPromoCard',
    'OverviewWidgetCard',
    'FocusPage',
    'FullPage',
    'Stepper',
    'ProductCard',
    'ProductInfo',
    'Progress',
    'ProgressBar',
    'SegmentedMenu',
    'Select',
    'FilteredSelect',
    'ProductSelect',
    'RecipientSelect',
    'NativeSelect',
    'Selectable',
    'SelectableItem',
    'PinSigning',
    'ClearableButton',
    'Snackbar',
    'SnackbarItem',
    'SnackbarButton',
    'Spinner',
    'ConditionalSpinner',
    'StripeActionButton',
    'StripeActionSplitButton',
    'StripeHeader',
    'StripeNavigation',
    'StripeProductInfo',
    'StripeTitle',
    'StripeToolbar',
    'Switch',
    'Table',
    'TableHead',
    'TableBody',
    'TableFoot',
    'TableSection',
    'TableRow',
    'TableCell',
    'CollapsibleTable',
    'CollapsibleTableRow',
    'CollapsibleTableCell',
    'ToggleButton',
    'ToggleIcon',
    'ToggleImage',
    'ToggleBadge',
    'ToggleGroup',
    'Transaction',
    'TransactionDate',
    'TransactionDetail',
    'TransactionDetailButton',
    'TransactionGroup',
    'TransactionGroupFooter',
    'TransactionIndicator',
    'TransactionMenu',
    'TransactionSection',
    'TransactionStarToggle',
    'Transition',
    'TransitionCollapse',
    'TransitionCollapseHeight',
    'TransitionFade',
    'TransitionSlideUp',
    'TransitionGroup',
    'Tabs',
    'TabPanel',
    'Timeline',
    'TimelineItem',
    'CollapsibleTimelineItems',
    'CollapsibleTimelineButton',
    'Tooltip',
    'Heading',
    'Paragraph',
    'Inline',
    'LineBreak',
    'Link',
    'List',
    'ListItem',
    'Separator',
    'ClickOutside',
    'FocusTrap',
    'KeyboardDetector',
    'LiveAnnouncer',
    'InfiniteScroll',
    'MatchMedia',
    'Portal',
    'RenderProp',
    'VisuallyHidden',
    'Interactable',
];
