export enum KEY {
    TAB = 'Tab',
    ENTER = 'Enter',
    ESC = 'Escape',
    SPACE = ' ',
    BACKSPACE = 'Backspace',
    END = 'End',
    HOME = 'Home',
    DELETE = 'Delete',
    UP = 'ArrowUp',
    DOWN = 'ArrowDown',
    LEFT = 'ArrowLeft',
    RIGHT = 'ArrowRight',
    SHIFT = 'ShiftLeft', // todo shift right
}

export enum TIER {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XXL = 'xxl',
}
