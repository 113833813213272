import React from 'react';
import { Context } from 'internal/Provider';
import { useBrowser } from 'utilsTS/hooks';

const ANNOUNCE_TIME = 10 * 1000;

const createLog = (ariaLive: 'assertive' | 'polite', document: Document) => {
    const node = document.createElement('div');
    node.setAttribute('class', 'sr-only');
    node.setAttribute('role', 'log');
    node.setAttribute('aria-live', ariaLive);
    node.setAttribute('aria-relevant', 'additions');
    return node;
};

const ensureNode = (document: Document) => {
    let node = document.querySelector('#g-announcer');

    if (!node) {
        node = document.createElement('div');
        node.setAttribute('id', 'g-announcer');
        node.setAttribute('class', 'g-bootstrap'); // to make .sr-only work

        const assertiveLog = createLog('assertive', document);
        node.appendChild(assertiveLog);
        const politeLog = createLog('polite', document);
        node.appendChild(politeLog);

        document.body.prepend(node);
    }

    return node;
};

export interface LiveAnnouncerProps {
    message: string;
    assertiveness?: 'polite' | 'assertive';
}

export const LiveAnnouncer: React.FC<LiveAnnouncerProps> = ({
    message,
    assertiveness = 'polite',
}) => {
    const { document } = useBrowser(React.useContext(Context));
    const nodeRef = React.useRef<Element>();

    React.useEffect(() => {
        nodeRef.current = document ? ensureNode(document) : undefined;
    }, [document]);

    React.useEffect(() => {
        if (!document || !nodeRef.current) return;

        const messageNode = document.createElement('div');
        messageNode.textContent = message;

        if (assertiveness === 'assertive') {
            nodeRef.current.firstChild?.appendChild(messageNode);
        }
        if (assertiveness === 'polite') {
            nodeRef.current.lastChild?.appendChild(messageNode);
        }
        if (message !== '') {
            setTimeout(() => messageNode.remove(), ANNOUNCE_TIME);
        }
    }, [message, assertiveness, document]);

    return null;
};

LiveAnnouncer.displayName = 'LiveAnnouncer';
