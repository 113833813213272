import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { LanguageContext } from 'containers/LanguageProvider';

import { NewButton as Button } from 'components/Button/NewButton';

export interface ModalHeaderHelpProps {
    small?: boolean;
    helpUrl?: string;
    helpLabel?: string;
}

export const ModalHeaderHelp: React.FC<ModalHeaderHelpProps> = ({
    small = false,
    helpUrl = '',
    helpLabel,
}) => {
    const { getString } = useContext(LanguageContext);

    return (
        <Button
            hiddenLabel={small ? Button.HIDDEN_LABEL.ALWAYS : Button.HIDDEN_LABEL.BELOW_MD}
            variant={Button.VARIANT.TERTIARY}
            size={Button.SIZE.SMALL}
            icon={Button.ICON.HELP}
            fullWidth={Button.FULL_WIDTH.NEVER}
            href={helpUrl}
            target="_blank"
            rel="noopener noreferer"
            className="modal-help"
        >
            {getString('common.help', helpLabel)}
        </Button>
    );
};

ModalHeaderHelp.displayName = 'ModalHeaderHelp';
ModalHeaderHelp.propTypes = {
    small: PropTypes.bool,
    helpUrl: PropTypes.string,
    helpLabel: PropTypes.string,
};
