import React from 'react';
import { Transition as TransitionComponent, CSSTransition } from 'react-transition-group';
import {
    TransitionProps as TransitionComponentProps,
    TransitionStatus,
} from 'react-transition-group/Transition';
import PropTypes from 'prop-types';
import { pick, isObject, isFunction } from 'lodash';

import { parseDuration } from 'utilsTS/string';
import { cloneElement } from 'utilsTS/react';

const DEFAULT_TIMEOUT = 350;

export interface TransitionType {
    enter?: string;
    enterActive?: string;
    exit?: string;
    exitActive?: string;
    appear?: string;
    appearActive?: string;
}

export interface TimeoutType {
    appear?: number | string;
    enter?: number | string;
    exit?: number | string;
}

// addEndListener is omitted as it's breaking the type cascade for some reason, it doesn't seem to be used anywhere
export type TransitionProps = Omit<TransitionComponentProps, 'addEndListener'> & {
    children?: React.ReactNode | ((status: TransitionStatus) => React.ReactNode);

    /** Defines whether the component should be visible */
    show?: boolean;
    /** Defines styles for the transition phase */
    transition: string | TransitionType;
    /** Defines timeout for the transition */
    timeout?: number | string | TimeoutType;
    /** Defines whether the animation should play on mount */
    appear?: boolean;
};

export const Transition: React.FC<TransitionProps> = ({
    in: inProp,
    show,
    transition,
    timeout = DEFAULT_TIMEOUT,
    appear,
    children,
    className,
    ...rest
}) => {
    const getTransition = () => {
        if (!isObject(transition)) return transition;
        return pick(transition, [
            'enter',
            'enterActive',
            'appear',
            'appearActive',
            'exit',
            'exitActive',
        ]);
    };

    const getTimeout = (key: keyof TimeoutType) => {
        if (isObject(timeout)) return parseDuration(timeout[key], DEFAULT_TIMEOUT);
        return parseDuration(timeout, DEFAULT_TIMEOUT);
    };

    return (
        <CSSTransition
            {...rest}
            unmountOnExit
            in={inProp || show}
            appear={appear}
            classNames={getTransition()}
            timeout={{
                appear: getTimeout('appear'),
                enter: getTimeout('enter'),
                exit: getTimeout('exit'),
            }}
        >
            {(status) => {
                const child = isFunction(children)
                    ? children(status)
                    : React.Children.only(children);

                const isExiting = status.startsWith('exit');

                return cloneElement(child, {
                    className,
                    'aria-hidden': isExiting ? true : undefined,
                });
            }}
        </CSSTransition>
    );
};

Transition.displayName = 'Transition';
Transition.propTypes = {
    // @ts-expect-error TS thinks TransitionComponent has no propTypes
    ...TransitionComponent.propTypes,
    show: PropTypes.bool,
    transition: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            enter: PropTypes.string,
            enterActive: PropTypes.string,
            exit: PropTypes.string,
            exitActive: PropTypes.string,
            appear: PropTypes.string,
            appearActive: PropTypes.string,
        }),
    ]).isRequired,
    timeout: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
            appear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            enter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            exit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ]),
    appear: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};
