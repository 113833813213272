import React from 'react';
import cx from 'classnames';

import { getColorClassName } from 'utilsTS/color';

import { AvatarContext } from '../AvatarContext';
import { AvatarShrunkIcon } from './AvatarShrunkIcon';

export const AvatarBadgeIcon: React.FC = () => {
    const { badgeColor, badgeIcon } = React.useContext(AvatarContext);

    if (!badgeIcon) return null;

    const className = cx('g-avatar-badge', getColorClassName(badgeColor || ''));

    const REL_BADGE_ICON_SIZE = (37.5 - 2 * 8) / 100; // width: 37.5%; padding: 8%;

    return (
        <span className={className}>
            <AvatarShrunkIcon shrinkIcon={badgeIcon} shrinkFactor={REL_BADGE_ICON_SIZE} />
        </span>
    );
};

AvatarBadgeIcon.displayName = 'AvatarBadgeIcon';
