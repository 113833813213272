import React from 'react';
import { uniq, isPlainObject } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type keysOrComponent = string[] | Record<string, any> | React.FC;

function getKeys(keysOrComponent: keysOrComponent) {
    if (Array.isArray(keysOrComponent)) {
        return keysOrComponent;
    }

    if (isPlainObject(keysOrComponent) && keysOrComponent.propTypes === undefined) {
        return Object.keys(keysOrComponent);
    }

    const { propTypes } = keysOrComponent || {}; // TODO: remove fallback, we know .propTypes exists by now
    if (!propTypes || typeof propTypes !== 'object') return [];
    return Object.keys(propTypes);
}

export function getPropKeys(...keysOrComponents: keysOrComponent[]): string[] {
    const keys = keysOrComponents.reduce(
        (previous: string[], keysOrComponent) => [...previous, ...getKeys(keysOrComponent)],
        [],
    );
    return uniq(keys);
}
