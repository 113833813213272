import { createContext } from 'utilsTS/react';
import { DEFAULT_TIER, TIER } from './constants';

export interface MatchMediaContextTypes {
    matchingTier: TIER;
    useExtendedTiers?: boolean;
}

export const MatchMediaContext = createContext<MatchMediaContextTypes>('MatchMedia', {
    matchingTier: DEFAULT_TIER,
    useExtendedTiers: false,
});
