const ANONYMOUS = '<<anonymous>>';

export default function createTypeChecker(validate) {
    function checkType(isRequired, props, propName, componentName, location, ...rest) {
        const name = componentName || ANONYMOUS;

        if (props[propName] == null) {
            if (isRequired) {
                return new Error(
                    `The ${location} \`${propName}\` is marked as required in \`${name}\`. but its value is \`undefined\`.`,
                );
            }
            return null;
        }

        return validate(props, propName, componentName, location, ...rest);
    }

    const chainedCheckType = checkType.bind(null, false);
    chainedCheckType.isRequired = checkType.bind(null, true);

    return chainedCheckType;
}
