import React from 'react';
import cx from 'classnames';

import { Image } from 'components/Image/Image';

import { AvatarContext } from '../AvatarContext';

export const AvatarImage: React.FC = () => {
    const { imageUrl, imageContain, isValidImageUrl, setIsValidImageUrl, imageClassName } =
        React.useContext(AvatarContext);

    if (!imageUrl || !isValidImageUrl) return null;

    const classNames = cx(
        'g-avatar-image',
        imageContain && 'g-avatar-image-contain',
        imageClassName,
    );

    return (
        <Image
            className={classNames}
            src={imageUrl}
            alt=""
            onError={() => setIsValidImageUrl(false)}
        />
    );
};

AvatarImage.displayName = 'AvatarImage';
