import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon } from 'components/Icon';

import { COLOR } from 'utilsTS/color';
import { ICON } from 'utilsTS/icon';
import { omitProps } from 'utilsTS/react';
import { deprecate } from 'utilsTS/error';
import { requiredIf } from 'utilsTS/prop-types';

export enum BADGE_VARIANT {
    TODO = 'todo',
    STATE = 'state',
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    CATEGORY = 'category',

    HONEY = 'honey',
    CARROT = 'carrot',
    BERRY = 'berry',
    ROSE = 'rose',
    LAVENDER = 'lavender',
    LILA = 'lila',
    INDIGO = 'indigo',
    POOL = 'pool',
    TEAL = 'teal',
    LIME = 'lime',
    FOREST = 'forest',
    STONE = 'stone',
}

export interface BadgeProps extends React.ComponentPropsWithoutRef<'span'> {
    /** Defines visual variant of the component */
    variant?: BADGE_VARIANT | COLOR;
    /**
     * Defines visible text
     * @deprecated use children prop instead
     */
    text?: string;
    /** Defines an optional icon */
    icon?: ICON;
    /** Defines whether colours should be flipped for product variant */
    filled?: boolean;
    /** Defines the content of the component */
    children?: React.ReactNode;
}

export interface BadgeStatics {
    VARIANT: typeof BADGE_VARIANT;
    ICON: typeof ICON;
}

export const Badge: React.FC<BadgeProps> & BadgeStatics = ({
    children,
    variant = BADGE_VARIANT.TODO,
    text,
    icon,
    filled,
    className,
    ...rest
}) => {
    deprecate(!!text, `Badge.text`, `Please use Badge.children instead.`);

    const classNames = cx(
        'g-badge',
        `g-badge-${variant}`,
        'g-badge-label', // DEPRECATED
        icon && 'g-badge-icon',
        filled && 'g-badge-filled',
        className,
    );

    if (!children && !text) return null;

    return (
        <span {...omitProps(rest, Badge)} className={classNames}>
            {icon && <Icon icon={icon} size={Icon.SIZE.SIZE_16} />}
            {children || text}
        </span>
    );
};

Badge.VARIANT = BADGE_VARIANT;
Badge.ICON = ICON;

Badge.displayName = 'Badge';
Badge.propTypes = {
    variant: PropTypes.oneOf(Object.values(BADGE_VARIANT)),
    text: requiredIf(PropTypes.string, ({ children }) => !children),
    icon: PropTypes.oneOf(Object.values(Icon.ICON)),
    filled: PropTypes.bool,
    className: PropTypes.string,
    children: requiredIf(PropTypes.node, ({ text }) => !text),
};
