import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { createEnum } from 'utils/enum';
import { deprecate } from 'utils/error';
import Button, { propTypes, defaultProps } from './Button';
import styles from './UIButton.scss';

export const UIBUTTON_COLOR = createEnum(
    'primary',
    'secondary',
    'white',
    'alert-error',
    'alert-warning',
    'alert-info',
);

const UIButton = ({ color, size, right, className, iconLeft, ...rest }) => {
    React.useEffect(() => {
        deprecate(
            true,
            'UIButton',
            'Please use <Button variant={Button.VARIANT.TERTIARY} /> instead.',
        );
    }, []);

    const classNames = cx(
        'g-btn-ui',
        color && `g-btn-ui-${color}`,
        right && 'float-right',
        iconLeft && styles.left,
        className,
    );

    return (
        <Button
            {...rest}
            link={false}
            outline={false}
            size={size}
            color={Button.COLOR.NONE}
            className={classNames}
        />
    );
};

UIButton.displayName = 'UIButton';

UIButton.SIZE = Button.SIZE;

UIButton.ICON = Button.ICON;

UIButton.COLOR = UIBUTTON_COLOR;

UIButton.propTypes = {
    ...propTypes,
    icon: PropTypes.oneOf(Object.values(UIButton.ICON)).isRequired,
    color: PropTypes.oneOf(UIBUTTON_COLOR.values()),
    right: PropTypes.bool,
    iconLeft: PropTypes.bool,
};

UIButton.defaultProps = {
    ...defaultProps,
    color: UIBUTTON_COLOR.SECONDARY,
    size: Button.SIZE.SMALL,
    right: false,
    iconLeft: false,
};

export default UIButton;
