import { useRef } from 'react';
import { debounce } from 'lodash';
import type { DebouncedFunc } from 'lodash'; // has to use import type, Storybook was breaking otherwise

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<T extends (...args: any) => any>(
    callback: T,
    wait?: number,
): DebouncedFunc<T> {
    return useRef(debounce(callback, wait)).current;
}
