import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/Layout';

import { AlertContext } from './AlertContext';
import { AlertContentHeading } from './AlertContentHeading';
import { AlertContentDetails } from './AlertContentDetails';

export interface AlertContentProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Defines the heading to show in the alert */
    heading?: React.ReactNode;
    /** Accepts Button components to be shown on the side of the Alert */
    buttons?: React.ReactNode[];
}

export const AlertContent: React.FC<AlertContentProps> = ({
    heading = '',
    buttons,
    className,
    children,
    ...rest
}) => {
    const { closeable } = React.useContext(AlertContext);

    return (
        <div {...rest} className={cx('g-card-alert-data', className)}>
            {closeable && <AlertContentHeading buttons={buttons}>{heading}</AlertContentHeading>}
            <AlertContentDetails buttons={buttons}>
                {!closeable && <AlertContentHeading>{heading}</AlertContentHeading>}
                <Text>{children}</Text>
            </AlertContentDetails>
        </div>
    );
};

AlertContent.displayName = 'AlertContent';
AlertContent.propTypes = {
    heading: PropTypes.node,
    buttons: PropTypes.arrayOf(PropTypes.node),
    className: PropTypes.string,
    children: PropTypes.node,
};
