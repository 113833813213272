import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/Layout';

export interface InlineProps extends React.ComponentPropsWithoutRef<'span'> {
    /** Defines whether text should be visually emphasised */
    emphasis?: boolean;
    /** Defines whether text should be visually small */
    small?: boolean;
    /** Defines whether text should be visually faded */
    faded?: boolean;
}

export const Inline: React.FC<InlineProps> = ({
    emphasis,
    small,
    faded,
    className,
    children,
    ...rest
}) => (
    <Text
        {...rest}
        always
        tag={emphasis ? 'strong' : 'span'}
        className={cx({ 'g-small': small, 'g-faded': faded }, className)}
    >
        {children}
    </Text>
);

Inline.displayName = 'Inline';
Inline.propTypes = {
    emphasis: PropTypes.bool,
    small: PropTypes.bool,
    faded: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
