import { components } from './components';

export const GLOBAL_INFO = '__GDS_INFO__';
export const GLOBAL_DEBUG = '__gdsDebugInfo';
export const STYLEGUIDE_VERSION_VAR = '--g-version';

const COMPONENT_EXCEPTIONS = [
    'Text',
    '.*Group',
    'Grid',
    '.*Row',
    '.*Col',
    'Card',
    'Modal.+',
    'Table.+',
    '.+Item',
    'ProgressBar',
    'Transition.*',
    'ClickOutside',
    'FocusTrap',
    'MatchMedia',
    'Portal',
    'RenderProp',
];

export const COMPONENT_REGEXES = components
    .filter(
        (component) => !new RegExp(`^(${COMPONENT_EXCEPTIONS.join('|')})$`, 'g').test(component),
    )
    .map((component) => new RegExp(`^${component}$`));
