import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { COLOR, getColorValue } from 'utilsTS/color';

export enum SPINNER_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export interface SpinnerSvgProps extends React.ComponentPropsWithoutRef<'svg'> {
    size?: SPINNER_SIZE;
    color?: COLOR;
}

export interface SpinnerSvgStatics {
    SIZE: typeof SPINNER_SIZE;
    COLOR: typeof COLOR;
}

export const SpinnerSvg: React.FC<SpinnerSvgProps> & SpinnerSvgStatics = ({
    size = SPINNER_SIZE.MEDIUM,
    color = '',
    className,
    ...rest
}) => {
    const colorValue = getColorValue(color);
    const classNames = cx('g-spinner', `g-spinner-${size}`, className);
    const style = color ? ({ '--color': colorValue } as React.CSSProperties) : undefined;

    return (
        <svg aria-hidden viewBox="22 22 44 44" className={classNames} style={style} {...rest}>
            <circle className="g-spinner-circle" cx="44" cy="44" r="20" fill="none" />
        </svg>
    );
};

SpinnerSvg.SIZE = SPINNER_SIZE;
SpinnerSvg.COLOR = COLOR;

SpinnerSvg.displayName = 'SpinnerSvg';
SpinnerSvg.propTypes = {
    size: PropTypes.oneOf(Object.values(SpinnerSvg.SIZE)),
    color: PropTypes.oneOf(Object.values(SpinnerSvg.COLOR)),
    className: PropTypes.string,
};
