import React from 'react';
import PropTypes from 'prop-types';

import { Transition, TransitionProps } from 'components/Transition';

import styles from '../Modal.scss';

export type ModalTransitionDialogProps = Omit<TransitionProps, 'transition'>;

export const ModalTransitionDialog: React.FC<ModalTransitionDialogProps> = ({
    show,
    appear,
    animate = true,
    children,
    ...rest
}) => {
    if (!animate) return show ? (children as React.ReactElement) : null;

    return (
        <Transition
            show={show}
            appear={appear}
            timeout={styles.timeout}
            {...rest}
            transition={styles}
        >
            {children}
        </Transition>
    );
};

ModalTransitionDialog.displayName = 'ModalTransitionDialog';
ModalTransitionDialog.propTypes = {
    show: PropTypes.bool,
    appear: PropTypes.bool,
    animate: PropTypes.bool,
    children: PropTypes.node,
};
