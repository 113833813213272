import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/Layout';

import { getColorClassName, COLOR } from 'utilsTS/color';
import { ref } from 'utilsTS/prop-types';

import { CardBody } from './CardBody';

export interface CardProps extends React.ComponentPropsWithoutRef<'div'> {
    innerRef?: React.Ref<HTMLDivElement>;
    /** Defines Card content */
    children?: React.ReactNode;
    /** Defines whether padding should be rendered */
    padded?: boolean;
    /** Defines whether an additional body wrapper should be rendered */
    useCardBody?: boolean;
    /** Defines background colour of the Card */
    color?: COLOR;
}

export interface CardStatics {
    COLOR: typeof COLOR;
}

export const Card: React.FC<CardProps> & CardStatics = ({
    innerRef,
    padded = true,
    useCardBody = true,
    color,
    className,
    children,
    ...rest
}) => {
    const classNames = cx(
        'card',
        'g-card',
        getColorClassName(color),
        { 'g-card-no-padding': !padded },
        className,
    );

    const content = <Text>{children}</Text>;

    return (
        <div ref={innerRef} className={classNames} {...rest}>
            {children && (useCardBody ? <CardBody>{content}</CardBody> : content)}
        </div>
    );
};

Card.COLOR = COLOR;

Card.displayName = 'Card';
Card.propTypes = {
    innerRef: ref(),
    padded: PropTypes.bool,
    useCardBody: PropTypes.bool,
    color: PropTypes.oneOf(Object.values(Card.COLOR)),
    className: PropTypes.string,
    children: PropTypes.node,
};
