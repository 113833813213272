import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon } from 'components/Icon';
import { Heading, Paragraph } from 'components/Typography';

import { ICON } from 'utilsTS/icon';
import { deprecate } from 'utilsTS/error';
import { omitProps } from 'utilsTS/react';

import { ModalHeaderClose } from './ModalHeaderClose';
import { ModalHeaderHelp } from './ModalHeaderHelp';

export interface ModalHeaderProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    closeable?: boolean;
    closeLabel?: string;
    confirmation?: boolean;
    helpLabel?: string;
    helpUrl?: string;
    icon?: ICON;
    image?: React.ReactNode;
    isLoading?: boolean;
    small?: boolean;
    stepping?: string; // DEPRECATED
    title?: React.ReactNode;
}

export interface ModalHeaderStatics {
    ICON: typeof ICON;
}

export const ModalHeader: React.FC<ModalHeaderProps> & ModalHeaderStatics = ({
    closeable = true,
    closeLabel,
    confirmation,
    helpLabel,
    helpUrl,
    icon,
    image,
    isLoading = false,
    small,
    stepping,
    title,
    id,
    className,
    ...rest
}) => {
    const renderClose = () => {
        if (!closeable) return null;

        return <ModalHeaderClose small={small} closeLabel={closeLabel} />;
    };

    const renderHelp = () => {
        if (!helpUrl) return null;

        return <ModalHeaderHelp small={small} helpUrl={helpUrl} helpLabel={helpLabel} />;
    };

    const renderIcon = () => {
        if (!isLoading && image) return image;
        if (!icon || small) return null;

        return <Icon icon={icon} size={Icon.SIZE.SIZE_48} className="g-modal-header-icon" />;
    };

    const renderTitle = () => {
        if (!title) return null;

        return (
            <Heading
                level={1}
                className={cx('modal-title', confirmation && isLoading && 'modal-title-hidden')}
                tabIndex={-1}
            >
                {title}
            </Heading>
        );
    };

    const renderStepping = () => {
        deprecate(!!stepping, 'Modal.stepping', 'Please use FocusPage component instead.');

        if (!stepping || small) return null;
        return <Paragraph className="g-modal-stepping">{stepping}</Paragraph>;
    };

    return (
        <div {...omitProps(rest, ModalHeader)} className={cx('modal-header', className)}>
            {renderClose()}
            {renderHelp()}
            {renderIcon()}
            <div id={`${id}-title`}>
                {renderTitle()}
                {renderStepping()}
            </div>
        </div>
    );
};

ModalHeader.ICON = Icon.ICON;

ModalHeader.displayName = 'ModalHeader';
ModalHeader.propTypes = {
    id: PropTypes.string.isRequired,
    confirmation: PropTypes.bool,
    small: PropTypes.bool,
    icon: PropTypes.oneOf(Object.values(Icon.ICON)),
    image: PropTypes.node,
    title: PropTypes.string,
    stepping: PropTypes.string, // DEPRECATED
    helpUrl: PropTypes.string,
    helpLabel: PropTypes.string,
    closeLabel: PropTypes.string,
    closeable: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
};
