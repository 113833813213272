import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export interface CardGroupProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Defines whether Cards should stack horizontally */
    horizontal?: boolean;
    /** Defines whether a visual separator between Cards should be rendered */
    noLine?: boolean;
    /** Defines whether a gap between CardGroups should be rendered */
    noGap?: boolean;
    /**
     * Defines whether scrollbars should be shown if content does not fit
     * (for more details refer to https://designsystem.george-labs.com/main/components/card/#scrollable)
     */
    scrollable?: boolean;
}

export const CardGroup: React.FC<CardGroupProps> = ({
    horizontal = false,
    noLine = false,
    noGap = false,
    scrollable = false,
    children,
    className,
    ...rest
}) => {
    const classNames = cx(
        'card-group',
        'g-card-group',
        horizontal ? 'g-card-group-horizontal' : 'g-card-group-vertical',
        noLine && 'g-card-group-noline',
        noGap && 'g-card-group-nogap',
        scrollable && 'g-card-group-scrollable',
        className,
    );
    return (
        <div className={classNames} {...rest}>
            {children}
        </div>
    );
};

CardGroup.displayName = 'CardGroup';
CardGroup.propTypes = {
    horizontal: PropTypes.bool,
    noLine: PropTypes.bool,
    noGap: PropTypes.bool,
    scrollable: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};
