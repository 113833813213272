import { uniq, isPlainObject } from 'lodash';

function getKeys(keysOrComponent) {
    if (Array.isArray(keysOrComponent)) {
        return keysOrComponent;
    }

    if (isPlainObject(keysOrComponent) && keysOrComponent.propTypes === undefined) {
        return Object.keys(keysOrComponent);
    }

    const { propTypes } = keysOrComponent || {};
    if (!propTypes || typeof propTypes !== 'object') return [];
    return Object.keys(propTypes);
}

export default function getPropKeys(...keysOrComponents) {
    const keys = keysOrComponents.reduce(
        (previous, keysOrComponent) => [...previous, ...getKeys(keysOrComponent)],
        [],
    );
    return uniq(keys);
}
