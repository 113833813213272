import React from 'react';
import PropTypes from 'prop-types';

import { deprecate } from 'utilsTS/error';

import {
    FunctionCardContainer,
    FunctionCardContainerProps,
    FunctionCardContainerStatics,
} from './components';

export interface HorizontalFunctionCardProps extends FunctionCardContainerProps {
    /**
     * Defines whether the card indicator should be rendered smaller
     * @deprecated can safely be removed, as it has no effect anymore
     */
    small?: boolean;
}

export type HorizontalFunctionCardStatics = FunctionCardContainerStatics;

/** @deprecated use FunctionCard with `horizontal` instead */
export const HorizontalFunctionCard: React.FC<HorizontalFunctionCardProps> &
    HorizontalFunctionCardStatics = ({ small, ...rest }) => {
    deprecate(!!small, `HorizontalFunctionCard.small`, 'The prop can be safely removed.');

    return <FunctionCardContainer {...rest} />;
};

HorizontalFunctionCard.ICON = FunctionCardContainer.ICON;

HorizontalFunctionCard.displayName = 'HorizontalFunctionCard';
HorizontalFunctionCard.propTypes = {
    ...FunctionCardContainer.propTypes,
    small: PropTypes.bool, // DEPRECATED
    className: PropTypes.string,
};
