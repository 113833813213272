import React from 'react';
import { mergeProps } from './mergeProps';

export function cloneElement(
    element: React.ReactNode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...props: Record<string, any>[]
): React.ReactElement | string | null {
    if (typeof element === 'string') return element;
    if (!React.isValidElement(element)) return null;

    const mergedProps = mergeProps(element.props, ...props);
    return React.cloneElement(element, mergedProps);
}
