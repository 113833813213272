import React from 'react';

import { isElementOfComponent } from 'utilsTS/react';

import { StripeContext } from '../StripeContext';

export const StripeHeaderCenter: React.FC = () => {
    const { left, center, isMinMediumTier } = React.useContext(StripeContext);

    const hasProductInfo = !isMinMediumTier && isElementOfComponent(left, 'StripeProductInfo');

    return (
        <>
            {hasProductInfo && left}
            {center}
        </>
    );
};

StripeHeaderCenter.displayName = 'StripeHeaderCenter';
