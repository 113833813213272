import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export type ModalFooterProps = React.ComponentPropsWithoutRef<'div'>;

export const ModalFooter: React.FC<ModalFooterProps> = ({ className, children, ...rest }) => {
    const classNames = cx('modal-footer', className);

    return (
        <div {...rest} className={classNames}>
            {children}
        </div>
    );
};

ModalFooter.displayName = 'ModalFooter';
ModalFooter.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
