import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cx from 'classnames';

import { Icon } from 'components/Icon';
import { Pill } from 'components/Misc/Pill';
import { Link, LinkProps } from 'components/Typography';
import { LanguageContext } from 'containers/LanguageProvider';
import { ICON } from 'utilsTS/icon';
import { omitProps } from 'utilsTS/react';

export interface SecondaryNavigationItemProps extends LinkProps {
    href?: string;
    label: string;
    icon?: ICON;
    badge?: React.ReactNode;
    pill?: number;
    selected?: boolean;
    linkClassName?: string;
    external?: boolean;
    items?: SecondaryNavigationItemProps[];
    showIcon?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export const SecondaryNavigationItem: React.FC<SecondaryNavigationItemProps> = ({
    href,
    label,
    icon,
    badge,
    pill,
    selected,
    showIcon,
    className,
    linkClassName,
    external,
    onClick = noop,
    ...rest
}) => {
    const classNames = cx(selected && 'selected', className);
    const labelClassName = showIcon && !icon ? 'g-sidenav-offseted-label' : '';
    const { getString } = React.useContext(LanguageContext);

    return (
        <li className={classNames}>
            <Link
                {...omitProps(rest, SecondaryNavigationItem)}
                href={href}
                className={linkClassName}
                target={external ? '_blank' : undefined}
                rel={external ? 'noopener noreferrer' : undefined}
                onClick={onClick}
            >
                {icon && showIcon && (
                    <Icon
                        icon={icon}
                        size={Icon.SIZE.SIZE_24}
                        className={cx('g-sidenav-icon', !selected && 'g-faded')}
                    />
                )}
                <span className={labelClassName}>
                    {label}
                    {external && (
                        <Icon
                            ariaLabel={` ${getString('common.externalLink')}`}
                            icon={Icon.ICON.ARROW_OUT}
                            size={Icon.SIZE.SIZE_16}
                            className="g-sidenav-external-icon"
                        />
                    )}
                </span>

                {badge}
                {!!pill && <Pill value={pill} />}
            </Link>
        </li>
    );
};

SecondaryNavigationItem.displayName = 'SecondaryNavigationItem';
SecondaryNavigationItem.propTypes = {
    id: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(Object.values(Icon.ICON)),
    pill: PropTypes.number,
    badge: PropTypes.node,
    selected: PropTypes.bool,
    className: PropTypes.string,
    linkClassName: PropTypes.string,
    external: PropTypes.bool,
    showIcon: PropTypes.bool,
    onClick: PropTypes.func,
};
