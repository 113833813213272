import { isObject, escapeRegExp } from 'lodash';

import { DEV } from '../constants';

const START = '{{';
const END = '}}';

export function processTemplateString(
    string?: string | null,
    replacements: Record<string, string | number | null | undefined> = {},
): string {
    const keys = isObject(replacements) ? Object.keys(replacements) : [];

    if (typeof string !== 'string') {
        if (DEV) throw new TypeError('Argument 0 is not of type string');
        return '';
    }
    if (keys.length === 0) return string;

    return keys.reduce((previous, key) => {
        const regex = new RegExp(escapeRegExp(START + key + END), 'gi');
        const value = replacements[key];
        if (value === undefined || value === null) return previous;
        return previous.replace(regex, String(value));
    }, string);
}
