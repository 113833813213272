import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { debounce } from 'lodash';

import { LanguageContext } from 'containers/LanguageProvider';
import { MatchMediaProvider } from 'containers/MatchMediaProvider';
import { IconProvider } from 'containers/IconProvider';
import { CopyDetector } from 'components/Utilities/CopyDetector';
import {
    KeyboardDetector,
    KEYBOARD_DETECTOR_CLASS_NAME,
} from 'components/Utilities/KeyboardDetector';

import { toggleClass } from 'utilsTS/dom';
import { warning } from 'utilsTS/error';
import { omitProps } from 'utilsTS/react';
import { setDebugInfo } from 'utilsTS/debug';
import { ThemeContext, THEME } from './ThemeContext';

const CONTAINER_SELECTOR = '.g-bootstrap, .g-portal';
const COPYING_CLASS_NAME = 'g-is-copying';
const TOGGLE_DELAY = 100;

const handleCopyTrigger = ({
    isCopying,
    documentNode,
}: {
    isCopying: boolean;
    documentNode: Document;
}) => {
    toggleClass(CONTAINER_SELECTOR, COPYING_CLASS_NAME, isCopying, { documentNode });
};

const handleKeyboardTriggerDebounced = debounce(
    ({ usesKeyboard, documentNode }: { usesKeyboard: boolean; documentNode: Document }) => {
        toggleClass(CONTAINER_SELECTOR, KEYBOARD_DETECTOR_CLASS_NAME, usesKeyboard, {
            documentNode,
        });
    },
    TOGGLE_DELAY,
);

export interface ThemeProviderProps {
    className?: string;
    children?: React.ReactNode;

    /** Defines which theme should be used globally */
    theme?: THEME;
    /** Defines whether the component is rendered in a React Portal */
    insidePortal?: boolean;
    /** Defines whether IconProvider should be automatically included */
    withIcons?: boolean;
    /** Defines whether the six tiers from Bootstrap 5 should be used */
    useBs5Grid?: boolean;
    /** Defines whether the XL and XXL breakpoints should be extended to 1320px and 1560px respectively */
    useExtendedGrid?: boolean;
    /** Defines whether to use fluid Bootstap grid */
    useFluidGrid?: boolean;
    /** Defines whether dark mode should be used */
    useDarkMode?: boolean;
}

export interface ThemeProviderStatics {
    THEME: typeof THEME;
}

export const ThemeProvider: React.FC<ThemeProviderProps> & ThemeProviderStatics = ({
    insidePortal = false,
    className,
    children,
    ...props
}) => {
    const context = React.useContext(ThemeContext);

    const {
        theme = THEME.BS4,
        withIcons = false,
        useBs5Grid = false,
        useExtendedGrid = false,
        useFluidGrid = false,
        useDarkMode = false,
        ...rest
    } = { ...context, ...props };

    React.useEffect(() => {
        warning(
            !!context && !insidePortal,
            'It appears you are using multiple ThemeProviders. Please try to wrap a top level component with it instead of using it to wrap single components. All further ThemeProviders will be ignored',
        );

        if (!context) {
            setDebugInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getThemeClassName = () =>
        cx(
            'g-bootstrap',
            'g-bs4',
            useBs5Grid && 'g-bs5-grid',
            useFluidGrid && 'g-fluid-grid',
            useExtendedGrid && 'g-extended-grid',
            useDarkMode && 'g-dark',
            Object.values(THEME).includes(theme) && theme !== THEME.BS4 && `g-${theme}`,
            className,
        );

    const getContext = () => ({
        theme,
        className: getThemeClassName(),
        useBs5Grid,
        useExtendedGrid,
        useFluidGrid,
        useDarkMode,
    });

    const renderContent = () => (
        <LanguageContext.Consumer>
            {({ lang }) => (
                <div
                    lang={lang}
                    {...omitProps(rest, ThemeProvider)}
                    className={getThemeClassName()}
                >
                    {withIcons && <IconProvider />}
                    {children}
                </div>
            )}
        </LanguageContext.Consumer>
    );

    if (insidePortal) return renderContent();
    if (context) return children as React.ReactElement;

    return (
        <ThemeContext.Provider value={getContext()}>
            <MatchMediaProvider useXxlTier={useBs5Grid} useExtendedTiers={useExtendedGrid}>
                <CopyDetector onTrigger={handleCopyTrigger} />
                <KeyboardDetector onTrigger={handleKeyboardTriggerDebounced} />
                {renderContent()}
            </MatchMediaProvider>
        </ThemeContext.Provider>
    );
};

ThemeProvider.THEME = THEME;

ThemeProvider.displayName = 'ThemeProvider';
ThemeProvider.propTypes = {
    theme: PropTypes.oneOf(Object.values(THEME)),
    insidePortal: PropTypes.bool,
    withIcons: PropTypes.bool,
    useBs5Grid: PropTypes.bool,
    useExtendedGrid: PropTypes.bool,
    useFluidGrid: PropTypes.bool,
    useDarkMode: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
