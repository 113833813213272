import React from 'react';
import { throttle, noop } from 'lodash';
import type { DebouncedFunc, ThrottleSettings } from 'lodash'; // has to use import type, Storybook was breaking otherwise

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useThrottle<T extends (...args: any) => any>(
    callback: T,
    wait?: number,
    options?: ThrottleSettings,
): DebouncedFunc<T> {
    const callbackRef = React.useRef(throttle(noop, wait, options));

    React.useEffect(() => {
        callbackRef.current = throttle(callback, wait, options);
        return () => {
            callbackRef.current.cancel();
        };
    }, [callback, wait, options]);

    return callbackRef.current;
}
