import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/Layout';

export enum MODAL_BODY_WIDTH {
    DEFAULT = 'default',
    LARGE = 'large',
    FULL = 'full',
}

export interface ModalBodyProps extends React.ComponentPropsWithoutRef<'div'> {
    width?: MODAL_BODY_WIDTH;
    formTop?: boolean;
    formBottom?: boolean;
}

export interface ModalBodyStatics {
    WIDTH: typeof MODAL_BODY_WIDTH;
}

export const ModalBody: React.FC<ModalBodyProps> & ModalBodyStatics = ({
    width = MODAL_BODY_WIDTH.DEFAULT,
    formTop = false,
    formBottom = false,
    className,
    children,
    ...rest
}) => {
    const classNames = cx(
        'modal-body',
        width === MODAL_BODY_WIDTH.FULL && 'g-modal-body-full',
        width === MODAL_BODY_WIDTH.LARGE && 'g-modal-body-large',
        formTop && 'g-modal-body-form-top',
        formBottom && 'g-modal-body-form-bottom',
        className,
    );

    return (
        <div {...rest} className={classNames}>
            <Text>{children}</Text>
        </div>
    );
};

ModalBody.WIDTH = MODAL_BODY_WIDTH;

ModalBody.displayName = 'ModalBody';
ModalBody.propTypes = {
    width: PropTypes.oneOf(Object.values(MODAL_BODY_WIDTH)),
    formTop: PropTypes.bool,
    formBottom: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
