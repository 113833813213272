import styles from 'gds-styles/_breakpoints.scss';
import stylesExtended from 'gds-styles/_breakpoints-extended.scss';
import { checkDOM } from './checkDOM';
import { TIER } from './enum';

export enum BREAKPOINT {
    XS = styles.xs,
    SM = styles.sm,
    MD = styles.md,
    LG = styles.lg,
    XL = styles.xl,
    XXL = styles.xxl,
}

export function matchMedia(
    tier: TIER,
    defaultValue = true,
    windowNode?: Window,
    useExtendedTiers = false,
): boolean {
    const breakpoints = useExtendedTiers ? stylesExtended : styles;

    /* istanbul ignore if */
    if (!checkDOM()) return defaultValue;

    const owner = windowNode || window;
    if (!owner.matchMedia) return defaultValue;
    if (!Object.values(TIER).includes(tier)) return defaultValue;
    if (!breakpoints[tier]) return defaultValue;

    const query = `(min-width: ${breakpoints[tier]})`;
    const result = owner.matchMedia(query);

    return result.matches;
}
