export enum ALERT_VARIANT {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

export enum ALERT_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}
