/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import PropTypes from 'prop-types';

import { useWindowScroll } from 'utilsTS/hooks';

import { StripeContext } from '../StripeContext';

function getScrollProgress(scrollY: number, startY: number, endY: number) {
    const startYReal = Math.max(0, startY);
    const diffY = Math.max(0, endY - startYReal);
    const progressY = Math.max(0, scrollY - startYReal);
    const progress = progressY / diffY || 0;
    return Math.min(progress, 1);
}

function getClientHeight(ref: React.MutableRefObject<HTMLDivElement>) {
    const { clientHeight = 0 } = ref.current || {};
    return clientHeight;
}

export interface StripeHeaderScrollTransitionProps {
    children: (options: {
        largeRef: React.MutableRefObject<HTMLDivElement>;
        stickyRef: React.MutableRefObject<HTMLDivElement>;
        toolbarRef: React.MutableRefObject<HTMLDivElement>;
        scrollProgress: {
            large: number;
            sticky: number;
        };
        isTransitioningToSticky?: boolean;
        heights: {
            large: number;
            sticky: number;
            toolbar: number;
        };
    }) => React.ReactElement;

    /** Defines the offset from the top of the viewport */
    stickyOffset?: number;
}

export const StripeHeaderScrollTransition: React.FC<StripeHeaderScrollTransitionProps> = ({
    stickyOffset = 0,
    children,
}) => {
    const { toolbar, isMinMediumTier, matchingTier, useTransition } =
        React.useContext(StripeContext);

    const largeRef = React.useRef<HTMLDivElement>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const stickyRef = React.useRef<HTMLDivElement>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const toolbarRef = React.useRef<HTMLDivElement>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const { scrollY } = useWindowScroll();

    const [scrollProgress, setScrollProgress] = React.useState({ large: 0, sticky: 0 });
    const [heights, setHeights] = React.useState({ large: 0, sticky: 0, toolbar: 0 });

    const isTransitioningToSticky = useTransition && scrollProgress.sticky !== 0;

    React.useEffect(() => {
        setHeights({
            large: getClientHeight(largeRef),
            sticky: getClientHeight(stickyRef),
            toolbar: isMinMediumTier ? getClientHeight(toolbarRef) : 0,
        });
    }, [matchingTier, toolbar]);

    React.useEffect(() => {
        if (!useTransition) return;

        const { top: clientOffset } = largeRef.current.getBoundingClientRect();
        const scrollOffset = Math.max(0, clientOffset + scrollY - stickyOffset);
        const scrollThreshold = scrollOffset + heights.large + (heights.toolbar || -heights.sticky);

        const nextScrollProgress = {
            large: getScrollProgress(scrollY, scrollThreshold - heights.sticky, scrollThreshold),
            sticky: getScrollProgress(scrollY, scrollThreshold, scrollThreshold + heights.sticky),
        };

        if (
            scrollProgress.large !== nextScrollProgress.large ||
            scrollProgress.sticky !== nextScrollProgress.sticky
        ) {
            setScrollProgress(nextScrollProgress);
        }
    }, [matchingTier, scrollY, useTransition]);

    return children({
        largeRef,
        stickyRef,
        toolbarRef,
        scrollProgress,
        isTransitioningToSticky,
        heights,
    });
};

StripeHeaderScrollTransition.displayName = 'StripeHeaderScrollTransition';
StripeHeaderScrollTransition.propTypes = {
    stickyOffset: PropTypes.number,
    children: PropTypes.func.isRequired,
};
