import React from 'react';
import cx from 'classnames';

import { getColorClassName } from 'utilsTS/color';

import { AvatarContext } from '../AvatarContext';

export const AvatarInitials: React.FC = () => {
    const { initials, color } = React.useContext(AvatarContext);

    if (!initials) return null;

    return (
        <span
            aria-hidden
            className={cx(
                'g-avatar-initials',
                'no-clipboard',
                getColorClassName(color, { foreground: true }),
            )}
        >
            {initials}
        </span>
    );
};

AvatarInitials.displayName = 'AvatarInitials';
