import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Grid } from 'components/Layout';
import { TransitionGroup } from 'components/Transition';

import styles from './Snackbar.scss';

export interface SnackbarProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Accepts SnackbarItem components to be rendered */
    children?: React.ReactNode;
}

export const Snackbar: React.FC<SnackbarProps> = ({ children, className, ...rest }) => (
    <div {...rest} className={cx(styles.group, className)}>
        <Grid className={cx(styles.grid)} fluid={false}>
            <TransitionGroup component={null}>{React.Children.toArray(children)}</TransitionGroup>
        </Grid>
    </div>
);

Snackbar.displayName = 'Snackbar';
Snackbar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
