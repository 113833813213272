import React from 'react';
import PropTypes from 'prop-types';

import { omitProps } from 'utilsTS/react';

import { LanguageContext } from './LanguageContext';

export interface LanguageStringProps {
    name: string;
    value?: string;
    replacements?: Record<string, unknown>;
}

export const LanguageString: React.FC<LanguageStringProps> = ({
    name,
    value,
    replacements = {},
    ...rest
}) => {
    const { getString } = React.useContext(LanguageContext);

    const replace = {
        ...replacements,
        ...omitProps(rest, LanguageString),
    };

    return getString(name, value, replace) as unknown as React.ReactElement;
};

LanguageString.displayName = 'LanguageString';
LanguageString.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    replacements: PropTypes.shape({}),
};
