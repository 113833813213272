import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { Icon } from 'components/Icon';

import { ICON } from 'utilsTS/icon';
import { ToggleableProps } from 'utilsTS/hooks';
import { ref } from 'utilsTS/prop-types';
import { generateId } from 'utilsTS/string';

export interface ModalProps extends ToggleableProps {
    /** Defines Modal id */
    id?: string;
    /** Gives access to imperative open() and close() methods */
    refModal?: React.Ref<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    /** Defines whether the body should be replaced with a spinner */
    isLoading?: boolean;
    /** Overrides loading text of the spinner */
    loadingText?: React.ReactNode;
    /** Defines whether Modal should open in on mount */
    appear?: boolean;
    /** Defines whether Modal should animate when opening and closing */
    animate?: boolean;
    /** Defines header icon to be rendered in Modal header area */
    headerIcon?: ICON;
    /** Defines header title to be rendered in Modal header area */
    title?: React.ReactNode;
    /**
     * Accepts step string
     * @deprecated use FocusPage component instead
     * */
    stepping?: string; // DEPRECATED
    /** Accepts components to be rendered in Modal footer area */
    footer?: React.ReactNode;
    /** Defines URL of help button link in Modal header area */
    helpUrl?: string;
    /** Overrides label of help button link in Modal header area */
    helpLabel?: string;
    /** Overrides label of close button in Modal header area */
    closeLabel?: string;
    /** Defines whether close button should be rendered in Modal header area */
    closeable?: boolean;
    /** Defines whether Modal should close on clicking outside */
    closeOnClick?: boolean;
    className?: string;
    headerClassName?: string;
    footerClassName?: string;
    backdropClassName?: string;
    children: React.ReactNode;

    embedded?: boolean; // for internal use only
}

export const propTypes = {
    id: PropTypes.string,
    refModal: ref(),
    isOpen: PropTypes.bool,
    onBeforeOpen: PropTypes.func,
    onBeforeClose: PropTypes.func,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.node,
    appear: PropTypes.bool,
    animate: PropTypes.bool,
    embedded: PropTypes.bool,
    headerIcon: PropTypes.oneOf(Object.values(Icon.ICON)),
    title: PropTypes.string,
    stepping: PropTypes.string,
    footer: PropTypes.node,
    helpUrl: PropTypes.string,
    helpLabel: PropTypes.string,
    closeLabel: PropTypes.string,
    closeable: PropTypes.bool,
    closeOnClick: PropTypes.bool,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    backdropClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export const defaultProps = {
    id: undefined,
    refModal: null,
    isOpen: false,
    onBeforeOpen: undefined,
    onBeforeClose: undefined,
    isLoading: false,
    loadingText: null,
    appear: false,
    animate: true,
    headerIcon: undefined,
    title: '',
    stepping: '',
    footer: null,
    helpUrl: '',
    helpLabel: undefined,
    closeLabel: undefined,
    closeable: true,
    closeOnClick: false,
    className: undefined,
    headerClassName: undefined,
    footerClassName: undefined,
    backdropClassName: undefined,
};

export function splitProps(props: Partial<ModalProps>): {
    headerProps: Partial<ModalProps>;
    footerProps: Omit<Partial<ModalProps>, 'title'>;
    containerProps: Partial<ModalProps>;
    loadingProps: Omit<Partial<ModalProps>, 'title'>;
    children: React.ReactNode;
} {
    const {
        id,
        refModal,
        isLoading,
        loadingText,
        headerIcon,
        title,
        stepping,
        footer,
        helpUrl,
        helpLabel,
        closeLabel,
        closeable,
        closeOnClick,
        headerClassName,
        footerClassName,
        backdropClassName,
        children,
        ...rest
    } = { ...defaultProps, ...props };

    const modalId = id || generateId('modal');

    const headerProps = {
        id: modalId,
        icon: headerIcon,
        title,
        stepping,
        helpUrl,
        helpLabel,
        closeLabel,
        closeable,
        isLoading,
        className: headerClassName,
    };

    const footerProps = {
        children: footer,
        className: footerClassName,
    };

    const containerProps = {
        ...omit(rest, ['small', 'teaser', 'drawer', 'confirmation', 'reduceMotion']),
        id: modalId,
        closeable,
        closeOnEsc: closeable,
        closeOnClick: closeable && closeOnClick,
        imperativeRef: refModal,
        backdropClassName,
    };

    const loadingProps = {
        isLoading,
        loadingText,
    };

    return {
        headerProps,
        footerProps,
        containerProps,
        loadingProps,
        children,
    };
}
