import React from 'react';
import { Popper, type PopperChildrenProps } from 'react-popper';
import PropTypes from 'prop-types';
import cx from 'classnames';
import type { DebouncedFunc } from 'lodash';

import { Text } from 'components/Layout';

export interface TooltipContentProps {
    children: React.ReactNode;
    className?: string;

    /** Defines the Popper offset of the tooltip */
    offset?: number[];
    /** Is called on every mouse enter event */
    onMouseEnter?: () => void;
    /** Is called on every mouse leave event */
    onMouseLeave?: DebouncedFunc<() => void>;
}

export const TooltipContent: React.FC<TooltipContentProps> = ({
    offset = [],
    className,
    children,
    ...rest
}) => {
    const renderContent = ({ ref, style, placement, arrowProps }: PopperChildrenProps) => (
        <div
            {...rest}
            ref={ref}
            aria-hidden
            style={style}
            className={cx('tooltip', 'g-tooltip', className)}
            data-placement={placement}
        >
            <span {...arrowProps} className="g-tooltip-arrow" />
            <Text always tag="div" className={cx('tooltip-inner', 'g-tooltip-inner')}>
                {children}
            </Text>
        </div>
    );

    return (
        <Popper
            placement="top"
            modifiers={[
                {
                    name: 'flip',
                    options: { fallbackPlacements: ['top', 'bottom', 'top'] },
                },
                {
                    name: 'preventOverflow',
                    options: { rootBoundary: 'document', padding: 15 },
                },
                {
                    name: 'computeStyles',
                    options: { adaptive: false, gpuAcceleration: false },
                },
                {
                    name: 'offset',
                    // @ts-expect-error I couldn't figure out the offset typing :(
                    options: { offset: () => offset },
                },
            ]}
        >
            {renderContent}
        </Popper>
    );
};

TooltipContent.displayName = 'TooltipContent';
TooltipContent.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
