import React from 'react';

export type RenderFunction<P> = (argument: P) => React.ReactNode | void;

export function renderProp<P>(
    render: RenderFunction<P> | React.ReactNode,
    fallback: RenderFunction<P> | React.ReactNode = null,
    argument?: P extends object ? P : never,
): React.ReactNode {
    if (React.isValidElement(render)) return render;
    const props = argument ?? ({} as P);

    const isValidFunction = typeof render === 'function';
    const result = isValidFunction ? render(props) : null;

    if (result !== false && result !== null && result !== undefined) {
        return result ?? null;
    }

    if (typeof fallback === 'function') {
        return fallback(props) ?? null;
    }
    return fallback;
}
