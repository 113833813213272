import { isObject } from 'lodash';

import { SEPARATOR, WILDCARD, StructureType } from './constants';

function getPrefixedKey(key: string, prefix?: string) {
    if (!prefix) return key;
    return `${prefix}${SEPARATOR}${key}`;
}

function flattenStructureArray(array: string[], prefix: string) {
    if (array.length === 0) return [getPrefixedKey(WILDCARD, prefix)];
    return array.map((key) => getPrefixedKey(key, prefix));
}

function flattenStructure(structure: StructureType | string[], prefix = ''): string[] {
    if (!isObject(structure)) return [];
    if (Array.isArray(structure)) return flattenStructureArray(structure, prefix);

    return Object.keys(structure).reduce((aggregate: string[], key) => {
        const prefixedKey = getPrefixedKey(key, prefix);
        const keys = flattenStructure(structure[key], prefixedKey);

        return [...aggregate, ...keys];
    }, []);
}

export function transformStructureToKeys(structure: StructureType): string[] {
    return flattenStructure(structure);
}
