import React from 'react';

import { ICON } from 'utilsTS/icon';
import { isElementInChildren } from 'utilsTS/react';

import { ModalContainer, ModalHeader, ModalFooter, ModalSpinner } from './components';
import { ModalProps, propTypes, defaultProps, splitProps } from './props';

export interface ModalStatics {
    HEADER_ICON: typeof ICON;
}

export const Modal: React.FC<ModalProps> & ModalStatics = (props) => {
    const { children, containerProps, headerProps, footerProps, loadingProps } = splitProps(props);

    return (
        <ModalContainer {...containerProps}>
            <ModalHeader {...headerProps} />
            <ModalSpinner {...loadingProps}>
                {children}
                {!isElementInChildren(children, ModalFooter) && <ModalFooter {...footerProps} />}
            </ModalSpinner>
        </ModalContainer>
    );
};

Modal.HEADER_ICON = ModalHeader.ICON;

Modal.displayName = 'Modal';
Modal.propTypes = { ...propTypes };
Modal.defaultProps = { ...defaultProps }; // TODO: figure out how to avoid defaultProps
