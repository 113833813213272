import React from 'react';

import { FunctionCardGroup, FunctionCardGroupProps } from './components';

export type HorizontalFunctionCardGroupProps = Omit<
    FunctionCardGroupProps,
    'horizontal' | 'noLine' | 'footer'
>;

/** @deprecated use CardGroup or Group instead */
export const HorizontalFunctionCardGroup: React.FC<HorizontalFunctionCardGroupProps> = (props) => (
    <FunctionCardGroup {...props} horizontal={false} noLine={false} footer={null} />
);

HorizontalFunctionCardGroup.displayName = 'HorizontalFunctionCardGroup';
HorizontalFunctionCardGroup.propTypes = FunctionCardGroup.propTypes;
