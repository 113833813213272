import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { useId, useInputStringValue } from 'utilsTS/hooks';
import { type InputProps, inputPropTypes } from './inputPropTypes';

export enum TEXT_INPUT_TYPE {
    EMAIL = 'email',
    NUMBER = 'number',
    PASSWORD = 'password',
    SEARCH = 'search',
    TEL = 'tel',
    TEXT = 'text',
    URL = 'url',
}

export interface TextInputProps extends InputProps {
    innerRef?: React.Ref<HTMLInputElement>;
}

export interface TextInputStatics {
    TYPE: typeof TEXT_INPUT_TYPE;
}

export const TextInput: React.FC<TextInputProps> & TextInputStatics = ({
    innerRef,
    id: defaultId,
    name = '',
    value: controlledValue = '',
    defaultValue,
    disabled = false,
    readOnly = false,
    className,
    processValue,
    onBeforeChange = () => true,
    onChange = noop,

    type = TEXT_INPUT_TYPE.TEXT,
    placeholder,
    ...rest
}) => {
    const id = useId(type, defaultId);
    const [value, handleChange] = useInputStringValue(defaultValue, controlledValue, {
        readOnly,
        onBeforeChange,
        onChange,
        processValue,
    });

    const inputProps = { id, name, value: value || '', disabled, readOnly, type, placeholder };

    return (
        <input
            {...rest}
            {...inputProps}
            ref={innerRef}
            className={cx('form-control', className)}
            onChange={handleChange}
        />
    );
};

TextInput.TYPE = TEXT_INPUT_TYPE;

TextInput.displayName = 'TextInput';
TextInput.propTypes = {
    ...inputPropTypes,
    type: PropTypes.oneOf(Object.values(TEXT_INPUT_TYPE)),
    placeholder: PropTypes.string,
};
TextInput.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    innerRef: noop, // needed for DropdownToggleWrapper to correctly recognise this as innerRef component
};
