import React from 'react';
import PropTypes from 'prop-types';

import { NewButton as Button } from 'components/Button/NewButton';
import { ButtonGroup } from 'components/Button/ButtonGroup';
import { Text, Row, RowProps, Col } from 'components/Layout';

import { cloneChildren } from 'utilsTS/react';

import { AlertContext } from './AlertContext';
import { ALERT_SIZE } from './AlertEnums';

export interface AlertContentDetailsProps extends RowProps {
    buttons?: React.ReactNode;
}

export const AlertContentDetails: React.FC<AlertContentDetailsProps> = ({
    buttons,
    children,
    ...rest
}) => {
    const { size } = React.useContext(AlertContext);

    if (!buttons) return <Text {...rest}>{children}</Text>;

    if (size === ALERT_SIZE.SMALL) {
        return (
            <Row {...rest}>
                <Col xs={null}>{children}</Col>
                <Col md="auto">
                    <ButtonGroup>
                        {cloneChildren(buttons, {
                            variant: Button.VARIANT.TERTIARY,
                            size: Button.SIZE.SMALL,
                        })}
                    </ButtonGroup>
                </Col>
            </Row>
        );
    }

    return (
        <Row {...rest}>
            <Col md={8}>{children}</Col>
            <Col md={4}>
                <ButtonGroup>
                    {cloneChildren(buttons, { variant: Button.VARIANT.SECONDARY })}
                </ButtonGroup>
            </Col>
        </Row>
    );
};

AlertContentDetails.displayName = 'AlertContentDetails';
AlertContentDetails.propTypes = {
    buttons: PropTypes.node,
    children: PropTypes.node,
};
