import React from 'react';
import PropTypes from 'prop-types';

import { error } from 'utilsTS/error';
import { getDisplayName } from 'utilsTS/react';

import { ThemeContext } from './ThemeContext';

export interface ThemeDetectorProps {
    /** Defines which component should be mentioned in the warning */
    component: string | React.ElementType;
}

export const ThemeDetector: React.FC<ThemeDetectorProps> = ({ component }) => {
    const context = React.useContext(ThemeContext);

    React.useEffect(() => {
        const name = getDisplayName(component);

        error(
            !context,
            `${name} components will not work without wrapping your application with a ThemeProvider.`,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

ThemeDetector.displayName = 'ThemeDetector';
ThemeDetector.propTypes = {
    component: PropTypes.elementType.isRequired,
};
