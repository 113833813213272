import React from 'react';
import PropTypes from 'prop-types';
import { isObject } from 'lodash';
import cx from 'classnames';

enum WIDTHS {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    xxl = 'xxl',
}
const MAX_WIDTH = 12;

type COL_ORDER = 0 | 1 | 2 | 3 | 4 | 5;
type COL_OFFSET = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
type COL_SIZE = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ColOffset = COL_OFFSET | `${COL_OFFSET}`;
export type ColSize = 0 | COL_SIZE | `${COL_SIZE}` | boolean | 'auto' | '' | null;

export type ColTier =
    | ColSize
    | {
          size?: ColSize;
          order?: COL_ORDER | `${COL_ORDER}` | 'first' | 'last';
          offset?: ColOffset;
      };

export interface ColProps extends React.ComponentPropsWithRef<'div'> {
    /** Accepts a tag or a component replacing the wrapper */
    tag?: React.ElementType;
    /** Defines size, order, and offset in XS tier */
    xs?: ColTier;
    /** Defines size, order, and offset in SM tier */
    sm?: ColTier;
    /** Defines size, order, and offset in MD tier */
    md?: ColTier;
    /** Defines size, order, and offset in LG tier */
    lg?: ColTier;
    /** Defines size, order, and offset in XL tier */
    xl?: ColTier;
    /** Defines size, order, and offset in XXL tier */
    xxl?: ColTier;
}

export interface ColStatics {
    WIDTHS: typeof WIDTHS;
    MAX_WIDTH: 12;
}

const getColumnSizeClass = (isXs: boolean, colWidth: WIDTHS, colSize?: ColSize) => {
    if (colSize === true || colSize === '') return isXs ? 'col' : `col-${colWidth}`;
    if (colSize === 'auto') return isXs ? 'col-auto' : `col-${colWidth}-auto`;
    return isXs ? `col-${colSize}` : `col-${colWidth}-${colSize}`;
};

/* eslint-disable react/prop-types */
export const Col = React.forwardRef(
    (
        { tag: Tag = 'div', xs = MAX_WIDTH, sm, md, lg, xl, xxl, className, ...rest },
        forwardedRef,
    ) => {
        const widthProps = { xs, sm, md, lg, xl, xxl };

        const colClasses = Object.values(WIDTHS).reduce((previous: string[], colWidth, index) => {
            const isXs = !index;
            const columnProp = isXs && !widthProps[colWidth] ? true : widthProps[colWidth];

            if (!columnProp && columnProp !== '') {
                return previous;
            }

            if (!isObject(columnProp)) {
                return [...previous, getColumnSizeClass(isXs, colWidth, columnProp)];
            }

            const { size, order, offset } = columnProp;
            const colSizeInterfix = isXs ? '-' : `-${colWidth}-`;
            const colClass = getColumnSizeClass(isXs, colWidth, columnProp.size);

            return [
                ...previous,
                cx({
                    [colClass]: size || size === '',
                    [`order${colSizeInterfix}${order}`]: order || order === 0,
                    [`offset${colSizeInterfix}${offset}`]: offset || offset === 0,
                }),
            ];
        }, []);

        const classNames = cx(colClasses, className);

        return <Tag ref={forwardedRef} {...rest} className={classNames} />;
    },
) as React.ForwardRefExoticComponent<ColProps> & ColStatics;

Col.WIDTHS = WIDTHS;
Col.MAX_WIDTH = MAX_WIDTH;

const columnProps = PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
        size: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
]);

Col.displayName = 'Col';
Col.propTypes = {
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
    xs: columnProps as React.Validator<ColTier | undefined> | undefined,
    sm: columnProps as React.Validator<ColTier | undefined> | undefined,
    md: columnProps as React.Validator<ColTier | undefined> | undefined,
    lg: columnProps as React.Validator<ColTier | undefined> | undefined,
    xl: columnProps as React.Validator<ColTier | undefined> | undefined,
    xxl: columnProps as React.Validator<ColTier | undefined> | undefined,
    className: PropTypes.string,
};
