import React from 'react';

export const COLOR = 'rgba(126, 91, 239, 0.66)';
export const BORDER = 3;

export const outline: Partial<React.CSSProperties> = {
    outline: `${BORDER}px solid ${COLOR}`,
};

export const component: Partial<React.CSSProperties> = {
    position: 'absolute',
    background: COLOR,
    color: 'white',
    padding: '5px 10px 2px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '12px',
    zIndex: 99999999,
};

export const info: Partial<React.CSSProperties> = {
    ...component,
    position: 'fixed',
    bottom: 0,
    left: 0,
    padding: '10px',
    fontWeight: 'normal',
    borderTopLeftRadius: '0',
};
