import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { LanguageString } from 'containers/LanguageProvider';

import { NewButton as Button, ButtonProps } from 'components/Button/NewButton';
import { Text } from 'components/Layout';
import { TransitionCollapseHeight } from 'components/Transition';

import { ToggleableContext } from 'utilsTS/hooks';
import { renderProp, omitProps } from 'utilsTS/react';

import { CollapsibleButton } from './CollapsibleButton';

import styles from './Collapsible.scss';

export interface CollapsibleHeaderProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Accepts a component replacing the wrapper */
    component?: React.ElementType;
    /** Defines whether a toggle button should be rendered */
    useButton?: boolean;
    /**
     * Defines label of the button in open state
     * @default language string "common.collapse"
     */
    collapseLabel?: string;
    /**
     * Defines label of the button in closed state
     * @default language string "common.expand"
     */
    expandLabel?: string;
    /** Defines whether the label should be shown */
    showLabel?: boolean;
    /** Defines whether the header should hide in open state */
    hideOnExpand?: boolean;
    /** Defines whether the header should react to clicks */
    collapseOnClick?: boolean;
    /** Defines whether the header should render the button in the center */
    centered?: boolean;
    /** Defines whether the header should render the button vertically centered */
    verticallyCentered?: boolean;
    /**
     * Defines the variant of the toggle button
     * @deprecated use button prop instead
     */
    buttonVariant?: ButtonProps['variant'];
    /**
     * Accepts additional className for the toggle button
     * @deprecated use button prop instead
     */
    buttonClassName?: string;
    /**
     * Renders the toggle button
     * @deprecated use button prop instead
     *  */
    renderButton?: (props: { button?: React.ReactElement; isOpen?: boolean }) => React.ReactNode;
    /** Accepts CollapsibleButton component to render as toggle */
    button?:
        | React.ReactNode
        | ((props: { button?: React.ReactElement; isOpen?: boolean }) => React.ReactNode);

    // TODO: replace this with componentProps prop
    [index: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = ({
    component: HeaderComponent = 'div',
    useButton = true,
    renderButton = noop,
    button,
    collapseLabel,
    expandLabel,
    showLabel = true,
    collapseOnClick = true,
    hideOnExpand,
    centered,
    verticallyCentered,
    buttonVariant = Button.VARIANT.TERTIARY,
    buttonClassName,
    className,
    children,
    ...rest
}) => {
    const { isOpen, id: collapsibleId } = React.useContext(ToggleableContext);

    const renderChildren = () => {
        if (typeof children !== 'string') return children;
        return <Text className="g-faded">{children}</Text>;
    };

    const renderCollapsibleButton = () => {
        const defaultButton = (
            <CollapsibleButton
                variant={buttonVariant}
                coverParent={collapseOnClick}
                centered={centered}
                verticallyCentered={verticallyCentered}
                showLabel={showLabel}
                className={cx(buttonClassName, !useButton && styles.buttonInvisible)}
            >
                {isOpen && !hideOnExpand ? (
                    <LanguageString name="common.collapse" value={collapseLabel} />
                ) : (
                    <LanguageString name="common.expand" value={expandLabel} />
                )}
            </CollapsibleButton>
        );

        const props = { button: defaultButton, isOpen };
        return renderProp(button, button, props) ?? renderProp(renderButton, defaultButton, props);
    };

    const renderContent = () => {
        const renderedChildren = renderChildren();
        const renderedButton = renderCollapsibleButton();

        if (centered) return renderedButton;
        if (!renderedButton) return renderedChildren;

        return (
            <>
                {renderedChildren}
                {renderedButton}
            </>
        );
    };

    const renderHeader = () => (
        <HeaderComponent
            {...omitProps(rest, CollapsibleHeader)}
            id={`${collapsibleId}-header`}
            className={cx(styles.header, centered && styles.centered, className)}
        >
            {renderContent()}
        </HeaderComponent>
    );
    if (!hideOnExpand) return renderHeader();

    return <TransitionCollapseHeight show={!isOpen}>{renderHeader()}</TransitionCollapseHeight>;
};

CollapsibleHeader.displayName = 'CollapsibleHeader';
CollapsibleHeader.propTypes = {
    component: PropTypes.elementType as React.Validator<React.ElementType>,
    useButton: PropTypes.bool,
    renderButton: PropTypes.func,
    button: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    tabIndex: PropTypes.number,
    collapseLabel: PropTypes.string,
    expandLabel: PropTypes.string,
    showLabel: PropTypes.bool,
    collapseOnClick: PropTypes.bool,
    centered: PropTypes.bool,
    verticallyCentered: PropTypes.bool,
    hideOnExpand: PropTypes.bool,
    buttonVariant: PropTypes.oneOf(Object.values(Button.VARIANT)),
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
};
