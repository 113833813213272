export enum ICON_SIZE {
    SIZE_16 = '16',
    /** @deprecated */
    SIZE_20 = '20', // DEPRECATED
    SIZE_24 = '24',
    SIZE_48 = '48',
    SIZE_64 = '64',
    SIZE_96 = '96',
    SIZE_128 = '128',
}

export const LARGE_ICON_SUFFIX = '-xl';
