import React from 'react';
import { KEY } from './enum';

export function eventHasKey(
    event: React.KeyboardEvent | KeyboardEvent,
    ...keys: (KEY | string)[]
): boolean {
    if (!event.key) return false;

    return keys.some((key) => {
        if (key.length === 1) return event.key === key; // to differentiate between X and x events
        return event.key.toLowerCase() === key.toLowerCase();
    });
}
