import { createContext } from 'utilsTS/react';

export interface PortalContextType {
    active: boolean;
    containerNode?: Element | null;
}

export const PortalContext = createContext<PortalContextType>('Portal', {
    active: true,
    containerNode: undefined,
});
