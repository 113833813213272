import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { NewButton as Button } from 'components/Button/NewButton';
import { StripeHeader } from 'components/Stripe';
import { useMatchMediaTier, TIER } from 'containers/MatchMediaProvider';

import { elementOfType } from 'utilsTS/prop-types';
import { cloneElement } from 'utilsTS/react';

import { PageContent, PageContentProps } from '../components';

export enum FOCUS_PAGE_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export interface FocusPageProps extends PageContentProps, React.ComponentPropsWithoutRef<'div'> {
    children: React.ReactNode;

    /** Defines the size of the FocusPage */
    size?: FOCUS_PAGE_SIZE;
    /** Accepts a StripeHeader component to be rendered */
    stripeHeader: React.ReactNode;
    /** Accepts a Stepper component to be rendered */
    stepper?: React.ReactNode;
    /** Accepts a Button component to be rendered on the left */
    previousButton?: React.ReactNode;
    /** Accepts a Button component to be rendered on the right */
    nextButton?: React.ReactNode;
}

export interface FocusPageStatics {
    SIZE: typeof FOCUS_PAGE_SIZE;
}

export const FocusPage: React.FC<FocusPageProps> & FocusPageStatics = ({
    size = FOCUS_PAGE_SIZE.MEDIUM,
    stripeHeader,
    stepper,
    previousButton,
    nextButton,
    increasePaddings = true,
    children,
    className,
    ...rest
}) => {
    const isAboveMD = useMatchMediaTier(TIER.LG);

    const buttonProps = {
        variant: Button.VARIANT.SECONDARY,
        hiddenLabel: Button.HIDDEN_LABEL.ALWAYS,
    };
    const smallButtonProps = {
        variant: Button.VARIANT.TERTIARY,
    };

    return (
        <>
            {stripeHeader}
            <div {...rest} className={cx('g-focus-page', cx(className, `g-focus-page-${size}`))}>
                {stepper && <div className="g-focus-page-stepper">{stepper}</div>}
                {(previousButton || nextButton) && (
                    <div className="g-focus-page-buttons">
                        {cloneElement(previousButton, {
                            ...(isAboveMD ? buttonProps : smallButtonProps),
                            icon: Button.ICON.CARET_LEFT,
                        })}
                        {cloneElement(nextButton, {
                            ...(isAboveMD ? buttonProps : smallButtonProps),
                            icon: Button.ICON.CARET_RIGHT,
                        })}
                    </div>
                )}
                <PageContent increasePaddings={increasePaddings}>{children}</PageContent>
            </div>
        </>
    );
};

FocusPage.SIZE = FOCUS_PAGE_SIZE;

FocusPage.displayName = 'FocusPage';
FocusPage.propTypes = {
    ...PageContent.propTypes,
    stripeHeader: elementOfType([StripeHeader]).isRequired,
    size: PropTypes.oneOf(Object.values(FOCUS_PAGE_SIZE)),
    stepper: PropTypes.node,
    previousButton: PropTypes.node,
    nextButton: PropTypes.node,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
