import 'custom-event-polyfill';
import elementClosest from 'element-closest';
import smoothscroll from 'smoothscroll-polyfill';

/* eslint-disable no-prototype-builtins, unicorn/prefer-node-remove */

if (typeof window !== 'undefined') {
    elementClosest(window);

    if (!('scrollBehavior' in document.documentElement.style)) {
        smoothscroll.polyfill();
    }

    ((array) => {
        array.forEach((item) => {
            if (item.hasOwnProperty('remove')) {
                return;
            }
            Object.defineProperty(item, 'remove', {
                configurable: true,
                enumerable: true,
                writable: true,
                value: function remove() {
                    this.parentNode?.removeChild(this);
                },
            });
        });
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype].filter(Boolean));
}
