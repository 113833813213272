import { TIER } from 'utilsTS/dom';

export interface OptionalTiersObject<T> extends Record<string, T | undefined> {
    [TIER.XS]?: T;
    [TIER.SM]?: T;
    [TIER.MD]?: T;
    [TIER.LG]?: T;
    [TIER.XL]?: T;
    [TIER.XXL]?: T;
}

// @ts-expect-error I'm not sure what type the data argument should be here
function isOptionalTiersObject(data): data is OptionalTiersObject<unknown> {
    if (!data) return false;
    const tiers = Object.values(TIER) as string[];
    return tiers.some((tier) => data[tier] !== undefined);
}

export function completeTierPropObject<T>(
    data?: OptionalTiersObject<T> | T,
): OptionalTiersObject<T> {
    const tiers = Object.values(TIER) as string[];

    // if data doesnt contain any defined tier then it should be treated as a value and assigned to all the tiers
    const isTiersObjectWithValue =
        isOptionalTiersObject(data) && Object.values(data).some((tier) => tier !== undefined);

    if (!isTiersObjectWithValue) {
        const result: OptionalTiersObject<T> = {};
        tiers.forEach((tier) => {
            result[tier] = data as T;
        });
        return result;
    }

    const tiersData = data as OptionalTiersObject<T>;
    const result: OptionalTiersObject<T> = {};

    // Fill leading values
    // { md: 10 } -> { md: 10, lg: 10, xl: 10, xxl: 10 }
    let lastNonEmpty: T | undefined;
    tiers.forEach((tier) => {
        const tierValue = tiersData[tier];
        if (tierValue !== undefined) {
            lastNonEmpty = tierValue;
            result[tier] = tierValue;
        } else if (lastNonEmpty !== undefined) {
            result[tier] = lastNonEmpty;
        }
    });

    // Fill remaining trailing values
    // { md: 10, lg: 10, xl: 10 } -> { xs: 10, sm: 10, md: 10, lg: 10, xl: 10, xxl: 10 }
    lastNonEmpty = undefined;
    tiers.reverse().forEach((tier) => {
        const tierValue = result[tier];
        if (tierValue !== undefined) {
            lastNonEmpty = tierValue;
        } else if (lastNonEmpty !== undefined) {
            result[tier] = lastNonEmpty;
        }
    });

    return result;
}
