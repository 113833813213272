import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ConditionalSpinner, ConditionalSpinnerProps } from 'components/Spinner';

import styles from '../Modal.scss';

import { ModalBody } from './ModalBody';

export interface ModalSpinnerProps extends ConditionalSpinnerProps {
    isLoading?: boolean;
    loadingText?: React.ReactNode;
}

export const ModalSpinner: React.FC<ModalSpinnerProps> = ({
    isLoading = false,
    loadingText = '',
    className,
    children,
    ...rest
}) => {
    if (!isLoading) return children as React.ReactElement;

    const classNames = cx(styles.spinner, className);

    return (
        <ModalBody className={classNames}>
            <ConditionalSpinner {...rest} tag="p" active={isLoading} text={loadingText} />
        </ModalBody>
    );
};

ModalSpinner.displayName = 'ModalSpinner';
ModalSpinner.propTypes = {
    isLoading: PropTypes.bool,
    loadingText: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node,
};
