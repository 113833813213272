import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { PortalContext } from 'containers/PortalProvider';
import { ThemeProvider, ThemeDetector } from 'containers/ThemeProvider';

import { checkDOM } from 'utilsTS/dom';
import { omitProps } from 'utilsTS/react';

export interface PortalProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Defines whether the Portal is active */
    active?: boolean;
    /** Accepts a node to render into */
    containerNode?: Element | null;
}

export class Portal extends React.Component<PortalProps> {
    static displayName = 'Portal';

    static propTypes = {
        active: PropTypes.bool,
        containerNode: PropTypes.shape({}) as React.Validator<Element | null | undefined>,
        className: PropTypes.string,
        children: PropTypes.element,
    };

    static contextType = PortalContext;

    static defaultProps = {
        active: true,
        containerNode: undefined,
        className: undefined,
        children: null,
    };

    // eslint-disable-next-line react/static-property-placement
    context!: React.ContextType<typeof PortalContext>;

    node: HTMLElement | null = null;

    componentWillUnmount() {
        this.removeContainerNode();
    }

    componentDidUpdate() {
        if (!this.node) return;
        if (!this.isValidPortal() || this.getContainerNode() !== this.node) {
            this.removeContainerNode();
        }
    }

    isValidPortal() {
        const { active, children } = this.props;
        const { active: defaultActive } = this.context;

        if (!children || !active || !defaultActive) {
            return false;
        }

        /* istanbul ignore if */
        if (!checkDOM()) {
            return false;
        }

        return true;
    }

    getContainerNode() {
        const { containerNode } = this.props;
        const { containerNode: defaultNode } = this.context;

        if (containerNode instanceof HTMLElement) {
            return containerNode;
        }

        if (defaultNode) {
            return defaultNode;
        }

        if (!this.node) {
            this.node = document.createElement('div');
            document.body.appendChild(this.node);
        }

        return this.node;
    }

    removeContainerNode() {
        if (this.node) {
            document.body.removeChild(this.node);
            this.node = null;
        }
    }

    renderContent() {
        const { className, children, ...rest } = this.props;

        const classNames = cx('g-portal', className);

        return (
            <ThemeProvider {...omitProps(rest, Portal)} insidePortal className={classNames}>
                <ThemeDetector component={Portal} />
                {children}
            </ThemeProvider>
        );
    }

    render() {
        const { children } = this.props;

        if (!this.isValidPortal()) {
            return children;
        }

        return ReactDOM.createPortal(this.renderContent(), this.getContainerNode());
    }
}
