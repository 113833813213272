export const SEPARATOR = '.';
export const WILDCARD = '*';

export type StructureType = Record<string, string[] | Record<string, string[]>>;

export const STRUCTURE = {
    common: [
        'clear',
        'close',
        'collapse',
        'expand',
        'externalLink',
        'help',
        'menu',
        'more',
        'previous',
        'next',
        'page',
    ],
    format: ['date', 'isoDate', 'screenreaderDate'],
    accountNumber: ['mask'],
    amount: {
        currency: [], // empty array -> any key can be stored and retrieved
    },
    dropdown: ['selectYear'],
    fixed: {
        fixedButton: ['backToTop'],
    },
    form: {
        label: ['optional'],
    },
    input: {
        date: ['button', 'rangeButton', 'nextMonth', 'previousMonth'],
        file: ['browse', 'delete', 'drag', 'drop', 'uploading'],
        select: ['selectAll', 'selectedItems', 'selectedSummary'],
        productSelect: ['selectAll', 'selectedItems'],
        time: ['hoursAnnouncement', 'minutesAnnouncement'],
    },
    spinner: ['message'],
    stepper: ['compactInfo'],
    table: {
        sort: ['column', 'select', 'ascending', 'descending'],
    },
    transaction: {
        header: [
            'date',
            'title',
            'info',
            'titleSecondary',
            'infoSecondary',
            'amount',
            'amountSecondary',
            'avatar',
            'badges',
            'indicators',
            'misc',
            'alert',
            'statement',
            'valuta',
            'menu',
            'overlay',
            'detail',
            'selectable',
        ],
        format: ['date', 'largeDate', 'largeDateYear'],
        indicator: ['unread', 'star', 'attachment', 'file', 'location', 'additionalCard'],
    },
};

// DEPRECATED
export const KEYS = [
    'actions',
    'additionalInformation',
    'amount',
    'browseFiles',
    'categoriesOrState',
    'close',
    'collapse',
    'date',
    'dateButton',
    'delete',
    'description',
    'details',
    'dragFiles',
    'dropFiles',
    'expand',
    'help',
    'indicators',
    'loading',
    'optional',
    'selectable',
    'selectAll',
    'selectAllProducts',
    'selectedItems',
    'selectedProducts',
    'stepperLabel',
    'title',
    'type',
    'uploading',
];

// DEPRECATED
export const KEY_MIGRATION_MAP = {
    actions: 'transaction.header.overlay',
    additionalInformation: 'transaction.header.misc',
    amount: 'transaction.header.amount',
    browseFiles: 'input.file.browse',
    categoriesOrState: 'transaction.header.badges',
    close: 'common.close',
    collapse: 'common.collapse',
    date: 'transaction.header.date',
    dateButton: 'input.date.button',
    delete: 'input.file.delete',
    description: 'transaction.header.info',
    details: 'transaction.header.detail',
    dragFiles: 'input.file.drag',
    dropFiles: 'input.file.drop',
    expand: 'common.expand',
    help: 'common.help',
    indicators: 'transaction.header.indicators',
    loading: 'spinner.message',
    optional: 'form.label.optional',
    selectable: 'transaction.header.selectable',
    selectAll: 'input.select.selectAll',
    selectAllProducts: 'input.productSelect.selectAll',
    selectedItems: 'input.select.selectedItems',
    selectedProducts: 'input.productSelect.selectedItems',
    stepperLabel: 'stepper.compactInfo',
    title: 'transaction.header.title',
    type: 'transaction.header.avatar',
    uploading: 'input.file.uploading',
};
