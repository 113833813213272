import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { TransitionStatus } from 'react-transition-group/Transition';

import { cloneElement } from 'utilsTS/react';

import { TransitionCollapse, TransitionCollapseProps } from './TransitionCollapse';

export type TransitionCollapseHeightProps = TransitionCollapseProps;

export const TransitionCollapseHeight: React.FC<TransitionCollapseHeightProps> = ({
    deferHeightCalculation,
    onEnter,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
    className,
    children,
    ...rest
}) => {
    const [state, setState] = React.useState<{ height: number | null; realHeight: number | null }>({
        height: null,
        realHeight: null,
    });

    const getRealHeight = (node: HTMLElement) => {
        const { realHeight } = state;

        if (realHeight !== null) return realHeight;

        const { scrollHeight, clientHeight } = node;
        const style = window.getComputedStyle(node);
        const maxHeight = Number.parseInt(style.getPropertyValue('max-height'), 10);
        const height = clientHeight || scrollHeight;

        return maxHeight ? Math.min(maxHeight, height) : height;
    };

    const setHeight = (node: HTMLElement, height?: number | null) => {
        const realHeight = getRealHeight(node);

        setState({
            realHeight: height === null ? null : realHeight,
            height: height === undefined ? realHeight : height,
        });
    };

    const handleEnter = (node: HTMLElement, isAppearing: boolean) => {
        if (deferHeightCalculation) setState((previous) => ({ ...previous, height: 0 }));
        else setHeight(node, 0);

        if (onEnter) onEnter(node, isAppearing);
    };

    const handleEntering = (node: HTMLElement, isAppearing: boolean) => {
        if (deferHeightCalculation) window.requestAnimationFrame(() => setHeight(node));
        else setHeight(node);

        if (onEntering) onEntering(node, isAppearing);
    };

    const handleEntered = (node: HTMLElement, isAppearing: boolean) => {
        setHeight(node, null);
        if (onEntered) onEntered(node, isAppearing);
    };

    const handleExit = (node: HTMLElement) => {
        setHeight(node);
        if (onExit) onExit(node);
    };

    const handleExiting = (node: HTMLElement) => {
        // getting this variable triggers a reflow
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-underscore-dangle
        const _unused = node.offsetHeight;
        setHeight(node, 0);
        if (onExiting) onExiting(node);
    };

    const handleExited = (node: HTMLElement) => {
        setHeight(node, null);
        if (onExited) onExited(node);
    };

    const { height } = state;

    return (
        <TransitionCollapse
            {...rest}
            onEnter={handleEnter}
            onEntering={handleEntering}
            onEntered={handleEntered}
            onExit={handleExit}
            onExiting={handleExiting}
            onExited={handleExited}
        >
            {(status: TransitionStatus) => {
                const child = isFunction(children)
                    ? children(status)
                    : React.Children.only(children);

                const isCollapsed = height === 0 || status === 'exited';
                const styleHeight = height === null ? {} : { height };
                const styleOut = isCollapsed ? { marginTop: 0, marginBottom: 0, minHeight: 0 } : {};

                return cloneElement(child, {
                    className,
                    style: { ...styleHeight, ...styleOut },
                });
            }}
        </TransitionCollapse>
    );
};

TransitionCollapseHeight.displayName = 'TransitionCollapseHeight';
TransitionCollapseHeight.propTypes = {
    deferHeightCalculation: PropTypes.bool,
    onEnter: PropTypes.func,
    onEntering: PropTypes.func,
    onEntered: PropTypes.func,
    onExit: PropTypes.func,
    onExiting: PropTypes.func,
    onExited: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};
