import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/Layout';
import { TransitionCollapseHeight } from 'components/Transition';

import { ToggleableContext } from 'utilsTS/hooks/useToggleable';

import styles from './Collapsible.scss';

export interface CollapsibleContentProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Accepts a component replacing the wrapper */
    component?: React.ElementType;
    /** Defines whether the component should animate */
    appear?: boolean;
    /** Defines whether the component should animate in a different way :D */
    deferHeightCalculation?: boolean;

    // TODO: replace this with componentProps prop
    [index: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const handleClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();
};

export const CollapsibleContent: React.FC<CollapsibleContentProps> = ({
    appear = false,
    deferHeightCalculation = false,
    component: ContentComponent = 'div',
    className,
    children,
    ...rest
}) => {
    const { isOpen, onOpen, onClose, id: collapsibleId } = React.useContext(ToggleableContext);

    return (
        <TransitionCollapseHeight
            appear={appear}
            show={isOpen}
            deferHeightCalculation={deferHeightCalculation}
            onEntered={onOpen}
            onExited={onClose}
        >
            <ContentComponent
                {...rest}
                id={`${collapsibleId}-content`}
                className={cx(styles.content, className)}
                onClick={handleClick}
            >
                <Text>{children}</Text>
            </ContentComponent>
        </TransitionCollapseHeight>
    );
};

CollapsibleContent.displayName = 'CollapsibleContent';
CollapsibleContent.propTypes = {
    appear: PropTypes.bool,
    deferHeightCalculation: PropTypes.bool,
    component: PropTypes.elementType as React.Validator<React.ElementType>,
    className: PropTypes.string,
    children: PropTypes.node,
};
