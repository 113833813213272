import getDisplayName from './getDisplayName';
import findElement from './findElement';

export default function isElementOfComponent(element, nameOrComponent, options = {}) {
    const { maxDepth = undefined, diveIntoChildren = true, onlySingleChildren = true } = options;
    const componentDisplayName = getDisplayName(nameOrComponent);

    const predicate = (item) => {
        const elementDisplayName = getDisplayName(item);

        if (!componentDisplayName || !elementDisplayName) {
            return false;
        }

        if (typeof nameOrComponent === 'string') {
            return elementDisplayName === nameOrComponent;
        }

        if (nameOrComponent instanceof RegExp) {
            return nameOrComponent.test(elementDisplayName);
        }

        if (elementDisplayName === componentDisplayName) {
            return true;
        }

        return componentDisplayName.includes(`(${elementDisplayName})`);
    };

    const matchingElement = findElement(element, predicate, {
        maxDepth: diveIntoChildren ? maxDepth : 0,
        onlySingleChildren,
    });

    return !!matchingElement;
}
