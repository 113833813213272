import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export interface ImageProps extends React.ComponentPropsWithoutRef<'img'> {
    /** Defines alternate text for screen readers */
    alt: string;
    /** Defines URL of the (raster or vector) image */
    src: string;
}

export const Image: React.FC<ImageProps> = ({ alt, src, className, ...rest }) => (
    <img {...rest} alt={alt} src={src} className={cx('g-image', className)} />
);

Image.displayName = 'Image';
Image.propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
};
