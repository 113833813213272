import PropTypes from 'prop-types';
import * as GDSPropTypes from 'utils/prop-types';
import { DEV } from 'utils/constants';

Object.keys(GDSPropTypes).forEach((type) => {
    if (PropTypes[type]) {
        if (!DEV) return;
        throw new Error(`The type \`${type}\` already exists in PropTypes`);
    }
    // eslint-disable-next-line import/namespace
    PropTypes[type] = GDSPropTypes[type];
});
