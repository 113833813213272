import { noop } from 'lodash';

import { ICON } from 'utilsTS/icon';
import { COLOR } from 'utilsTS/color';
import { createContext } from 'utilsTS/react';

import { AVATAR_SIZE, AVATAR_HOVER_ICON } from './AvatarEnums';

export interface AvatarContextTypes {
    icon?: ICON;
    initials?: string;
    color?: COLOR;
    imageUrl?: string;
    imageContain?: boolean;
    iconClassName?: string;
    imageClassName?: string;
    size?: AVATAR_SIZE;
    /** @deprecated */
    hoverIcon?: AVATAR_HOVER_ICON; // DEPRECATED
    /** @deprecated */
    hoverIconLabel?: string; // DEPRECATED
    /** @deprecated */
    hoverIconProps?: React.HTMLAttributes<HTMLButtonElement>; // DEPRECATED
    badgeColor?: COLOR;
    badgeIcon?: ICON;
    isValidImageUrl?: boolean;
    setIsValidImageUrl: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AvatarContext = createContext<AvatarContextTypes>('Avatar', {
    setIsValidImageUrl: noop,
});
