import PropTypes from 'prop-types';

export function createTypeChecker<T>(validate: PropTypes.Validator<T>): PropTypes.Requireable<T> {
    const chainedCheckType: PropTypes.Requireable<T> = (
        props,
        propName,
        componentName,
        location,
        propFullName,
    ) => {
        if (props[propName] == null) return null;
        return validate(props, propName, componentName, location, propFullName);
    };

    chainedCheckType.isRequired = (props, propName, componentName, location, propFullName) => {
        if (props[propName] == null) {
            return new Error(
                `The ${location} \`${propName}\` is marked as required in \`${componentName}\`. but its value is \`undefined\`.`,
            );
        }

        return validate(props, propName, componentName, location, propFullName);
    };

    return chainedCheckType;
}
