import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ClickOutside, ClickOutsideProps } from 'components/Utilities';

import { omitProps } from 'utilsTS/react';
import { ToggleableContext } from 'utilsTS/hooks';

import styles from '../Modal.scss';

export interface ModalDialogProps extends Omit<ClickOutsideProps, 'onTrigger' | 'children'> {
    small?: boolean;
    teaser?: boolean;
    drawer?: boolean;
    confirmation?: boolean;
    embedded?: boolean;
    closeOnEsc?: boolean;
    closeOnClick?: boolean;
    children?: React.ReactNode;
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
    small = false,
    teaser = false,
    drawer = false,
    confirmation = false,
    embedded = false,
    closeOnEsc = true,
    closeOnClick = false,
    id,
    className,
    children,
    ...rest
}) => {
    const { close } = React.useContext(ToggleableContext);

    const classNames = cx(
        'modal',
        'g-modal',
        !small && !teaser && !confirmation && 'g-modal-regular',
        small && 'g-modal-small',
        teaser && 'g-modal-teaser',
        drawer && 'g-modal-drawer',
        confirmation && 'g-modal-confirmation',
        embedded && 'g-modal-embedded',
        styles.modal,
    );

    return (
        <div className={classNames}>
            <ClickOutside
                {...omitProps(rest, ModalDialog)}
                aria-modal
                id={id}
                role="dialog"
                aria-describedby={`${id}-title`}
                triggerOnEsc={closeOnEsc}
                triggerOnClick={closeOnClick}
                className={cx('modal-dialog', styles.dialog, className)}
                onTrigger={close}
            >
                <div className={cx('modal-content', styles.content)}>{children}</div>
            </ClickOutside>
        </div>
    );
};

ModalDialog.displayName = 'ModalDialog';
ModalDialog.propTypes = {
    id: PropTypes.string.isRequired,
    small: PropTypes.bool,
    teaser: PropTypes.bool,
    drawer: PropTypes.bool,
    confirmation: PropTypes.bool,
    embedded: PropTypes.bool,
    closeOnClick: PropTypes.bool,
    closeOnEsc: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
