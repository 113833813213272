import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTheme } from 'containers/ThemeProvider';
import { Grid, GridProps } from 'components/Layout';

import { StripeContext } from '../StripeContext';

import { StripeHeaderLeft } from './StripeHeaderLeft';
import { StripeHeaderCenter } from './StripeHeaderCenter';
import { StripeHeaderRight } from './StripeHeaderRight';

import styles from './StripeHeaderBasicGrid.scss';

export const StripeHeaderBasicGrid: React.FC<GridProps> = ({ className, ...rest }) => {
    const { useFluidGrid } = useTheme();

    const context = React.useContext(StripeContext);
    const { fullWidth, isTransparentVariant } = context;

    const basicContext = React.useMemo(
        () => ({
            ...context,
            isLargeVariant: false,
        }),
        [context],
    );

    return (
        <StripeContext.Provider value={basicContext}>
            <Grid
                {...rest}
                fluid={fullWidth || useFluidGrid}
                className={cx(
                    styles.grid,
                    isTransparentVariant && styles.transparentGrid,
                    className,
                )}
            >
                <div className={styles.left}>
                    <StripeHeaderLeft />
                </div>
                <div className={cx(styles.center, isTransparentVariant && styles.transparent)}>
                    <StripeHeaderCenter />
                </div>
                <div className={styles.right}>
                    <StripeHeaderRight />
                </div>
            </Grid>
        </StripeContext.Provider>
    );
};

StripeHeaderBasicGrid.displayName = 'StripeHeaderBasicGrid';
StripeHeaderBasicGrid.propTypes = {
    className: PropTypes.string,
};
