import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { ref } from 'utilsTS/prop-types';

export enum INPUT_TYPE {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    RADIOSET = 'radioset',
}

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
    innerRef?: React.Ref<HTMLElement>;
    value?: string;
    processValue?: (value: string) => string;
    onBeforeChange?: (
        event: React.ChangeEvent<HTMLInputElement>,
        value: string | boolean,
    ) => boolean | void;
}

export const inputPropTypes = {
    innerRef: ref(),
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    className: PropTypes.string,
    processValue: PropTypes.func,
    onBeforeChange: PropTypes.func,
    onChange: PropTypes.func,
};

export const inputDefaultProps = {
    innerRef: noop,
    id: null,
    name: '',
    value: '',
    disabled: false,
    readOnly: false,
    className: undefined,
    processValue: undefined,
    onBeforeChange: noop,
    onChange: noop,
};

export const omitPropTypes = ['processValue', 'onBeforeChange'];
