import React from 'react';
import { isObject } from 'lodash';

export function getChildren(
    element?: React.ReactNode,
): (React.ReactChild | React.ReactFragment | React.ReactPortal)[] {
    if (!React.isValidElement(element) || !isObject(element?.props)) return [];

    // @ts-expect-error TS thinks children don't exist on element.props
    const { children } = element.props;
    if (children) return React.Children.toArray(children);

    return [];
}
