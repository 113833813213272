import React from 'react';

import { completeTierPropObject } from 'utilsTS/react';
import type { OptionalTiersObject } from 'utilsTS/react';

import { MatchMediaContext } from './MatchMediaContext';

export function useMatchMediaTierProp<T>(data: OptionalTiersObject<T> | T): T {
    const { matchingTier } = React.useContext(MatchMediaContext);
    return completeTierPropObject(data)[matchingTier] as T;
}
