import Hashids from 'hashids';
import { kebabCase } from 'lodash';
import info from 'gds-common/info.js';
import { TEST } from '../constants';

const ID_LENGTH = 4;
const ID_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

let counter = 0;
const hashids = new Hashids(info.name, ID_LENGTH, ID_ALPHABET);

export function generateId(prefix = ''): string {
    const prefixClean = prefix && kebabCase(prefix.replace(/^[^a-z]+/i, ''));

    if (TEST) return prefixClean || 'id';

    counter += 1;
    const id = hashids.encode(counter);
    const prefixString = prefixClean ? `${prefixClean}-` : '';

    return `${prefixString}${id}`;
}
