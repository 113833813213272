import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Heading, HeadingProps, Paragraph } from 'components/Typography';

import styles from './StripeTitle.scss';

export interface StripeTitleProps extends Omit<HeadingProps, 'level'> {
    children: React.ReactNode;

    /** Defines which heading element to render */
    level?: 1 | 2 | 3 | 4 | 5 | 6;
    /** Defines an optional text rendered above the children */
    overline?: React.ReactNode;
}

export const StripeTitle: React.FC<StripeTitleProps> = ({
    level = 1,
    overline,
    children,
    className,
    ...rest
}) => (
    <>
        {overline && <Paragraph className={styles.overline}>{overline}</Paragraph>}
        <Heading
            {...rest}
            level={level}
            className={cx(styles.title, overline && styles.withOverline, className)}
            tabIndex={-1}
        >
            {children}
        </Heading>
    </>
);

StripeTitle.displayName = 'StripeTitle';
StripeTitle.propTypes = {
    level: PropTypes.oneOf<StripeTitleProps['level']>([1, 2, 3, 4, 5, 6]),
    overline: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
