import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { omitProps } from 'utilsTS/react';

export enum PILL_VARIANT {
    DEFAULT = 'default',
    UNREAD = 'unread',
}

export interface PillProps extends React.ComponentPropsWithoutRef<'span'> {
    children?: never;

    /** Defines visual variant of the component */
    variant?: PILL_VARIANT;
    /** Defines visible text */
    value: number;
    /** Defines maximum to display after which the text is truncated, e.g. 99+ */
    max?: number;
}

export interface PillStatics {
    VARIANT: typeof PILL_VARIANT;
}

export const Pill: React.FC<PillProps> & PillStatics = ({
    variant = PILL_VARIANT.DEFAULT,
    value,
    max = 99,
    className,
    ...rest
}) => {
    const classNames = cx('g-pill', `g-pill-${variant}`, className);

    const renderValue = () => {
        if (value > max) return `${max}+`;
        return value;
    };

    return (
        <span {...omitProps(rest, Pill)} className={classNames}>
            {renderValue()}
        </span>
    );
};

Pill.VARIANT = PILL_VARIANT;

Pill.displayName = 'Pill';
Pill.propTypes = {
    variant: PropTypes.oneOf(Object.values(PILL_VARIANT)),
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
    max: PropTypes.number,
};
