import { noop } from 'lodash';

import { createContext } from 'utilsTS/react';

export interface SnackbarItemContextType {
    close: () => void;
}

export const SnackbarItemContext = createContext<SnackbarItemContextType>('SnackbarItem', {
    close: noop,
});
