// *******************************************************************
// * This file is automatically generated with each and every build, *
// * your edits will vanish and your efforts will be in vain... 🤷   *
// *******************************************************************

/* eslint-disable max-lines */

export enum ICON {
    ACCESSIBILITY = 'accessibility',
    ACCIDENT_INSURANCE = 'accident-insurance',
    ACCOUNT_PLUS = 'account-plus',
    ACCOUNTS = 'accounts',
    ADDRESSBOOK = 'addressbook',
    AIRPLANE = 'airplane',
    APP_CARDS = 'app-cards',
    APP_ICON = 'app-icon',
    APP_LIST = 'app-list',
    APP_STORE_ANDROID = 'app-store-android',
    APP_STORE_IOS = 'app-store-ios',
    ARROW_DOWN = 'arrow-down',
    ARROW_FAST_FORWARD = 'arrow-fast-forward',
    ARROW_LEFT = 'arrow-left',
    ARROW_OUT = 'arrow-out',
    ARROW_RIGHT = 'arrow-right',
    ARROW_UP = 'arrow-up',
    ARROW_UP_DOWN = 'arrow-up-down',
    ARROWS_COLLAPSE = 'arrows-collapse',
    ARROWS_EXPAND = 'arrows-expand',
    ATTACHMENT = 'attachment',
    AUDIO = 'audio',
    BANK = 'bank',
    BANK_GUARANTEE = 'bank-guarantee',
    BANKCARD = 'bankcard',
    BARS_ARROW_OUTLINE = 'bars-arrow-outline',
    BARS_CHECK_OUTLINE = 'bars-check-outline',
    BARS_LINE_CHART_OUTLINE = 'bars-line-chart-outline',
    BILL_PAYMENT = 'bill-payment',
    BLOCK = 'block',
    BRIEFCASE = 'briefcase',
    BROKEN_GLASS = 'broken-glass',
    BUDGET_STATUS_EXCEEDED = 'budget-status-exceeded',
    BUDGET_STATUS_OK = 'budget-status-ok',
    BUILDING_SAVINGS = 'building-savings',
    BUILDING_SAVINGS_CONTRACT = 'building-savings-contract',
    BUILDING_SOCIETY_LOAN = 'building-society-loan',
    BURINKA = 'burinka',
    BUSINESS = 'business',
    BUSINESS_ACCOUNT_MAXI = 'business-account-maxi',
    CALENDAR = 'calendar',
    CALENDAR_INCOMING = 'calendar-incoming',
    CALENDAR_OUTGOING = 'calendar-outgoing',
    CALENDAR_RELOAD = 'calendar-reload',
    CAMERA = 'camera',
    CAMERA_SWITCH = 'camera-switch',
    CAR_INSURANCE = 'car-insurance',
    CARD_ACTIVATE = 'card-activate',
    CARD_ADDITIONAL = 'card-additional',
    CARD_CREDITCARD = 'card-creditcard',
    CARD_HEALTHINSURANCE = 'card-healthinsurance',
    CARD_ID = 'card-id',
    CARD_NEW = 'card-new',
    CARD_PAYMENT_ACCEPTANCE = 'card-payment-acceptance',
    CARD_REMOVE = 'card-remove',
    CARD_REPLACEMENT = 'card-replacement',
    CARD_SETTINGS = 'card-settings',
    CARD_TOPUP = 'card-topup',
    CARDTAN = 'cardtan',
    CARET_DOWN = 'caret-down',
    CARET_LEFT = 'caret-left',
    CARET_RIGHT = 'caret-right',
    CARET_UP = 'caret-up',
    CART = 'cart',
    CASUALTY_INSURANCE = 'casualty-insurance',
    CAT = 'cat',
    CHARITY = 'charity',
    CHART_HORIZONTAL = 'chart-horizontal',
    CHART_ROUND = 'chart-round',
    CHART_VERTICAL = 'chart-vertical',
    CHECKMARK = 'checkmark',
    CHECKMARK_CIRCLE = 'checkmark-circle',
    CLOCK = 'clock',
    CLOSED = 'closed',
    COINS_SAVINGS = 'coins-savings',
    COMMENT = 'comment',
    COMMENT_IMPORTANT = 'comment-important',
    COMMENTS = 'comments',
    COMPUTER_1 = 'computer-1',
    COMPUTER_2 = 'computer-2',
    CONSEQ = 'conseq',
    CONTACTLESS = 'contactless',
    COOKIE = 'cookie',
    COPY = 'copy',
    CREDITCARD_ADDITIONAL = 'creditcard-additional',
    CURRENT_LOCATION = 'current-location',
    DEBITMOBILE_ANDROID = 'debitmobile-android',
    DEBITMOBILE_BAND = 'debitmobile-band',
    DEBITMOBILE_STICKER = 'debitmobile-sticker',
    DELETE = 'delete',
    DEVICES = 'devices',
    DIAMOND = 'diamond',
    DIGGER = 'digger',
    DINERSCARD = 'dinerscard',
    DIRECT_DEBIT = 'direct-debit',
    DIRECT_DEBIT_INCOMING = 'direct-debit-incoming',
    DIRECT_DEBIT_OUTGOING = 'direct-debit-outgoing',
    DIRECT_DEBIT_REFUSED = 'direct-debit-refused',
    DIRECT_DEBIT_SIPO = 'direct-debit-sipo',
    DISCOVER = 'discover',
    DOG = 'dog',
    DOT_LARGE = 'dot-large',
    DRAG_DROP = 'drag-drop',
    DRAG_ITEM = 'drag-item',
    E_MAIL = 'e-mail',
    EDIT = 'edit',
    ELECTRICITY = 'electricity',
    EMOJI_ANGRY = 'emoji-angry',
    EMOJI_HAPPY = 'emoji-happy',
    EMOJI_JOY = 'emoji-joy',
    EMOJI_OKAY = 'emoji-okay',
    EMOJI_SAD = 'emoji-sad',
    EMPTY_STATE_GENERIC = 'empty-state-generic',
    EMPTYSTATE = 'emptystate',
    ERROR = 'error',
    ERROR_CIRCLE = 'error-circle',
    ERSTE = 'erste',
    EURO = 'euro',
    EURO_CONVERT = 'euro-convert',
    FACE_ID = 'face-id',
    FACTORY = 'factory',
    FEEDBACK = 'feedback',
    FILE = 'file',
    FILE_DOWNLOAD = 'file-download',
    FILE_LOCKED = 'file-locked',
    FILE_UPLOAD = 'file-upload',
    FILTER = 'filter',
    FINGERPRINT = 'fingerprint',
    FIRE = 'fire',
    FLAG = 'flag',
    FLAG_PLACEHOLDER = 'flag-placeholder',
    FLASH = 'flash',
    FOLDER = 'folder',
    FOLDER_CONTRACT = 'folder-contract',
    FOLDER_DELETE = 'folder-delete',
    FOLDER_IMPORT = 'folder-import',
    FOLDER_INVOICE = 'folder-invoice',
    FOLDER_NEW = 'folder-new',
    FOLDER_PERSONAL = 'folder-personal',
    FOLDER_PHOTOS = 'folder-photos',
    FOREIGN_CURRENCY_ACCOUNT = 'foreign-currency-account',
    FOREIGN_CURRENCY_ACCOUNT_BUSINESS = 'foreign-currency-account-business',
    FREEZE = 'freeze',
    FULLSCREEN = 'fullscreen',
    GEO_CONTROL = 'geo-control',
    GEORGE = 'george',
    GEORGE_LOGO = 'george-logo',
    GEU_SERVICE_B24 = 'geu-service-b24',
    GEU_SERVICE_BLUE = 'geu-service-blue',
    GEU_SERVICE_MZF = 'geu-service-mzf',
    GEU_SERVICE_PZD = 'geu-service-pzd',
    GEU_SERVICE_S24 = 'geu-service-s24',
    GOLD_BARS = 'gold-bars',
    GRIDCARD = 'gridcard',
    GROUP = 'group',
    HAND_COIN = 'hand-coin',
    HAND_COINS = 'hand-coins',
    HAND_CURSOR = 'hand-cursor',
    HAND_MONEY = 'hand-money',
    HAND_WALLET = 'hand-wallet',
    HANDS_OUTLINE = 'hands-outline',
    HASHTAG = 'hashtag',
    HEALTH_INSURANCE = 'health-insurance',
    HELP = 'help',
    HELPDESK = 'helpdesk',
    HILFREICH = 'hilfreich',
    HOME = 'home',
    HOME_SECONDARY = 'home-secondary',
    HOSPITAL = 'hospital',
    HOSPITAL_DOCTOR = 'hospital-doctor',
    HOUSEHOLD_INSURANCE = 'household-insurance',
    INCOME_PROTECTION_INSURANCE = 'income-protection-insurance',
    INFO = 'info',
    INSURANCE = 'insurance',
    INSURANCE_PLUS = 'insurance-plus',
    INVOICE_DIGITAL = 'invoice-digital',
    INVOICE_POSTAL = 'invoice-postal',
    KEY = 'key',
    KEYBOARD = 'keyboard',
    KIDS_OUTLINE = 'kids-outline',
    LEAF = 'leaf',
    LEAKAGE = 'leakage',
    LIABILITY = 'liability',
    LIABILITY_INSURANCE = 'liability-insurance',
    LIFE_HEALTH_INSURANCE = 'life-health-insurance',
    LIFE_INSURANCE = 'life-insurance',
    LIGHTBULB = 'lightbulb',
    LINE_DOTTED = 'line-dotted',
    LINE_FILLED = 'line-filled',
    LINK = 'link',
    LIST = 'list',
    LOAN_RECONSTRUCTION = 'loan-reconstruction',
    LOCATION = 'location',
    LOCK_CLOSED = 'lock-closed',
    LOCK_OPEN = 'lock-open',
    LOGOUT = 'logout',
    LUCK = 'luck',
    MAGIC = 'magic',
    MAIL = 'mail',
    MARKETING_INFO = 'marketing-info',
    MASTERCARD = 'mastercard',
    MEDIA_PAUSE = 'media-pause',
    MEDIA_PLAY = 'media-play',
    MEDIA_STOP = 'media-stop',
    MENU = 'menu',
    MICRO_INSURANCE = 'micro-insurance',
    MINUS = 'minus',
    MOBILE_NFC_PAYMENT = 'mobile-nfc-payment',
    MOBILE_PAYMENT = 'mobile-payment',
    MOBILE_TOP_UP = 'mobile-top-up',
    MONEY = 'money',
    MORE_HORIZONTAL = 'more-horizontal',
    MORE_VERTICAL = 'more-vertical',
    MULTIBANKING_SYNC = 'multibanking-sync',
    NATURAL_DISASTER = 'natural-disaster',
    NEW = 'new',
    NEWSLETTER = 'newsletter',
    NOTIFICATION = 'notification',
    OFFER = 'offer',
    ORDERS = 'orders',
    PALETTE = 'palette',
    PARACHUTE = 'parachute',
    PATTERN = 'pattern',
    PAYMENT_FULL = 'payment-full',
    PAYMENT_PARTIAL = 'payment-partial',
    PAYMENT_PROTECTION_INSURANCE = 'payment-protection-insurance',
    PAYMENT_SLIP = 'payment-slip',
    PENNIES_ON_CLICK = 'pennies-on-click',
    PENSION = 'pension',
    PENSION_INSURANCE = 'pension-insurance',
    PENSION_SAVINGS = 'pension-savings',
    PENSION_TEMPLATE = 'pension-template',
    PET_INSURANCE = 'pet-insurance',
    PFM_ACCESSORIES = 'pfm-accessories',
    PFM_ACCOMODATION = 'pfm-accomodation',
    PFM_ADDITIONAL_INCOMING = 'pfm-additional-incoming',
    PFM_ADDITIONAL_OUTGOING = 'pfm-additional-outgoing',
    PFM_ALIMONY = 'pfm-alimony',
    PFM_ALIMONY_LEGAL = 'pfm-alimony-legal',
    PFM_AMAZON = 'pfm-amazon',
    PFM_AMUSEMENT = 'pfm-amusement',
    PFM_ATM = 'pfm-atm',
    PFM_BETTING = 'pfm-betting',
    PFM_BOOKS = 'pfm-books',
    PFM_CAFE = 'pfm-cafe',
    PFM_CAPITALISATION = 'pfm-capitalisation',
    PFM_CAR = 'pfm-car',
    PFM_CASH = 'pfm-cash',
    PFM_CHILDREN = 'pfm-children',
    PFM_CLEANING = 'pfm-cleaning',
    PFM_CLOTHING = 'pfm-clothing',
    PFM_COSMETICS = 'pfm-cosmetics',
    PFM_CRYPTO = 'pfm-crypto',
    PFM_DEBT_COLLECTION = 'pfm-debt-collection',
    PFM_DOCTOR = 'pfm-doctor',
    PFM_DRUGSTORE = 'pfm-drugstore',
    PFM_EDUCATION = 'pfm-education',
    PFM_ELECTRONICS = 'pfm-electronics',
    PFM_FASHION = 'pfm-fashion',
    PFM_FEES = 'pfm-fees',
    PFM_FOOD = 'pfm-food',
    PFM_FUEL = 'pfm-fuel',
    PFM_GAMES = 'pfm-games',
    PFM_GOOGLE = 'pfm-google',
    PFM_HEALTH = 'pfm-health',
    PFM_HOBBY = 'pfm-hobby',
    PFM_HOLIDAY = 'pfm-holiday',
    PFM_INTEREST = 'pfm-interest',
    PFM_LEISURE = 'pfm-leisure',
    PFM_LIVING = 'pfm-living',
    PFM_MEDIA = 'pfm-media',
    PFM_ONLINE = 'pfm-online',
    PFM_OTHER_INCOMING = 'pfm-other-incoming',
    PFM_OTHER_OUTGOING = 'pfm-other-outgoing',
    PFM_OTHER_TRANSPORT = 'pfm-other-transport',
    PFM_PARKING = 'pfm-parking',
    PFM_PET = 'pfm-pet',
    PFM_PHARMACY = 'pfm-pharmacy',
    PFM_POCKETMONEY = 'pfm-pocketmoney',
    PFM_PUBLIC_TRANSPORT = 'pfm-public-transport',
    PFM_SCHOOL = 'pfm-school',
    PFM_SERVICE = 'pfm-service',
    PFM_SEWER = 'pfm-sewer',
    PFM_SHOES = 'pfm-shoes',
    PFM_SPORT = 'pfm-sport',
    PFM_TABACCO = 'pfm-tabacco',
    PFM_TAXES = 'pfm-taxes',
    PFM_TAXI = 'pfm-taxi',
    PFM_TOLLS = 'pfm-tolls',
    PFM_TRAVEL_EXPENSES = 'pfm-travel-expenses',
    PFM_UNCATEGORISED_INCOMING = 'pfm-uncategorised-incoming',
    PFM_UNCATEGORISED_OUTGOING = 'pfm-uncategorised-outgoing',
    PFM_WASTE = 'pfm-waste',
    PFM_WITHDRAWAL = 'pfm-withdrawal',
    PHONE_HOTLINE = 'phone-hotline',
    PHONE_INSURANCE = 'phone-insurance',
    PHONE_MOBILE = 'phone-mobile',
    PHONE_WARNING = 'phone-warning',
    PHOTO = 'photo',
    PIN = 'pin',
    PLACEHOLDER = 'placeholder',
    PLUGIN_BUDGETS = 'plugin-budgets',
    PLUGIN_FILETRANSFER = 'plugin-filetransfer',
    PLUGIN_GIFTCARD = 'plugin-giftcard',
    PLUGIN_MONEYBACK = 'plugin-moneyback',
    PLUGIN_MULTIBANKING = 'plugin-multibanking',
    PLUGIN_PRO = 'plugin-pro',
    PLUGIN_REMEMBER_TO_PAY = 'plugin-remember-to-pay',
    PLUGIN_SAFE = 'plugin-safe',
    PLUGIN_SNAPSHOT = 'plugin-snapshot',
    PLUGIN_WHATIF = 'plugin-whatif',
    PLUS = 'plus',
    PLUS_CIRCLE = 'plus-circle',
    PLUS_FILLED = 'plus-filled',
    PORTFOLIO = 'portfolio',
    PRINT = 'print',
    PRODUCT_SORTING = 'product-sorting',
    PRODUCTS = 'products',
    PROPERTY_INSURANCE = 'property-insurance',
    QR_CODE = 'qr-code',
    RAINBOW = 'rainbow',
    REDO = 'redo',
    RELOAD = 'reload',
    REPLY = 'reply',
    REWARD_BADGE = 'reward-badge',
    RISK_LIFE_INSURANCE = 'risk-life-insurance',
    ROBBERY = 'robbery',
    ROCKET = 'rocket',
    ROUNDING_HILFREICH = 'rounding-hilfreich',
    S_ID_APP = 's-id-app',
    SALARY_TEMPLATE = 'salary-template',
    SAVINGS = 'savings',
    SEARCH = 'search',
    SECURITY_DIAMOND = 'security-diamond',
    SECURITY_GOOD = 'security-good',
    SECURITY_STAR = 'security-star',
    SECURITY_USER = 'security-user',
    SECURITY_WARNING = 'security-warning',
    SELECTION = 'selection',
    SET_ASIDE = 'set-aside',
    SETTINGS = 'settings',
    SG_ELECTRONICS = 'sg-electronics',
    SG_FURNITURE = 'sg-furniture',
    SG_GIFTS_AND_PARTIES = 'sg-gifts-and-parties',
    SG_SPORTS_EQUIPMENT = 'sg-sports-equipment',
    SG_WHITE_GOODS = 'sg-white-goods',
    SHARE = 'share',
    SHARE_ANDROID = 'share-android',
    SHARE_IOS = 'share-ios',
    SHOPPING = 'shopping',
    SHOW_OFF = 'show-off',
    SHOW_ON = 'show-on',
    SIGN = 'sign',
    SMS_CODE = 'sms-code',
    SOCIAL_INSURANCE = 'social-insurance',
    SPARKASSE = 'sparkasse',
    STANDING_ORDER = 'standing-order',
    STAR = 'star',
    STAR_FILLED = 'star-filled',
    STICKER_INFO = 'sticker-info',
    SUM = 'sum',
    SWEEP = 'sweep',
    SWEEP_ORDER = 'sweep-order',
    TAG = 'tag',
    TEMPLATE = 'template',
    TEMPLATE_PENSION = 'template-pension',
    TEMPLATE_SALARY = 'template-salary',
    TEXT = 'text',
    THUMB_DOWN = 'thumb-down',
    THUMB_UP = 'thumb-up',
    TOILET = 'toilet',
    TRAVEL_INSURANCE = 'travel-insurance',
    TRIANGLE_DOUBLE = 'triangle-double',
    TRUCK = 'truck',
    TUTORIAL = 'tutorial',
    UNREAD = 'unread',
    USER = 'user',
    USER_CHECK = 'user-check',
    USER_EDIT = 'user-edit',
    VAN = 'van',
    VISACARD = 'visacard',
    WALLET = 'wallet',
    WALLET_INSURANCE = 'wallet-insurance',
    WARNING = 'warning',
    WATCH_NFC_PAYMENT = 'watch-nfc-payment',
    WATER = 'water',
    WEB_CARDS = 'web-cards',
    WEB_CARDS_NEW = 'web-cards-new',
    WEB_CARDS_NEW_ACTIVE = 'web-cards-new-active',
    WEB_LIST = 'web-list',
    WEB_LIST_NEW = 'web-list-new',
    WEB_LIST_NEW_ACTIVE = 'web-list-new-active',
    WORLD = 'world',
    ZOOM_IN = 'zoom-in',
    ZOOM_OUT = 'zoom-out',
}
