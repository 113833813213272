import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { omitProps } from 'utilsTS/react';
import { findByIndexOrProperty } from 'utilsTS/array';
import { ICON } from 'utilsTS/icon';

import {
    SecondaryNavigationHeadingItem,
    SecondaryNavigationHeadingItemProps,
    SecondaryNavigationItem,
    SecondaryNavigationItemProps,
} from './components';

export interface SecondaryNavigationProps extends React.ComponentPropsWithoutRef<'nav'> {
    /** Defines selected item */
    selectedItem: string | number | SecondaryNavigationItemProps;
    /** Defines array of items */
    items: (SecondaryNavigationItemProps | SecondaryNavigationHeadingItemProps)[];

    /** Defines whether the component should be sticky at the top of the viewport */
    sticky?: boolean;
    /** Defines the offset from the top of the viewport */
    stickyOffset?: string | number;
    /** Defines whether icons should be rendered */
    showIcons?: boolean;
    /** Accepts additional className for the whole list */
    listClassName?: string;
    /** Accepts additional className for each item */
    itemClassName?: string;
    /** Accepts additional className for each link */
    linkClassName?: string;
    /** Is call on every citem click */
    onItemClick?: React.MouseEventHandler<HTMLLIElement>;
}

export interface SecondaryNavigationStatics {
    ICON: typeof ICON;
}

export const SecondaryNavigation: React.FC<SecondaryNavigationProps> &
    SecondaryNavigationStatics = ({
    id,
    selectedItem,
    items,
    sticky,
    stickyOffset = 0,
    showIcons = false,
    listClassName,
    itemClassName,
    linkClassName,
    className,
    style,
    onItemClick = noop,
    ...rest
}) => {
    const isItemSelected = (
        item: SecondaryNavigationItemProps | SecondaryNavigationHeadingItemProps,
    ) => {
        const selected = findByIndexOrProperty<
            SecondaryNavigationItemProps | SecondaryNavigationHeadingItemProps
        >([...items, item], selectedItem, 'href');
        return item === selected;
    };

    const renderItem = (
        item: SecondaryNavigationItemProps | SecondaryNavigationHeadingItemProps,
        index: number,
    ) => {
        const handleClick: React.MouseEventHandler<HTMLLIElement> = (event) => {
            if (item.onClick) item.onClick(event);
            onItemClick(event);
        };

        if (item.items) {
            const { items: subItems, label, ...itemRest } = item;

            return (
                <SecondaryNavigationHeadingItem
                    key={item.id || `nav-item-${index}`}
                    label={label}
                    showIcon={showIcons}
                    className={cx(itemClassName)}
                    {...itemRest}
                >
                    {subItems.map(renderItem)}
                </SecondaryNavigationHeadingItem>
            );
        }

        return (
            <SecondaryNavigationItem
                key={item.id || `nav-item-${index}`}
                {...item}
                selected={isItemSelected(item)}
                showIcon={showIcons}
                className={cx(itemClassName, item.className)}
                linkClassName={cx(linkClassName, item.linkClassName)}
                onClick={handleClick}
            />
        );
    };

    return (
        <nav
            {...omitProps(rest, SecondaryNavigation)}
            id={id}
            className={cx('g-sidenav', sticky && 'g-sidenav-sticky', className)}
            style={{ ...style, top: stickyOffset }}
        >
            <ul role="list" className={listClassName}>
                {items.map(renderItem)}
            </ul>
        </nav>
    );
};

SecondaryNavigation.ICON = ICON;

SecondaryNavigation.displayName = 'SecondaryNavigation';
SecondaryNavigation.propTypes = {
    id: PropTypes.string,
    selectedItem: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({ ...SecondaryNavigationItem.propTypes }),
    ]).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({ ...SecondaryNavigationItem.propTypes }).isRequired)
        .isRequired,
    sticky: PropTypes.bool,
    stickyOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showIcons: PropTypes.bool,
    listClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    linkClassName: PropTypes.string,
    style: PropTypes.shape({}),
    className: PropTypes.string,
    onItemClick: PropTypes.func,
};
