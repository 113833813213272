import { isValidElement } from 'react';

const FALLBACK = 'Component';

export default function getDisplayName(elementOrComponent = {}, { clean = true } = {}) {
    const element = elementOrComponent;

    const isElement = isValidElement(element);
    const type = isElement ? element.type : element;

    if (typeof type === 'string') {
        return type;
    }

    const { displayName, name, wrapsComponent } = type || {};

    if (wrapsComponent) {
        return getDisplayName(wrapsComponent);
    }

    if (displayName) {
        if (!clean) return displayName;
        return displayName.replace(/^(.*?\()?(.*?)(\))?$/, '$2');
    }

    return name || FALLBACK;
}
