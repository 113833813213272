import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Transition, TransitionProps } from './Transition';

import styles from './TransitionCollapse.scss';
import horizontalTransition from './TransitionCollapseHorizontal.scss';
import verticalTransition from './TransitionCollapseVertical.scss';

export interface TransitionCollapseProps extends Omit<TransitionProps, 'transition' | 'timeout'> {
    /** Defines whether the animation should play quickly */
    quick?: boolean;
    /** Defines whether the animation should start at the bottom */
    bottom?: boolean;
    /** Defines whether the animation should play horizontally rather than vertically */
    horizontal?: boolean;
}

export const TransitionCollapse: React.FC<TransitionCollapseProps> = ({
    quick,
    bottom,
    horizontal,
    className,
    ...rest
}) => {
    const transitionStyles = horizontal ? horizontalTransition : verticalTransition;

    return (
        <Transition
            transition={transitionStyles}
            timeout={quick ? styles.timeoutQuick : styles.timeout}
            className={cx(
                styles.container,
                className,
                quick && styles.quick,
                bottom && transitionStyles.fromEnd,
            )}
            {...rest}
        />
    );
};

TransitionCollapse.displayName = 'TransitionCollapse';
TransitionCollapse.propTypes = {
    show: PropTypes.bool,
    appear: PropTypes.bool,
    quick: PropTypes.bool,
    bottom: PropTypes.bool,
    className: PropTypes.string,
};
