import React from 'react';

export function useInputValue<T>(defaultValue: T, controlledValue: T) {
    const [value, setValue] = React.useState(controlledValue ?? defaultValue);

    React.useEffect(() => {
        if (controlledValue !== undefined && controlledValue !== value) {
            setValue(controlledValue);
        }
    }, [controlledValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return [value, setValue] as const;
}

export function useInputBooleanValue<E extends HTMLInputElement>(
    defaultValue: boolean | undefined,
    controlledValue: boolean | undefined,
    options: {
        readOnly?: boolean;
        onBeforeChange?: (event: React.ChangeEvent<E>, checked: boolean) => boolean | void;
        onChange?: (event: React.ChangeEvent<E>, checked: boolean) => void;
    },
) {
    const [checked, setChecked] = useInputValue(defaultValue, controlledValue);
    const { readOnly, onBeforeChange, onChange } = options;

    const handleChange: React.ChangeEventHandler<E> = (event) => {
        if (readOnly || onBeforeChange?.(event, event.target.checked) === false) return;
        if (event.target.checked !== checked) onChange?.(event, event.target.checked);
        setChecked(event.target.checked);
    };

    return [checked, handleChange] as const;
}

export function useInputStringValue<E extends HTMLInputElement | HTMLTextAreaElement>(
    defaultValue: React.ComponentPropsWithoutRef<'input'>['value'],
    controlledValue: React.ComponentPropsWithoutRef<'input'>['value'],
    options: {
        readOnly?: boolean;
        processValue?: (value: string) => string;
        onBeforeChange?: (event: React.ChangeEvent<E>, value: string) => boolean | void;
        onChange?: React.ChangeEventHandler<E>;
    },
) {
    const [value, setValue] = useInputValue(defaultValue, controlledValue);
    const { readOnly, processValue, onBeforeChange, onChange } = options;

    const handleChange: React.ChangeEventHandler<E> = (event) => {
        const target = event.target as E;
        if (readOnly || onBeforeChange?.(event, target.value) === false) return;

        const processedValue = processValue ? processValue(event.target.value) : event.target.value;
        if (processedValue !== event.target.value) event.target.value = processedValue; // eslint-disable-line no-param-reassign
        if (processedValue !== value) onChange?.(event);
        setValue(event.target.value);
    };

    return [value, handleChange] as const;
}
