import React from 'react';
import PropTypes from 'prop-types';

import { Context } from 'internal/Provider';

import { matchMedia } from 'utilsTS/dom';
import { useBrowser, useThrottle } from 'utilsTS/hooks';

import { TIER, DEFAULT_TIER } from './constants';
import { MatchMediaContext } from './MatchMediaContext';

const RESIZE_THROTTLE = 25;

export interface MatchMediaProviderProps {
    /** Defines whether to use XXL tier */
    useXxlTier?: boolean;
    /** Defines whether to use extended XL and XXL tiers */
    useExtendedTiers?: boolean;
    /** Defines the tier to fall back to */
    defaultTier?: TIER;
    /** Defines content of provider */
    children?: React.ReactNode;
}

const REVERSE_TIERS = Object.values(TIER).reverse();

export const MatchMediaProvider: React.FC<MatchMediaProviderProps> = ({
    useXxlTier = false,
    useExtendedTiers = false,
    defaultTier = DEFAULT_TIER,
    children,
}) => {
    const { window } = useBrowser(React.useContext(Context));
    const [matchingTier, setMatchingTier] = React.useState<TIER | undefined>();
    const tierRef = React.useRef<TIER | undefined>();

    const updateMatchingTier = React.useCallback(() => {
        const queryTiers = REVERSE_TIERS.filter((tier) => tier !== TIER.XXL || useXxlTier);
        const currentTier = queryTiers.find((queryTier) =>
            matchMedia(queryTier, queryTier === defaultTier, window, useExtendedTiers),
        );
        if (tierRef.current !== currentTier) {
            tierRef.current = currentTier;
            setMatchingTier(currentTier);
        }
    }, [defaultTier, window, useExtendedTiers, useXxlTier]);

    const handleResize = useThrottle(updateMatchingTier, RESIZE_THROTTLE);

    React.useLayoutEffect(() => {
        updateMatchingTier();
    }, [updateMatchingTier]);

    React.useEffect(() => {
        window?.addEventListener('resize', handleResize);
        return () => {
            window?.removeEventListener('resize', handleResize);
        };
    }, [window, handleResize]);

    const context = React.useMemo(
        () => ({
            matchingTier: matchingTier || defaultTier,
            useExtendedTiers,
        }),
        [matchingTier, defaultTier, useExtendedTiers],
    );

    return <MatchMediaContext.Provider value={context}>{children}</MatchMediaContext.Provider>;
};

MatchMediaProvider.displayName = 'MatchMediaProvider';
MatchMediaProvider.propTypes = {
    useXxlTier: PropTypes.bool,
    useExtendedTiers: PropTypes.bool,
    defaultTier: PropTypes.oneOf(Object.values(TIER)),
    children: PropTypes.node,
};
