import React from 'react';
import PropTypes from 'prop-types';

import { Context } from 'internal/Provider';
import { useBrowser } from 'utilsTS/hooks';

export interface CopyDetectorProps {
    /** Is called before and after copy event */
    onTrigger: (argument: { isCopying: boolean; documentNode: Document }) => void;
}

export const CopyDetector: React.FC<CopyDetectorProps> = ({ onTrigger }) => {
    const { document } = useBrowser(React.useContext(Context));

    React.useEffect(() => {
        if (!document) {
            return () => {};
        }

        const handleCopy = () => {
            requestIdleCallback(() => {
                onTrigger({ isCopying: false, documentNode: document });
            });

            onTrigger({ isCopying: true, documentNode: document });
        };

        const eventListenerOptions = { capture: true, passive: true };

        document.addEventListener('copy', handleCopy, eventListenerOptions);
        document.addEventListener('cut', handleCopy, eventListenerOptions);

        return () => {
            document.removeEventListener('copy', handleCopy, eventListenerOptions);
            document.removeEventListener('cut', handleCopy, eventListenerOptions);
        };
        // the listeners need to be rebound on prop changes because of variable scoping in their handlers
    }, [document, onTrigger]);

    return null;
};

CopyDetector.displayName = 'CopyDetector';
CopyDetector.propTypes = {
    onTrigger: PropTypes.func.isRequired,
};
