import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Group, GroupProps } from 'components/Layout';

import { cloneElement, getDisplayName } from 'utilsTS/react';

import { NewButton, BUTTON_SIZE as NEW_BUTTON_SIZE } from './NewButton';
import Button from './Button.jsx';

export interface ButtonGroupProps extends GroupProps {
    /** Defines size of rendered buttons */
    buttonSize?: NEW_BUTTON_SIZE;
    /** Defines whether rendered buttons should be disabled */
    disabled?: boolean;
    /** Defines whether buttons should be rendered as segmented */
    segmented?: boolean;
    /** Defines whether ButtonGroup should take up all available space horizontally */
    fullWidth?: boolean;
}

export interface ButtonGroupStatics {
    BUTTON_SIZE: typeof NEW_BUTTON_SIZE;
}

export const ButtonGroup: React.FC<ButtonGroupProps> & ButtonGroupStatics = ({
    buttonSize,
    disabled,
    segmented,
    fullWidth = true,
    className,
    children,
    ...rest
}) => (
    <Group
        className={cx(
            'g-group-button',
            segmented && 'g-group-button-segmented',
            !fullWidth && 'g-group-button-inline',
            className,
        )}
        role="group"
        {...rest}
    >
        {React.Children.map(children, (child) => {
            if (!getDisplayName(child).includes('Button')) return child;

            const { size } = (child as React.ReactElement).props;

            return cloneElement(child, {
                size: buttonSize || size,
                disabled,
                ...(segmented ? { variant: NewButton.VARIANT.SECONDARY } : {}),
            });
        })}
    </Group>
);

ButtonGroup.BUTTON_SIZE = NewButton.SIZE;

ButtonGroup.displayName = 'ButtonGroup';
ButtonGroup.propTypes = {
    buttonSize: PropTypes.oneOf([...Object.values(NewButton.SIZE), ...Button.SIZE.values()]),
    disabled: PropTypes.bool,
    segmented: PropTypes.bool,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
