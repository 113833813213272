/* eslint-disable @typescript-eslint/no-explicit-any */

import { pick } from 'lodash';
import { getPropKeys, keysOrComponent } from './getPropKeys';

export function pickProps(
    props: Record<string, any>,
    ...keysOrComponents: keysOrComponent[]
): Record<string, any> {
    const keys = getPropKeys(...keysOrComponents);
    if (keys.length === 0) return {};
    return pick(props, keys);
}
