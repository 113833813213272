import { findReactRoots } from './findReactRoots';
import { findComponentsInDom } from './findComponentsInDom';
import { displayGlobalInfo } from './displayGlobalInfo';
import * as styles from './styles';

const highlighted: HTMLElement[] = [];

function highlightComponent({
    displayName,
    element,
}: {
    displayName: string;
    element: HTMLElement;
}) {
    Object.assign(element.style, styles.outline);

    const div = document.createElement('div');
    if (highlighted.includes(element)) return;

    element.addEventListener('mouseenter', () => {
        const rect = element.getBoundingClientRect();
        div.innerHTML = displayName;

        Object.assign(div.style, styles.component, {
            top: `${rect.top + window.pageYOffset - 19 - styles.BORDER}px`,
            left: `${rect.left + window.pageXOffset - styles.BORDER}px`,
        });

        document.body.appendChild(div);
    });

    element.addEventListener('mouseleave', () => {
        if (div.parentNode) {
            div.remove();
        }
    });

    highlighted.push(element);
}

export function highlightComponents(): void {
    displayGlobalInfo();

    const roots = findReactRoots();

    roots.forEach((root) => {
        const node = root.children.item(0);
        // @ts-expect-error there's a type mismatch here but everything seems too custom
        findComponentsInDom(node).forEach(highlightComponent);
    });
}
