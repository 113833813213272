import cx from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mergeProp(key: string, props1: Record<string, any>, props2: Record<string, any>) {
    const prop1 = props1[key];
    const prop2 = props2[key];

    if (!prop1) return prop2;
    if (prop1 === prop2) return prop1;
    if (prop2 === undefined) return prop1;

    switch (key) {
        case 'aria-describedby':
        case 'aria-labelledby':
        case 'className': {
            return cx(prop1, prop2);
        }
        case 'style': {
            return { ...prop1, ...prop2 };
        }
        case 'readOnly':
        case 'disabled': {
            return prop1 || prop2 || undefined;
        }
        default: {
            return prop2;
        }
    }
}

export function mergeProps(...props: Record<string, unknown>[]): Record<string, unknown> {
    return props.reduce((previous, current) => {
        if (!current) return previous;

        const keys = Object.keys(current);
        const merged = keys.reduce(
            (previousMerged, key) => ({
                ...previousMerged,
                [key]: mergeProp(key, previous, current),
            }),
            {},
        );

        return {
            ...previous,
            ...merged,
        };
    }, {});
}
