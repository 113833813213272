import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { LanguageString } from 'containers/LanguageProvider';
import { TransitionFade } from 'components/Transition';
import { VisuallyHidden } from 'components/Utilities/VisuallyHidden';

import { COLOR } from 'utilsTS/color';

import { SpinnerSvg, SPINNER_SIZE } from './SpinnerSvg';
import styles from './Spinner.scss';

export interface SpinnerProps extends React.ComponentPropsWithoutRef<'span'> {
    children?: never;

    /** Defines size of the spinner */
    size?: SPINNER_SIZE;
    /** Defines colour of the spinner */
    color?: COLOR;
    /** Defines whether the spinner should be centered */
    center?: boolean;
    /** Defines wrapping component */
    component?: React.ElementType;
    /** Defines whether a11y message should be rendered */
    ornamental?: boolean;
    /** Defines a11y message */
    screenreaderText?: string;
}

export interface SpinnerStatics {
    SIZE: typeof SPINNER_SIZE;
    COLOR: typeof COLOR;
}

export const Spinner: React.FC<SpinnerProps> & SpinnerStatics = ({
    size = Spinner.SIZE.MEDIUM,
    color,
    center,
    component: Component = 'span',
    ornamental,
    screenreaderText,
    className,
    ...rest
}) => (
    <TransitionFade show appear>
        <Component className={cx(styles.container, center && styles.center, className)} {...rest}>
            {!ornamental && (
                <VisuallyHidden>
                    <LanguageString name="spinner.message" value={screenreaderText} />
                </VisuallyHidden>
            )}
            <SpinnerSvg size={size} color={color} />
        </Component>
    </TransitionFade>
);

Spinner.SIZE = SPINNER_SIZE;
Spinner.COLOR = COLOR;

Spinner.displayName = 'Spinner';
Spinner.propTypes = {
    size: PropTypes.oneOf(Object.values(Spinner.SIZE)),
    color: PropTypes.oneOf(Object.values(Spinner.COLOR)),
    center: PropTypes.bool,
    component: PropTypes.elementType as React.Validator<React.ElementType>,
    ornamental: PropTypes.bool,
    screenreaderText: PropTypes.string,
    className: PropTypes.string,
};
