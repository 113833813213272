import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CardBody } from 'components/Card';
import { Icon } from 'components/Icon';

import { cloneElement } from 'utilsTS/react';

import {
    FunctionCardImage,
    FunctionCardImageProps,
    FunctionCardImageStatics,
} from './FunctionCardImage';

export interface FunctionCardIndicatorProps
    extends Omit<React.ComponentPropsWithoutRef<'div'>, 'color'>,
        FunctionCardImageProps {
    /** Defines the text of the indicator */
    indicatorText?: React.ReactNode;
    /** Defines whether the indicator is shown as active */
    indicatorActive?: boolean;
    /** Accept custom content to be shown instead of regular indicator */
    children?: React.ReactNode;
}

export type FunctionCardIndicatorStatics = FunctionCardImageStatics;

export const FunctionCardIndicator: React.FC<FunctionCardIndicatorProps> &
    FunctionCardIndicatorStatics = ({
    icon,
    imageUrl,
    indicatorText,
    indicatorActive,
    className,
    iconClassName,
    children,
}) => {
    if (!children && !icon && !imageUrl && !indicatorText) return null;

    const classNames = cx(
        'g-card-function-indicator',
        children ? 'g-indicator-custom' : `g-indicator-${indicatorActive ? 'enabled' : 'disabled'}`,
        className,
    );

    const renderIndicatorStatus = () => {
        // by design, indicator status text won't be shown if custom indicator is specified
        if (React.isValidElement(indicatorText))
            return cloneElement(indicatorText, { className: 'g-state' });
        if (indicatorText) return <span className="g-state">{indicatorText}</span>;
        return null;
    };

    return (
        <CardBody className={classNames}>
            {children ? (
                <div className="g-card-function-indicator-content">{children}</div>
            ) : (
                <>
                    <FunctionCardImage
                        icon={icon}
                        imageUrl={imageUrl}
                        iconClassName={iconClassName}
                    />
                    {renderIndicatorStatus()}
                </>
            )}
        </CardBody>
    );
};

FunctionCardIndicator.ICON = Icon.ICON;

FunctionCardIndicator.displayName = 'FunctionCardIndicator';
FunctionCardIndicator.propTypes = {
    ...FunctionCardImage.propTypes,
    indicatorText: PropTypes.node,
    indicatorActive: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
