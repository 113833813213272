import React from 'react';

import { AvatarContext } from '../AvatarContext';
import { AvatarShrunkIcon } from './AvatarShrunkIcon';

export const AvatarHoverIcon: React.FC = () => {
    const { hoverIcon, hoverIconProps } = React.useContext(AvatarContext);

    if (!hoverIcon) return null;

    const REL_HOVER_ICON_SIZE = (50 - 2 * 10) / 100; // width: 50%; padding: 10%;

    return (
        <span className="g-avatar-hover" {...hoverIconProps}>
            <AvatarShrunkIcon shrinkIcon={hoverIcon} shrinkFactor={REL_HOVER_ICON_SIZE} />
        </span>
    );
};

AvatarHoverIcon.displayName = 'AvatarHoverIcon';
