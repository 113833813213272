module.exports = {
    legacy: [
        'bars-arrow-outline',
        'bars-check-outline',
        'bars-line-chart-outline',
        'conseq',
        'drag-drop',
        'erste',
        'geo-control',
    ],
    deprecations: [
        { icon: 'pfm-atm', alias: 'pfm-withdrawal' },
        { icon: 'pfm-children', alias: 'pfm-alimony' },
        { icon: 'emptystate', alias: 'empty-state-generic' },
        { icon: 'mobile-payment', alias: 'mobile-nfc-payment' },
        { icon: 'george', alias: 'george-logo' },
        { icon: 'pension-template', alias: 'template-pension' },
        { icon: 'salary-template', alias: 'template-salary' },
        { icon: 'sweep', alias: 'sweep-order' },
        { icon: 'sg-electronics', alias: 'pfm-electronics' },
        { icon: 'sg-furniture', alias: 'pension-savings' },
        { icon: 'sg-gifts-and-parties', alias: 'plugin-giftcard' },
        { icon: 'sg-sports-equipment', alias: 'pfm-sport' },
        { icon: 'sg-white-goods', alias: 'freeze' },
    ],
};
