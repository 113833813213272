import { Children, isValidElement } from 'react';
import { isObject } from 'lodash';

export default function getChildren(element) {
    if (!isValidElement(element) || !isObject(element?.props)) {
        return [];
    }

    const { children } = element.props;

    if (children) {
        return Children.toArray(children);
    }

    return [];
}
