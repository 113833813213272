import { isObject } from 'lodash';

export function findByIndexOrProperty<T>(
    items: T[],
    indexOrValue?: number | string | T,
    property?: keyof T,
): T | undefined {
    if (indexOrValue === undefined || indexOrValue === '') {
        return undefined;
    }

    if (typeof indexOrValue === 'number') {
        return items[indexOrValue];
    }

    if (property) {
        if (typeof indexOrValue === 'string') {
            return items.find((item) => item[property] === indexOrValue);
        }
        if (isObject(indexOrValue) && indexOrValue[property]) {
            return items.find((item) => item[property] === indexOrValue[property]);
        }
    } else if (isObject(indexOrValue)) {
        return items.find((item) => item === indexOrValue);
    }

    return undefined;
}
