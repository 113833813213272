import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/Layout';

export interface ParagraphProps extends React.ComponentPropsWithoutRef<'p'> {
    /** Defines whether text should be visually small */
    small?: boolean;
    /** Defines whether text should be visually micro */
    micro?: boolean;
    /** Defines whether text should be visually faded */
    faded?: boolean;
}

export const Paragraph: React.FC<ParagraphProps> = ({
    small,
    micro,
    faded,
    className,
    children,
    ...rest
}) => (
    <Text
        {...rest}
        always
        tag="p"
        className={cx(
            {
                'g-small': small,
                'g-micro': micro,
                'g-faded': faded,
            },
            className,
        )}
    >
        {children}
    </Text>
);

Paragraph.displayName = 'Paragraph';
Paragraph.propTypes = {
    small: PropTypes.bool,
    micro: PropTypes.bool,
    faded: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
