import { noop } from 'lodash';

import { ToggleableContextTypes } from 'utilsTS/hooks';
import { createContext } from 'utilsTS/react';

import { ALERT_VARIANT, ALERT_SIZE } from './AlertEnums';

export interface AlertContextTypes extends ToggleableContextTypes {
    variant?: ALERT_VARIANT;
    size?: ALERT_SIZE;
    closeable?: boolean;
}

export const AlertContext = createContext<AlertContextTypes>('Alert', {
    isOpen: true,
    open: noop,
    close: noop,
    toggle: noop,
    onToggle: noop,
    onBeforeOpen: noop,
    onOpen: noop,
    onBeforeClose: noop,
    onClose: noop,

    variant: ALERT_VARIANT.ERROR,
    size: undefined,
    closeable: false,
});
