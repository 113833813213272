import React from 'react';

import { UIButton } from 'components/Button/index.js';
import { NewButton as Button, ButtonProps } from 'components/Button/NewButton';

import { cloneElement, isElementOfComponent } from 'utilsTS/react';

import { StripeContext } from '../StripeContext';

export const StripeHeaderRight: React.FC = () => {
    const { right = null, isMinMediumTier } = React.useContext(StripeContext);

    // DEPRECATED
    /* istanbul ignore if */
    if (isElementOfComponent(right, UIButton)) {
        return cloneElement(right, { color: UIButton.COLOR.WHITE }) as React.ReactElement;
    }

    if (
        isElementOfComponent(right, 'StripeActionButton') ||
        isElementOfComponent(right, 'StripeActionSplitButton')
    ) {
        return right as React.ReactElement;
    }

    if (isElementOfComponent(right, Button)) {
        const { props } = right as React.ReactElement<ButtonProps>;

        /* eslint-disable react/prop-types */
        const hasIcon = !!props.icon;
        const isTertiary = props.variant === Button.VARIANT.TERTIARY;
        const isPrimary = !props.variant || props.variant === Button.VARIANT.PRIMARY;
        /* eslint-enable react/prop-types */

        if (!hasIcon && !isMinMediumTier) {
            return null;
        }

        return cloneElement(
            right,
            {
                variant: isPrimary ? Button.VARIANT.SECONDARY : undefined,
                size: isMinMediumTier ? Button.SIZE.MEDIUM : Button.SIZE.SMALL,
                hiddenLabel: Button.HIDDEN_LABEL.BELOW_MD,
                fullWidth: Button.FULL_WIDTH.NEVER,
                iconClassName: isTertiary ? 'g-button-icon-right' : null,
            },
            {
                variant: !isMinMediumTier && hasIcon ? Button.VARIANT.TERTIARY : undefined,
            },
        ) as React.ReactElement;
    }

    if (isMinMediumTier) return right as React.ReactElement;
    return null;
};

StripeHeaderRight.displayName = 'StripeHeaderRight';
