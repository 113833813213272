import colors from 'gds-common/colors.js';

export type Color = {
    name: string;
    title: string;
    value: string;
    deprecated?: boolean;
    hidden?: boolean;
};

export type ColorShadeKey = 25 | 50 | 75 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export type ColorShaded = {
    name: string;
    title: string;
    value: {
        25?: string;
        50?: string;
        75?: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
    };
    deprecated?: boolean;
};

export const COLORS_COMMON: Color[] = colors.common;
export const COLORS_BLUE: Color[] = colors.blue;
export const COLORS_GRAY: Color[] = colors.gray;
export const COLORS_UI: Color[] = colors.ui;
export const COLORS_NAMED: Color[] = colors.named;
export const COLORS_EXTERNAL: Color[] = colors.external;
export const COLORS_GROUP: Color[] = colors.group; // DEPRECATED
export const COLORS_SHADED: ColorShaded[] = colors.shaded; // DEPRECATED

export const COLORS_NEW = [
    ...COLORS_COMMON,
    ...COLORS_BLUE,
    ...COLORS_GRAY,
    ...COLORS_UI,
    ...COLORS_NAMED,
    ...COLORS_EXTERNAL,
];

export const COLORS = [
    ...COLORS_COMMON,
    ...COLORS_BLUE,
    ...COLORS_GRAY,
    ...COLORS_UI,
    ...COLORS_NAMED,
    ...COLORS_EXTERNAL,
    ...COLORS_GROUP, // DEPRECATED
    ...COLORS_SHADED, // DEPRECATED
];
