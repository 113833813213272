import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { LanguageString } from 'containers/LanguageProvider';

import { Card } from 'components/Card';
import { Icon } from 'components/Icon';
import { Flag } from 'components/Image';
import { NewButton as Button } from 'components/Button/NewButton';
import { TransitionCollapseHeight } from 'components/Transition';

import { warning } from 'utilsTS/error';
import { useToggleable, ToggleableProps, toggleablePropTypes } from 'utilsTS/hooks';
import { ICON } from 'utilsTS/icon';
import { omitProps } from 'utilsTS/react';

import styles from '../Alert.scss';
import { AlertContext } from './AlertContext';
import { ALERT_VARIANT, ALERT_SIZE } from './AlertEnums';

export interface AlertContainerProps
    extends React.ComponentPropsWithoutRef<'div'>,
        ToggleableProps {
    /** Defines whether the Alert should animate in */
    appear?: boolean;
    /**
     * Defines whether the Alert is a CTAAlert
     * @deprecated
     */
    cta?: boolean;
    /** Defines the Alert variant */
    variant?: ALERT_VARIANT;
    /** Defines the Alert size */
    size?: ALERT_SIZE;
    /** Defines the Alert icon */
    icon?: ICON;
    /** Defines the Alert flag image */
    flagUrl?: string;
    /** Defines the Alert flag alt text */
    flagAlt?: string;
    /** Defines whether the Alert is closeable */
    closeable?: boolean;
    /**
     * Defines the close label
     * @default language string "common.close"
     */
    closeLabel?: string;
    /** Defines the heading to show in the alert */
    heading?: React.ReactNode;
    /** Defines the body text of the alert */
    children?: React.ReactNode;
}

export interface AlertContainerStatics {
    VARIANT: typeof ALERT_VARIANT;
    SIZE: typeof ALERT_SIZE;
    ICON: typeof ICON;
}

const ALERT_ICON = {
    [ALERT_VARIANT.INFO]: ICON.INFO,
    [ALERT_VARIANT.ERROR]: ICON.ERROR_CIRCLE,
    [ALERT_VARIANT.WARNING]: ICON.WARNING,
};

export const AlertContainer: React.FC<AlertContainerProps> & AlertContainerStatics = ({
    variant = ALERT_VARIANT.ERROR,
    size = ALERT_SIZE.MEDIUM,
    icon,
    flagUrl,
    flagAlt,
    cta,
    appear,
    closeable = false,
    closeLabel,
    heading,
    children,
    className,

    isOpen = true,
    onToggle = noop,
    onBeforeOpen = noop,
    onOpen = noop,
    onBeforeClose = noop,
    onClose = noop,
    ...rest
}) => {
    warning(
        !!closeable && size === ALERT_SIZE.SMALL,
        'A small-sized Alert cannot be closeable. Please change the Alert size or remove the closeable prop',
    );

    warning(
        !!closeable && !heading,
        'A closeable Alert must have a heading. Please add a heading or remove the closeable prop',
    );

    const isCloseable = !!closeable && !!heading && size !== ALERT_SIZE.SMALL;

    const [isToggled, { open, close, toggle }] = useToggleable(true, isOpen, {
        onToggle,
        onBeforeOpen,
        onBeforeClose,
    });

    const renderFlagOrIcon = () => {
        if (flagUrl) return <Flag imageUrl={flagUrl} alt={flagAlt} />;

        return (
            <Icon
                icon={icon || ALERT_ICON[variant]}
                size={cta || size === ALERT_SIZE.LARGE ? Icon.SIZE.SIZE_48 : undefined} // TODO: remove cta after deprecation period
                className="g-card-alert-icon"
            />
        );
    };

    const renderContent = () => {
        const classNames = cx(
            `g-card-alert-${variant}`,
            size && `g-card-alert-${size}`,
            cta && 'g-card-alert-cta', // TODO: remove cta after deprecation period
            isCloseable && 'g-card-alert-closeable',
            flagUrl && 'g-card-alert-flag',
            className,
        );

        return (
            <Card {...omitProps(rest, AlertContainer)} className={classNames}>
                {renderFlagOrIcon()}
                {isCloseable && (
                    <div className="g-card-close">
                        <Button
                            variant={Button.VARIANT.TERTIARY}
                            size={Button.SIZE.SMALL}
                            icon={Button.ICON.ERROR}
                            fullWidth={Button.FULL_WIDTH.NEVER}
                            hiddenLabel={Button.HIDDEN_LABEL.BELOW_MD}
                            iconClassName="g-button-icon-right"
                            onClick={close}
                        >
                            <LanguageString name="common.close" value={closeLabel} />
                        </Button>
                    </div>
                )}
                {children}
            </Card>
        );
    };

    const renderChildren = () => {
        if (!isCloseable && !appear) return renderContent();

        return (
            <TransitionCollapseHeight
                appear={appear}
                show={isCloseable ? isToggled : true}
                onEntered={onOpen}
                onExited={onClose}
            >
                <div className={styles.container}>{renderContent()}</div>
            </TransitionCollapseHeight>
        );
    };

    const context = React.useMemo(
        () => ({
            isOpen: isToggled,
            open,
            close,
            toggle,
            onToggle,
            onBeforeOpen,
            onOpen,
            onBeforeClose,
            onClose,

            variant,
            size,
            closeable: isCloseable,
        }),
        [
            close,
            isToggled,
            isCloseable,
            onToggle,
            onBeforeClose,
            onBeforeOpen,
            onClose,
            onOpen,
            open,
            size,
            toggle,
            variant,
        ],
    );

    return <AlertContext.Provider value={context}>{renderChildren()}</AlertContext.Provider>;
};

AlertContainer.VARIANT = ALERT_VARIANT;
AlertContainer.SIZE = ALERT_SIZE;
AlertContainer.ICON = ICON;

AlertContainer.displayName = 'AlertContainer';
AlertContainer.propTypes = {
    ...toggleablePropTypes,
    appear: PropTypes.bool,
    cta: PropTypes.bool, // TODO: remove cta after deprecation period
    variant: PropTypes.oneOf(Object.values(ALERT_VARIANT)),
    size: PropTypes.oneOf(Object.values(ALERT_SIZE)),
    icon: PropTypes.oneOf(Object.values(Icon.ICON)),
    flagUrl: PropTypes.string,
    flagAlt: PropTypes.string,
    closeable: PropTypes.bool,
    closeLabel: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};
