import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { LanguageString } from 'containers/LanguageProvider';

import { NewButton as Button, ButtonProps, ButtonStatics } from 'components/Button/NewButton';

import { omitProps } from 'utilsTS/react';
import { ICON } from 'utilsTS/icon';
import { ToggleableContext } from 'utilsTS/hooks/useToggleable';

import styles from './Collapsible.scss';

export interface CollapsibleButtonProps extends Omit<ButtonProps, 'children'> {
    children?: React.ReactNode;

    /** Defines whether the label should be shown */
    showLabel?: boolean;
    /** Defines whether the click area should extend to parent component */
    coverParent?: boolean;
    /** Defines whether the button is being displayed in the center */
    centered?: boolean;
    /** Defines whether the button is vertically centered */
    verticallyCentered?: boolean;
    /**
     * Defines label of the button in open state
     * @default language string "common.collapse"
     */
    collapseLabel?: string;
    /**
     * Defines label of the button in closed state
     * @default language string "common.expand"
     */
    expandLabel?: string;
}

export type CollapsibleButtonStatics = Pick<ButtonStatics, 'VARIANT' | 'ICON'>;

export const CollapsibleButton: React.FC<CollapsibleButtonProps> & CollapsibleButtonStatics = ({
    icon: propIcon,
    variant = Button.VARIANT.TERTIARY,
    showLabel = true,
    coverParent = true,
    centered,
    verticallyCentered,
    collapseLabel,
    expandLabel,
    className,
    children,
    onClick = noop,
    ...rest
}) => {
    const { isOpen, toggle, id: collapsibleId } = React.useContext(ToggleableContext);

    const handleClick: React.MouseEventHandler = (event) => {
        toggle();
        onClick(event);
    };

    const renderLabel = () => {
        if (children) return children;

        return isOpen ? (
            <LanguageString name="common.collapse" value={collapseLabel} />
        ) : (
            <LanguageString name="common.expand" value={expandLabel} />
        );
    };

    const useCustomIcon = propIcon !== undefined;
    const icon = useCustomIcon ? propIcon : ICON.CARET_DOWN;
    const label = renderLabel();
    const classNames = cx(
        styles.button,
        coverParent && styles.buttonCoverParent,
        verticallyCentered && styles.buttonVertical,
        className,
    );
    const hiddenLabel = centered ? Button.HIDDEN_LABEL.NEVER : Button.HIDDEN_LABEL.BELOW_MD;

    return (
        <Button
            {...omitProps(rest, CollapsibleButton)}
            size={Button.SIZE.SMALL}
            hiddenLabel={hiddenLabel}
            fullWidth={Button.FULL_WIDTH.NEVER}
            variant={variant}
            icon={icon}
            iconClassName="g-button-icon-right"
            className={classNames}
            labelClassName={showLabel ? undefined : 'sr-only'}
            aria-controls={`${collapsibleId}-content`}
            aria-expanded={isOpen}
            onClick={handleClick}
        >
            {label}
        </Button>
    );
};

CollapsibleButton.VARIANT = Button.VARIANT;
CollapsibleButton.ICON = Button.ICON;

CollapsibleButton.displayName = 'CollapsibleButton';
CollapsibleButton.propTypes = {
    showLabel: PropTypes.bool,
    coverParent: PropTypes.bool,
    icon: PropTypes.oneOf(Object.values(CollapsibleButton.ICON)),
    variant: PropTypes.oneOf(Object.values(Button.VARIANT)),
    centered: PropTypes.bool,
    verticallyCentered: PropTypes.bool,
    collapseLabel: PropTypes.string,
    expandLabel: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
};
