export function findReactRoots(): Element[] {
    const roots = document.body.querySelectorAll('*');

    return [...roots].filter((element) => {
        // Method for React < v18
        // @ts-expect-error property does not exist
        const { _reactRootContainer } = element;
        if (_reactRootContainer) return true;

        // Method for React >= v18
        return Object.keys(element).some((property) => property.startsWith('__reactContainer'));
    });
}
