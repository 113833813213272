import React from 'react';

import { checkDOM } from 'utilsTS/dom';

export function useBrowser(options?: { windowNode?: Window; documentNode?: Document }) {
    const { windowNode, documentNode } = options || {};
    const hasBrowser = checkDOM();

    const browser = React.useMemo(() => {
        if (!hasBrowser) {
            return {
                window: windowNode || undefined,
                document: documentNode || undefined,
            };
        }

        return {
            window: windowNode || window,
            document: documentNode || document,
        };
    }, [hasBrowser, windowNode, documentNode]);

    return browser;
}
