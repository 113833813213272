import { warning } from 'utils/error';
import { getDisplayName, isElementOfComponent, isValidComponent } from 'utils/react';
import createTypeChecker from './createTypeChecker';

export default function elementOfType(components) {
    if (Array.isArray(components)) {
        components.forEach((component, index) => {
            warning(
                !isValidComponent(component) && typeof component !== 'string',
                `Invalid argument supplied to elementOfType. Expected an array of components, but received ${typeof component} at index ${index}.`,
            );
        });
    } else {
        warning(true, 'Invalid argument supplied to elementOfType, expected an array.');
    }

    const checkElementOfType = (props, propName, componentName, location) => {
        // eslint-disable-next-line react/destructuring-assignment
        const element = props[propName];

        const elementOfComponents = components.some((nameOrComponent) =>
            isElementOfComponent(element, nameOrComponent),
        );

        if (!elementOfComponents) {
            const componentNames = components.map(getDisplayName);
            return new Error(
                `${propName} in ${componentName} needs to be an element of \`[${componentNames.join(
                    ', ',
                )}]\`, but is an element of \`${getDisplayName(element)}\``,
            );
        }
        // assume all ok
        return null;
    };

    return createTypeChecker(checkElementOfType);
}
