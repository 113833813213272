import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { ThemeDetector } from 'containers/ThemeProvider';
import { Portal, FocusTrap } from 'components/Utilities';

import { isIE11 } from 'utilsTS/dom';
import { omitProps } from 'utilsTS/react';
import {
    useToggleable,
    toggleablePropTypes,
    ToggleableProps,
    ToggleableContext,
    useUpdateEffect,
} from 'utilsTS/hooks';

import { ModalDialog } from './ModalDialog';
import { ModalBackdrop } from './ModalBackdrop';
import { ModalTransitionDialog } from './ModalTransitionDialog';
import {
    toggleBodyClass,
    setBodyPadding,
    setClassNamePadding,
    getScrollbarWidth,
    isBodyOverflowing,
} from './utils';

const OPEN_DELAY = 100;

export interface ModalContainerProps
    extends ToggleableProps,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    children: React.ReactNode;

    title?: React.ReactNode;
    animate?: boolean;
    appear?: boolean;
    small?: boolean;
    teaser?: boolean;
    drawer?: boolean;
    embedded?: boolean;
    confirmation?: boolean;
    closeable?: boolean;
    closeOnEsc?: boolean;
    closeOnClick?: boolean;
    imperativeRef?: React.Ref<ModalContainerImperatives>;
    backdropClassName?: string;
}

export interface ModalContainerImperatives {
    open: ({ delay }?: { delay: boolean }) => void;
    close: () => void;
}

export const ModalContainer: React.FC<ModalContainerProps> = ({
    animate = true,
    appear = false,
    small = false,
    teaser = false,
    drawer = false,
    confirmation = false,
    embedded = false,
    closeable = true,
    closeOnEsc = true,
    closeOnClick = false,
    imperativeRef,
    backdropClassName,
    className,
    children,

    id = '',
    isOpen: shouldBeOpen = false,
    onToggle = noop,
    onBeforeOpen = noop,
    onOpen = noop,
    onBeforeClose = noop,
    onClose = noop,

    ...rest
}) => {
    const [isToggled, { open: _open, close, toggle }] = useToggleable(false, shouldBeOpen, {
        onBeforeOpen,
        onBeforeClose,
    });

    const adjustDOM = (isOpen: boolean) => {
        if (embedded) return;

        if (!isOpen || isBodyOverflowing()) {
            const scrollbarWidth = getScrollbarWidth();
            const padding = (isOpen ? 1 : -1) * scrollbarWidth;

            setBodyPadding(padding);
            setClassNamePadding('.g-fixed', padding);
        }

        toggleBodyClass('modal-open', isOpen, '.modal:not(.g-modal-embedded)');
    };

    const announce = () => {
        const announcement = document.querySelector<HTMLDivElement>(`#${id}-title`);

        /* istanbul ignore if */
        if (!announcement) return;
        announcement.setAttribute('tabindex', '-1');
        announcement.focus();
    };

    const handleOpen = () => {
        announce();
        onOpen();
    };

    const handleClose = () => {
        onClose();
    };

    const handleBeforeOpen = () => {
        if (!animate || isIE11()) handleOpen();
    };

    const handleBeforeClose = () => {
        if (!animate || isIE11()) handleClose();
    };

    React.useEffect(() => {
        if (isToggled) adjustDOM(true);
        return () => {
            if (isToggled) adjustDOM(false);
        };
    }, [isToggled]); // eslint-disable-line react-hooks/exhaustive-deps

    useUpdateEffect(() => {
        if (isToggled) handleBeforeOpen();
        else handleBeforeClose();
    }, [isToggled]);

    const open = ({ delay = false } = {}) => {
        if (delay && _open) setTimeout(() => _open(), OPEN_DELAY);
        else _open();
    };

    React.useImperativeHandle(imperativeRef, () => ({ open, close }));

    const context = React.useMemo(
        () => ({
            id,
            isOpen: isToggled,
            toggle,
            open: _open,
            close,
            onToggle,
            onBeforeOpen,
            onOpen,
            onBeforeClose,
            onClose,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id, isToggled, onBeforeOpen, onOpen, onBeforeClose, onClose],
    );

    const renderDialog = () => (
        <ModalDialog
            {...omitProps(rest, ModalContainer)}
            id={id}
            small={small}
            teaser={teaser}
            drawer={drawer}
            confirmation={confirmation}
            embedded={embedded}
            className={className}
            closeOnEsc={closeOnEsc}
            closeOnClick={closeOnClick}
        >
            {children}
        </ModalDialog>
    );

    if (embedded) return renderDialog();

    const transitionProps = {
        show: isToggled,
        animate: animate && !isIE11(),
        appear,
    };

    return (
        <ToggleableContext.Provider value={context}>
            <ThemeDetector component="Modal" />
            <Portal>
                <FocusTrap active={isToggled} useFakeButton={!closeable}>
                    <ModalTransitionDialog {...transitionProps}>
                        <ModalBackdrop className={backdropClassName} />
                    </ModalTransitionDialog>
                    <ModalTransitionDialog
                        {...transitionProps}
                        onEntered={handleOpen}
                        onExited={handleClose}
                    >
                        {renderDialog()}
                    </ModalTransitionDialog>
                </FocusTrap>
            </Portal>
        </ToggleableContext.Provider>
    );
};

ModalContainer.displayName = 'ModalContainer';
ModalContainer.propTypes = {
    ...toggleablePropTypes,
    animate: PropTypes.bool,
    appear: PropTypes.bool,
    small: PropTypes.bool,
    teaser: PropTypes.bool,
    drawer: PropTypes.bool,
    embedded: PropTypes.bool,
    confirmation: PropTypes.bool,
    closeable: PropTypes.bool,
    closeOnEsc: PropTypes.bool,
    closeOnClick: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
