import { createContext } from 'utilsTS/react';

export enum THEME {
    BS4 = 'bs4',
    STORE = 'store',
}

export interface ThemeContextType {
    theme?: THEME;
    className?: string;
    useBs5Grid?: boolean;
    useFluidGrid?: boolean;
    useExtendedGrid?: boolean;
    useDarkMode?: boolean;
}

// @ts-expect-error intentionally no default value
export const ThemeContext = createContext<ThemeContextType>('Theme');
