import React from 'react';

export const useUpdateEffect: typeof React.useEffect = (callback, dependencies) => {
    const mountRef = React.useRef(false);

    React.useEffect((...args) => {
        if (mountRef.current) callback(...args);
        else mountRef.current = true;
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};
