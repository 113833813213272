import React from 'react';
import PropTypes from 'prop-types';

import { NewButton as Button } from 'components/Button/NewButton';
import { MatchMedia } from 'components/Utilities/MatchMedia';

import { warning } from 'utilsTS/error';
import { COLOR } from 'utilsTS/color';
import { elementOfType } from 'utilsTS/prop-types';

import { StripeProvider } from './StripeContext';
import { StripeActionButton } from './StripeActionButton';
import { StripeActionSplitButton } from './StripeActionSplitButton';
import { StripeNavigation } from './StripeNavigation';
import { StripeTitle } from './StripeTitle';
import { StripeHeaderGrid, StripeHeaderGridProps } from './components';

export enum STRIPE_HEADER_VARIANT {
    SMALL = 'small',
    LARGE = 'large',
    AUTO = 'auto',
    TRANSPARENT = 'transparent',
}

export interface StripeHeaderProps extends StripeHeaderGridProps {
    /** Defines the variant of the Stripe */
    variant?: STRIPE_HEADER_VARIANT;
    /** Accepts Button or StripeProductInfo components to be shown in the left slot */
    left?: React.ReactNode;
    /** Accepts StripeNavigation or StripeTitle components to be shown in the center slot */
    center?: React.ReactNode;
    /** Accepts StripeActionButton or Button components to be shown in the right slot */
    right?: React.ReactNode;
    /** Accepts a StripeToolbar component to be shown in large variant */
    toolbar?: React.ReactNode;
    /** Defines whether the Stripe inner layout should take all available width */
    fullWidth?: boolean;
}

export interface StripeHeaderStatics {
    COLOR: typeof COLOR;
    VARIANT: typeof STRIPE_HEADER_VARIANT;
}

export const StripeHeader: React.FC<StripeHeaderProps> & StripeHeaderStatics = ({
    variant = STRIPE_HEADER_VARIANT.SMALL,
    left,
    center,
    right,
    color,
    colorValue,
    fullWidth = false,
    sticky,
    toolbar,
    className,
    children,
    ...rest
}) => {
    warning(
        variant === StripeHeader.VARIANT.AUTO && !sticky,
        'StripeHeader.variant: AUTO can only be used in combination with StripeHeader.sticky!',
    );

    const isLargeVariant = !!(
        variant === StripeHeader.VARIANT.LARGE || variant === StripeHeader.VARIANT.AUTO
    );

    return (
        <MatchMedia min={MatchMedia.TIER.MD}>
            {({ isMatching, matchingTier }) => (
                <StripeProvider
                    left={left}
                    center={center}
                    right={right}
                    toolbar={toolbar}
                    fullWidth={fullWidth}
                    isLargeVariant={isLargeVariant}
                    isTransparentVariant={variant === StripeHeader.VARIANT.TRANSPARENT}
                    isMinMediumTier={isMatching}
                    isMinLargeTier={isMatching && matchingTier !== MatchMedia.TIER.MD}
                    useTransition={sticky && variant === StripeHeader.VARIANT.AUTO}
                    matchingTier={matchingTier}
                >
                    <StripeHeaderGrid
                        {...rest}
                        sticky={sticky}
                        color={color}
                        colorValue={colorValue}
                        className={className}
                    >
                        {children}
                    </StripeHeaderGrid>
                </StripeProvider>
            )}
        </MatchMedia>
    );
};

StripeHeader.COLOR = COLOR;
StripeHeader.VARIANT = STRIPE_HEADER_VARIANT;

StripeHeader.displayName = 'StripeHeader';
StripeHeader.propTypes = {
    variant: PropTypes.oneOf(Object.values(StripeHeader.VARIANT)),
    color: PropTypes.oneOf(Object.values(StripeHeader.COLOR)),
    colorValue: PropTypes.string,
    left: PropTypes.node,
    center: elementOfType([StripeTitle, StripeNavigation]),
    right: elementOfType([Button, StripeActionButton, StripeActionSplitButton]),
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    sticky: StripeHeaderGrid.propTypes?.sticky,
    stickyOffset: StripeHeaderGrid.propTypes?.stickyOffset,
    toolbar: PropTypes.node,
    children: PropTypes.node,
};
