import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { LanguageContext } from 'containers/LanguageProvider';

import { Avatar } from 'components/Image/Avatar';
import { Icon } from 'components/Icon';
import { SpinnerSvg } from 'components/Spinner';
import { Tooltip } from 'components/Tooltip';
import { TransitionFade } from 'components/Transition';

import { isElementInChildren } from 'utilsTS/react';
import { ICON } from 'utilsTS/icon';

export enum BUTTON_VARIANT {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    DANGER = 'danger',
}

export enum BUTTON_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
}

export enum BUTTON_HIDDEN_LABEL {
    NEVER = 'never',
    BELOW_SM = 'below-sm',
    BELOW_MD = 'below-md',
    ALWAYS = 'always',
}

export enum BUTTON_FULL_WIDTH {
    NEVER = 'never',
    BELOW_SM = 'below-sm',
    ALWAYS = 'always',
}

export interface ButtonProps extends Omit<React.ComponentPropsWithRef<'button'>, 'children'> {
    /** Defines Button text */
    children: React.ReactNode;
    /** Defines visual variant */
    variant?: BUTTON_VARIANT;
    /** Defines size */
    size?: BUTTON_SIZE;
    /** Defines tiers in which label should be hidden */
    hiddenLabel?: BUTTON_HIDDEN_LABEL;
    /** Defines tiers in which the button should be full-width */
    fullWidth?: BUTTON_FULL_WIDTH;
    /** Accepts a tag or a component replacing the wrapper */
    component?: React.ElementType;
    /** Defines icon */
    icon?: ICON;
    /** Accepts additional className for the icon */
    iconClassName?: string;
    /** Accepts additional className for the label */
    labelClassName?: string;
    /** Accepts Pill component to be shown */
    pill?: React.ReactNode;
    /** Defines whether a Tooltip should be rendered when label hidden */
    showTooltip?: boolean;
    /** Gets called on every click event */
    onClick?: React.MouseEventHandler<HTMLButtonElement>;

    /** Defines whether Button should render disabled */
    disabled?: boolean;
    /** Defines whether Button should render unstyled */
    unstyled?: boolean;
    /** Defines whether Button should render loading */
    isLoading?: boolean;
    /** Defines loading text */
    loadingText?: string;

    /** Defines link Button URL */
    href?: string; // for link-like behaviour
    /** Defines link Button target */
    target?: string; // for link-like behaviour
    /** Defines link Button rel */
    rel?: string; // for link-like behaviour
    /** Defines link Button download */
    download?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface ButtonStatics {
    VARIANT: typeof BUTTON_VARIANT;
    SIZE: typeof BUTTON_SIZE;
    ICON: typeof ICON;
    HIDDEN_LABEL: typeof BUTTON_HIDDEN_LABEL;
    FULL_WIDTH: typeof BUTTON_FULL_WIDTH;
}

/* eslint-disable react/display-name, react/prop-types */
export const NewButton = React.forwardRef(
    (
        {
            variant = BUTTON_VARIANT.PRIMARY,
            size = BUTTON_SIZE.MEDIUM,
            hiddenLabel = BUTTON_HIDDEN_LABEL.NEVER,
            fullWidth = BUTTON_FULL_WIDTH.BELOW_SM,
            component,
            icon,
            type = 'button',
            disabled,
            unstyled,
            isLoading,
            loadingText,
            href,
            className,
            iconClassName,
            labelClassName,
            pill,
            showTooltip,
            onClick = noop,
            children,
            ...rest
        },
        forwardedRef,
    ) => {
        const { getString } = React.useContext(LanguageContext);

        const isLink = !!href && !disabled;
        const hasAvatar = isElementInChildren(children, Avatar);
        const Component = component || (isLink ? 'a' : 'button');
        const linkProps = isLink ? { href } : { type, disabled };
        const loadingProps = isLoading
            ? { 'aria-label': getString('spinner.message', loadingText) || undefined }
            : {};

        const buttonClassNames = cx(
            'g-button',
            unstyled || hasAvatar ? 'g-button-unstyled' : `g-button-variant-${variant}`,
            `g-button-size-${size}`,
            hiddenLabel !== BUTTON_HIDDEN_LABEL.NEVER && `g-button-hidden-label-${hiddenLabel}`,
            `g-button-full-width-${fullWidth}`,
            hasAvatar && 'g-button-avatar',
            isLoading && 'g-button-loading',
            className,
        );
        const iconClassNames = cx('g-button-icon', iconClassName);
        const labelClassNames = cx('g-button-label', labelClassName);

        const handleClick: React.MouseEventHandler = (event) => {
            event.stopPropagation();
            onClick(event);
        };

        const renderSpinner = () =>
            isLoading && (
                <TransitionFade show appear>
                    <SpinnerSvg size={SpinnerSvg.SIZE.SMALL} />
                </TransitionFade>
            );
        const renderIcon = () => icon && <Icon icon={icon} className={iconClassNames} />;
        const renderLabel = () => <span className={labelClassNames}>{children}</span>;
        const renderPill = () => pill && <> {pill}</>;

        if (showTooltip && hiddenLabel === BUTTON_HIDDEN_LABEL.ALWAYS) {
            return (
                <Component
                    ref={forwardedRef}
                    className={buttonClassNames}
                    {...rest}
                    {...linkProps}
                    {...loadingProps}
                    onClick={handleClick}
                >
                    <Tooltip
                        ornamental
                        content={children}
                        enlargeTrigger={Tooltip.ENLARGE_TRIGGER.ALWAYS}
                        offset={[0, 8]}
                    >
                        {renderSpinner()}
                        {renderIcon()}
                        {renderLabel()}
                        {renderPill()}
                    </Tooltip>
                </Component>
            );
        }

        return (
            <Component
                ref={forwardedRef}
                className={buttonClassNames}
                {...rest}
                {...linkProps}
                {...loadingProps}
                onClick={handleClick}
            >
                {renderSpinner()}
                {renderIcon()}
                {renderLabel()}
                {renderPill()}
            </Component>
        );
    },
) as React.ForwardRefExoticComponent<ButtonProps> & ButtonStatics;

NewButton.VARIANT = BUTTON_VARIANT;
NewButton.SIZE = BUTTON_SIZE;
NewButton.ICON = Icon.ICON;
NewButton.HIDDEN_LABEL = BUTTON_HIDDEN_LABEL;
NewButton.FULL_WIDTH = BUTTON_FULL_WIDTH;

NewButton.displayName = 'Button';
NewButton.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
    size: PropTypes.oneOf(Object.values(BUTTON_SIZE)),
    hiddenLabel: PropTypes.oneOf(Object.values(BUTTON_HIDDEN_LABEL)),
    fullWidth: PropTypes.oneOf(Object.values(BUTTON_FULL_WIDTH)),
    component: PropTypes.elementType as React.Validator<React.ElementType>,
    icon: PropTypes.oneOf(Object.values(Icon.ICON)),
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    pill: PropTypes.node,
    showTooltip: PropTypes.bool,
    onClick: PropTypes.func,

    disabled: PropTypes.bool,
    unstyled: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,

    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    download: PropTypes.string,
};
