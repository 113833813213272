import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Card, CardHeader, CardGroup, CardGroupProps } from 'components/Card';
import { Text } from 'components/Layout';

export interface FunctionCardGroupProps extends CardGroupProps {
    /**
     * Accepts components shown in the group header
     * @deprecated only needed in HorizontalFunctionCardGroup, which is deprecated
     */
    header?: React.ReactNode;
    /** Accepts components shown in the group footer */
    footer?: React.ReactNode;
    /**
     * Accepts additional className passed to the group header
     * @deprecated because `header` is too
     */
    headerClassName?: string;
}

export const FunctionCardGroup: React.FC<FunctionCardGroupProps> = ({
    header,
    footer,
    noGap,
    noLine,
    headerClassName,
    className,
    children,
    ...rest
}) => (
    <>
        {header ? (
            <CardGroup key="header" noGap noLine>
                <CardHeader className={cx('g-card-function-header', headerClassName)}>
                    <Text>{header}</Text>
                </CardHeader>
            </CardGroup>
        ) : null}
        <CardGroup
            key="main"
            {...rest}
            noGap={footer ? true : noGap}
            noLine={footer ? true : noLine}
            className={className}
        >
            {children}
        </CardGroup>
        {footer ? (
            <CardGroup key="footer" noGap={noGap} className="g-card-function-footer-shared">
                <Card className="g-card-function-footer">
                    <Text>{footer}</Text>
                </Card>
            </CardGroup>
        ) : null}
    </>
);

FunctionCardGroup.displayName = 'FunctionCardGroup';
FunctionCardGroup.propTypes = {
    ...CardGroup.propTypes,
    header: PropTypes.node,
    footer: PropTypes.node,
    noGap: PropTypes.bool,
    noLine: PropTypes.bool,
    headerClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
