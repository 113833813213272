import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTheme } from 'containers/ThemeProvider/useTheme';

export interface GridProps extends React.ComponentPropsWithRef<'div'> {
    /** Accepts a tag or a component replacing the wrapper */
    tag?: React.ElementType;
    /** Defines whether a fluid-width container should be used */
    fluid?: boolean;
}

export const Grid: React.ForwardRefExoticComponent<GridProps> = React.forwardRef(
    ({ tag: Tag = 'div', fluid, className, ...rest }, forwardedRef) => {
        const { useFluidGrid } = useTheme();

        const isFluid = fluid ?? useFluidGrid ?? true;
        const classNames = cx(isFluid ? 'container-fluid' : 'container', className);

        return <Tag ref={forwardedRef} {...rest} className={classNames} />;
    },
);

Grid.displayName = 'Grid';
Grid.propTypes = {
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
    fluid: PropTypes.bool,
    className: PropTypes.string,
};
