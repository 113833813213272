import { COLORS, COLORS_GROUP, COLORS_SHADED } from './colors';

export function getColorClassName(name?: string, { foreground = false } = {}): string {
    if (!name) return '';

    const isGroup = COLORS_GROUP.some((color) => name === color.name);
    const isShaded = !isGroup && COLORS_SHADED.some((color) => name.startsWith(color.name));
    const colorName = isShaded ? name.slice(0, -4) : name;
    const shade = isShaded ? name.slice(-3) : '';

    const prefix = foreground ? 'fg' : 'bg';

    const color = COLORS.find((c) => c.name === (isShaded ? colorName : name));

    if (!color) return '';
    if (isGroup) return `g-${prefix}-eg-${color.name}`; // DEPRECATED
    if (isShaded) return `g-${prefix}-g-${color.name}-${shade}`; // DEPRECATED
    return `g-${prefix}-${color.name}`;
}
