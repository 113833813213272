import React from 'react';
import cx from 'classnames';

import { AvatarContext } from '../AvatarContext';
import { AvatarShrunkIcon } from './AvatarShrunkIcon';

export const AvatarFallbackIcon: React.FC = () => {
    const { icon, initials, color, iconClassName, imageUrl, isValidImageUrl } =
        React.useContext(AvatarContext);

    if (!icon || (imageUrl && isValidImageUrl) || initials) return null;

    const REL_ICON_SIZE = (100 - 2 * 25) / 100; // .g-avatar-icon has padding: 25%;
    const className = cx('g-avatar-icon', iconClassName);

    return (
        <AvatarShrunkIcon
            className={className}
            color={color}
            shrinkIcon={icon}
            shrinkFactor={REL_ICON_SIZE}
        />
    );
};

AvatarFallbackIcon.displayName = 'AvatarFallbackIcon';
