import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { warning } from 'utilsTS/error';

import { COLOR } from 'utilsTS/color';
import { Card, CardProps } from './Card';

export enum CARD_HEADER_ALIGNMENT {
    CENTER = 'center',
    LEFT = 'left',
}

export enum CARD_HEADER_VARIANT {
    DEFAULT = 'default',
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
}

export interface CardHeaderProps extends CardProps {
    /** Defines visual alignment of the component. Only supported for CARD_HEADER_VARIANT.INFO */
    alignment?: CARD_HEADER_ALIGNMENT;
    /** Defines visual variant of the component */
    variant?: CARD_HEADER_VARIANT;
    /**
     * Defines whether the component should be large
     * @deprecated
     */
    large?: boolean;
    /** Defines background colour of the component */
    color?: COLOR;
}

export interface CardHeaderStatics {
    ALIGNMENT: typeof CARD_HEADER_ALIGNMENT;
    COLOR: typeof COLOR;
    VARIANT: typeof CARD_HEADER_VARIANT;
}

const HEADER_COLOR = {
    [CARD_HEADER_VARIANT.DEFAULT]: undefined,
    [CARD_HEADER_VARIANT.INFO]: undefined,
    [CARD_HEADER_VARIANT.ERROR]: COLOR.UI_RED,
    [CARD_HEADER_VARIANT.SUCCESS]: COLOR.UI_GREEN,
};

export const CardHeader: React.FC<CardHeaderProps> & CardHeaderStatics = ({
    alignment = CARD_HEADER_ALIGNMENT.CENTER,
    variant = CARD_HEADER_VARIANT.DEFAULT,
    large,
    color,
    children,
    className,
    ...rest
}) => {
    const isInfoCardHeader = variant === CardHeader.VARIANT.INFO;
    const isLeftAligned = alignment === CardHeader.ALIGNMENT.LEFT;

    warning(
        isLeftAligned && !isInfoCardHeader,
        'CardHeader.ALIGNMENT is only supported for CardHeader.VARIANT.INFO',
    );

    const getClassNames = () => {
        if (isInfoCardHeader) return 'g-card-header-info';
        return large ? 'g-card-header-large' : 'g-card-header-small';
    };

    const variantColor = HEADER_COLOR[variant];
    const appliedColor = color && !isInfoCardHeader ? color : variantColor;

    const classNames = cx(
        getClassNames(),
        isLeftAligned && 'g-card-header-left',
        appliedColor && 'g-inverted',
        className,
    );

    return (
        <Card color={appliedColor} className={classNames} {...rest}>
            {children}
        </Card>
    );
};

CardHeader.ALIGNMENT = CARD_HEADER_ALIGNMENT;
CardHeader.COLOR = COLOR;
CardHeader.VARIANT = CARD_HEADER_VARIANT;

CardHeader.displayName = 'CardHeader';
CardHeader.propTypes = {
    alignment: PropTypes.oneOf(Object.values(CARD_HEADER_ALIGNMENT)),
    variant: PropTypes.oneOf(Object.values(CARD_HEADER_VARIANT)),
    large: PropTypes.bool, // DEPRECATED
    color: PropTypes.oneOf(Object.values(CardHeader.COLOR)),
    children: PropTypes.node,
    className: PropTypes.string,
};
