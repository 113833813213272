import React from 'react';
import PropTypes from 'prop-types';

import { Text, TextProps, Row, Col } from 'components/Layout';

import { AlertContext } from './AlertContext';
import { ALERT_SIZE } from './AlertEnums';

export interface AlertContentHeadingProps extends TextProps {
    /** Defines the tag of the Alert heading */
    tag?: React.ElementType;
    /** Accepts Button components to be shown on the side of the Alert */
    buttons?: React.ReactNode;
    /** Defines whether the component acts as a heading */
    isHeading?: boolean;
}

export const AlertContentHeading: React.FC<AlertContentHeadingProps> = ({
    tag = 'h3',
    buttons,
    isHeading = true,
    children,
    ...rest
}) => {
    const { isOpen, size } = React.useContext(AlertContext);

    if (!children || size === ALERT_SIZE.SMALL) return null;

    const renderHeading = () => {
        if (!isHeading) return isOpen ? '\u200B' : children;
        return children;
    };

    if (buttons) {
        return (
            <Row {...rest}>
                <Col md={8}>
                    <Text tag={tag}>{renderHeading()}</Text>
                </Col>
            </Row>
        );
    }

    return (
        <Text {...rest} tag={tag}>
            {renderHeading()}
        </Text>
    );
};

AlertContentHeading.displayName = 'AlertContentHeading';
AlertContentHeading.propTypes = {
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
    buttons: PropTypes.node,
    isHeading: PropTypes.bool,
    children: PropTypes.node,
};
