import React from 'react';

import { TIER } from './constants';
import { MatchMediaContext } from './MatchMediaContext';

export function useMatchMediaTier(tier: TIER): boolean {
    const { matchingTier } = React.useContext(MatchMediaContext);
    const tiers = Object.values(TIER);
    const tierIndex = tiers.indexOf(tier);
    const matchingIndex = tiers.indexOf(matchingTier);
    return tierIndex >= 0 && matchingIndex >= tierIndex;
}
