import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { LanguageString } from 'containers/LanguageProvider';

import { Col, type ColSize } from 'components/Layout';

import { omitProps } from 'utilsTS/react';

import styles from './Form.scss';

export interface FormLabelProps extends React.ComponentPropsWithoutRef<'label'> {
    /** Accepts id of the related input component */
    inputId?: string;
    /** Defines whether the field should be required */
    required?: boolean;
    /** Defines the column width of the label */
    columnWidth?: ColSize;
    /** Defines the string replacing the "optional" label */
    optionalLabel?: React.ReactNode;
    /** Defines whether no grid layout should be applied */
    noLayout?: boolean;
    /** Defines whether the label is fake */
    fake?: boolean;
}

export const FormLabel: React.FC<FormLabelProps> = ({
    inputId = '',
    required,
    columnWidth = 0,
    optionalLabel,
    noLayout,
    fake,
    className,
    children,
    ...rest
}) => {
    const renderOptionalLabel = () => {
        if (required) return null;

        return (
            <span className={cx('g-faded', styles.optional)}>
                <LanguageString name="form.label.optional" value={optionalLabel as string} />
            </span>
        );
    };

    if (!children) return null;

    const noLayoutTagName = (fake ? 'span' : 'label') as React.ElementType;
    const ColComponent = noLayout ? noLayoutTagName : Col;
    const colProps = noLayout
        ? {}
        : {
              tag: fake ? undefined : 'label',
              xs: Col.MAX_WIDTH,
              md: columnWidth,
          };
    const classNames = cx(
        noLayout || 'col-form-label',
        styles.label,
        !required && styles.optional,
        className,
    );

    return (
        <ColComponent
            {...omitProps(rest, FormLabel)}
            {...colProps}
            htmlFor={fake ? undefined : inputId}
            className={classNames}
        >
            {children} {renderOptionalLabel()}
        </ColComponent>
    );
};

FormLabel.displayName = 'FormLabel';
FormLabel.propTypes = {
    inputId: PropTypes.string,
    required: PropTypes.bool,
    columnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]) as
        | React.Validator<ColSize | undefined>
        | undefined,
    optionalLabel: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    noLayout: PropTypes.bool,
    fake: PropTypes.bool,
};
