import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image } from '../Image';

import styles from './Flag.scss';

export interface FlagProps extends Omit<React.ComponentPropsWithoutRef<'img'>, 'src'> {
    /** Defines URL of the flag to load */
    imageUrl: string;
    /** Defines alternate text for screen readers */
    alt?: string;
}

export const Flag: React.FC<FlagProps> = ({ imageUrl, alt, className, ...rest }) => (
    <Image src={imageUrl} alt={alt || ''} {...rest} className={cx(styles.flag, className)} />
);

Flag.displayName = 'Flag';
Flag.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
};
