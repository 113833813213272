import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ButtonGroup } from 'components/Button/ButtonGroup';
import { NewButton as Button, ButtonProps } from 'components/Button/NewButton';

import { cloneElement, renderProp } from 'utilsTS/react';

import styles from './SplitButton.scss';

export interface SplitButtonProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Accepts a Button component to be rendered in the left slot */
    button: React.ReactElement<ButtonProps>;
    /** Accepts a MenuDropdown component to be rendered in the right slot */
    dropdown: React.ReactElement;
}

export const SplitButton: React.FC<SplitButtonProps> = ({
    button,
    dropdown,
    className,
    ...rest
}) => {
    const toggle = renderProp(
        dropdown.props.toggle,
        dropdown.props.renderToggle,
    ) as React.ReactElement;

    return (
        <ButtonGroup fullWidth={false} className={cx(styles.splitButton, className)} {...rest}>
            {cloneElement(button, { fullWidth: Button.FULL_WIDTH.NEVER })}
            {cloneElement(dropdown, {
                toggle: cloneElement(toggle, {
                    variant: button.props.variant || toggle.props.variant,
                    size: button.props.size || toggle.props.size, // eslint-disable-line unicorn/explicit-length-check
                    icon: Button.ICON.CARET_DOWN,
                    hiddenLabel: Button.HIDDEN_LABEL.ALWAYS,
                    fullWidth: Button.FULL_WIDTH.NEVER,
                }),
            })}
        </ButtonGroup>
    );
};

SplitButton.displayName = 'SplitButton';
SplitButton.propTypes = {
    ...ButtonGroup.propTypes,
    button: PropTypes.element.isRequired,
    dropdown: PropTypes.element.isRequired,
};
