import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { LanguageContext } from 'containers/LanguageProvider';

import { NewButton as Button } from 'components/Button/NewButton';

import { ToggleableContext } from 'utilsTS/hooks';

export interface ModalHeaderCloseProps {
    small?: boolean;
    closeLabel?: string;
}

export const ModalHeaderClose: React.FC<ModalHeaderCloseProps> = ({ small, closeLabel }) => {
    const { getString } = useContext(LanguageContext);
    const { close } = useContext(ToggleableContext);

    const label = getString('common.close', closeLabel);

    return (
        <Button
            hiddenLabel={small ? Button.HIDDEN_LABEL.ALWAYS : Button.HIDDEN_LABEL.BELOW_MD}
            variant={Button.VARIANT.TERTIARY}
            size={Button.SIZE.SMALL}
            icon={Button.ICON.ERROR}
            iconClassName="g-button-icon-right"
            fullWidth={Button.FULL_WIDTH.NEVER}
            className="modal-close"
            onClick={close}
        >
            {label}
        </Button>
    );
};

ModalHeaderClose.displayName = 'ModalHeaderClose';
ModalHeaderClose.propTypes = {
    small: PropTypes.bool,
    closeLabel: PropTypes.string,
};
