import { isObject } from 'lodash';

function transformKey(key) {
    if (typeof key !== 'string') return '';
    return key.toUpperCase().replace(/[^\dA-Z]/g, '_');
}

export default class Enum {
    constructor(...types) {
        types.forEach((type) => {
            if (isObject(type)) {
                Object.keys(type).forEach((key) => {
                    this[key] = type[key];
                });
            } else if (typeof type === 'string') {
                const key = transformKey(type);
                this[key] = type;
            } else if (type !== null && type !== undefined) {
                const key = transformKey(type.toString());
                this[key] = type;
            }
        });

        Object.freeze(this);
    }

    keys() {
        return Object.keys(this);
    }

    values() {
        return Object.values(this);
    }

    entries() {
        return this.keys().reduce((previous, key) => [...previous, [key, this[key]]], []);
    }

    includes(value) {
        return this.values().includes(value);
    }

    has(key) {
        if (typeof key !== 'string') return false;
        return this.keys().includes(transformKey(key));
    }

    get(key) {
        return this[transformKey(key)] || null;
    }

    find(value) {
        return this.keys().find((key) => value === this[key]);
    }

    pick(...values) {
        const keys = values.map((value) => this.find(value));
        const types = keys.reduce(
            (previous, key) => ({
                ...previous,
                [key]: this[key],
            }),
            {},
        );
        return Object.freeze(new Enum(types));
    }

    toString() {
        return this.keys().join('|');
    }
}
