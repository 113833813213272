import { createContext } from 'utilsTS/react';

export interface ContextTypes {
    windowNode?: Window;
    documentNode?: Document;
}

export const Context = createContext<ContextTypes>('', {
    windowNode: undefined,
    documentNode: undefined,
});
