import React from 'react';
import { isFunction } from 'lodash';

import { cloneElement } from './cloneElement';
import { isValidComponent } from './isValidComponent';
import { mergeProps } from './mergeProps';

export type ElementOrComponent =
    | React.ReactNode
    | React.ComponentType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
    | ((props: Record<string, unknown>) => React.ReactNode);

export function cloneOrRender(
    elementOrComponent: ElementOrComponent | ElementOrComponent[],
    ...props: Record<string, unknown>[]
): React.ReactNode {
    if (!elementOrComponent) return null;

    if (typeof elementOrComponent === 'string') return elementOrComponent;

    if (isFunction(elementOrComponent)) {
        const merged = mergeProps(...props);

        if (isValidComponent(elementOrComponent as React.ComponentType)) {
            return React.createElement(elementOrComponent as React.ComponentType, merged);
        }

        return elementOrComponent(merged);
    }

    if (Array.isArray(elementOrComponent)) {
        return elementOrComponent.map((element) => cloneOrRender(element, ...props));
    }

    return cloneElement(elementOrComponent as React.ReactElement, ...props);
}
