import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { NewButton as Button, ButtonProps, ButtonStatics } from 'components/Button/NewButton';

import { StripeContext } from './StripeContext';
import { StripeHeaderStickyContainer } from './components';

export interface StripeActionButtonProps
    extends Omit<ButtonProps, 'variant' | 'fullWidth' | 'size' | 'hiddenLabel'> {
    /** Defines whether the StripeActionButton should be sticky by the bottom of the viewport */
    sticky?: boolean;
    /** Defines the offset from the bottom of the viewport */
    stickyOffset?: string | number;
}

type StripeActionButtonStatics = Pick<ButtonStatics, 'ICON'>;

const BUTTON_DEFAULT_PROPS = {
    hiddenLabel: Button.HIDDEN_LABEL.BELOW_MD,
};

export const StripeActionButton: React.FC<StripeActionButtonProps> & StripeActionButtonStatics = ({
    sticky,
    stickyOffset,
    icon,
    children,
    ...rest
}) => {
    const { isMinMediumTier } = React.useContext(StripeContext);

    if (sticky && !isMinMediumTier) {
        return (
            <StripeHeaderStickyContainer offset={stickyOffset}>
                <Button
                    {...rest}
                    {...BUTTON_DEFAULT_PROPS}
                    hiddenLabel={Button.HIDDEN_LABEL.NEVER}
                    className="g-no-inverted"
                >
                    {children}
                </Button>
            </StripeHeaderStickyContainer>
        );
    }

    if (isMinMediumTier) {
        return (
            <Button {...rest} {...BUTTON_DEFAULT_PROPS} icon={icon}>
                {children}
            </Button>
        );
    }

    if (icon) {
        return (
            <Button
                {...rest}
                {...BUTTON_DEFAULT_PROPS}
                variant={Button.VARIANT.TERTIARY}
                size={Button.SIZE.SMALL}
                icon={icon}
            >
                {children}
            </Button>
        );
    }

    return null;
};

StripeActionButton.ICON = Button.ICON;

StripeActionButton.displayName = 'StripeActionButton';
StripeActionButton.propTypes = {
    ...omit(Button.propTypes, 'variant'),
    sticky: PropTypes.bool,
    stickyOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
