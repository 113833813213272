import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTheme } from 'containers/ThemeProvider';
import { Grid } from 'components/Layout';

import { StripeContext } from '../StripeContext';
import styles from './StripeHeaderToolbarGrid.scss';

export interface StripeHeaderToolbarGridProps extends React.ComponentPropsWithRef<'div'> {
    /** Defines whether the component should be sticky towards to the top of the viewport */
    sticky?: boolean;
}

export const StripeHeaderToolbarGrid: React.ForwardRefExoticComponent<StripeHeaderToolbarGridProps> =
    forwardRef((props, forwardedRef) => {
        const { sticky, className, ...rest } = props;
        const { toolbar, fullWidth } = useContext(StripeContext);
        const { useFluidGrid } = useTheme();

        if (!toolbar) return null;

        const classNames = cx(styles.grid, sticky && styles.sticky, className);

        return (
            <div {...rest} ref={forwardedRef} className={classNames}>
                <Grid fluid={fullWidth || useFluidGrid} className={styles.toolbar}>
                    {toolbar}
                </Grid>
            </div>
        );
    });

StripeHeaderToolbarGrid.displayName = 'StripeHeaderToolbarGrid';
StripeHeaderToolbarGrid.propTypes = {
    sticky: PropTypes.bool,
    className: PropTypes.string,
};
