import React from 'react';
import PropTypes from 'prop-types';

import { nl2br } from 'utilsTS/string';

export interface TextProps extends React.ComponentPropsWithRef<'p'> {
    /** Accepts a tag or a component replacing the wrapper */
    tag?: React.ElementType | null;
    /** Defines whether a wrapper is always used */
    always?: boolean;
}

export const Text: React.ForwardRefExoticComponent<TextProps> = React.forwardRef(
    ({ tag: Tag = 'p', always, children, ...rest }, forwardedRef) => {
        const text = nl2br(children);

        if (!text) return null;
        if (!always && typeof children !== 'string') {
            return text;
        }

        if (!Tag) return text;

        return (
            <Tag {...rest} ref={forwardedRef}>
                {text}
            </Tag>
        );
    },
);

Text.displayName = 'Text';
Text.propTypes = {
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
    always: PropTypes.bool,
    children: PropTypes.node,
};
