import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { VisuallyHidden } from 'components/Utilities/VisuallyHidden';

import { getColorClassName, COLOR } from 'utilsTS/color';
import { deprecate } from 'utilsTS/error';
import { mergeProps, cloneOrRender, type ElementOrComponent } from 'utilsTS/react';
import { ICON, ICON_SIZE, getIcon } from 'utilsTS/icon';

export type IconSVGComponent = React.ElementType<React.SVGProps<SVGSVGElement>>;

export interface IconProps extends Omit<React.ComponentPropsWithoutRef<'svg'>, 'children'> {
    /** Defines whether a `<use />` tag should be used */
    symbol?: boolean;
    /** Defines icon name or a custom SVG compatible component */
    icon?: ICON | IconSVGComponent;
    /** Defines icon colour */
    color?: COLOR | ''; // TODO: some components are passing empty string here, review when migrating
    /** Defines accessible label for the icon */
    ariaLabel?: string;
    /** Defines icon size */
    size?: ICON_SIZE;
    /** Defines whether detailed icon should be used (only for size 48 and bigger) */
    useDetailed?: boolean;
    /** The SVG contents to display in the icon */
    children?: React.SVGProps<SVGSVGElement>;
}

export interface IconStatics {
    ICON: typeof ICON;
    COLOR: typeof COLOR;
    SIZE: typeof ICON_SIZE;
}

export const Icon: React.FC<IconProps> & IconStatics = ({
    symbol = false,
    icon,
    color = '',
    ariaLabel,
    className,
    size,
    useDetailed = false,
    children,
    ...rest
}) => {
    deprecate(size === Icon.SIZE.SIZE_20, 'Icon.SIZE.SIZE_20', 'Please use other Icon size.');

    const props = {
        'aria-hidden': true,
        className: cx(
            'g-icon',
            size && `g-image-${size}`,
            getColorClassName(color, { foreground: true }),
            className,
        ),
        ...rest,
    };

    const renderIcon = () => {
        if (icon && Object.values(ICON).includes(icon as ICON)) {
            const { id, viewBox, svg } = getIcon(icon as ICON, size, useDetailed);

            if (!svg) return null;

            const namedProps = mergeProps(props, { className: id });

            if (symbol) {
                const reference = `#${id}`;
                return (
                    <svg {...namedProps}>
                        <use href={reference} xlinkHref={reference} />
                    </svg>
                );
            }
            return (
                <svg
                    {...namedProps}
                    viewBox={viewBox}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: svg,
                    }}
                />
            );
        }

        const svg = icon || children;

        if (svg && typeof svg !== 'string') {
            return cloneOrRender(svg as unknown as ElementOrComponent, props);
        }

        return null;
    };

    return (
        <>
            {renderIcon()}
            {ariaLabel && <VisuallyHidden>{ariaLabel}</VisuallyHidden>}
        </>
    );
};

Icon.ICON = ICON;
Icon.SIZE = ICON_SIZE;
Icon.COLOR = COLOR;

Icon.displayName = 'Icon';
Icon.propTypes = {
    symbol: PropTypes.bool,
    icon: PropTypes.oneOfType([
        PropTypes.oneOf(Object.values(ICON)),
        PropTypes.elementType,
    ]) as React.Validator<IconSVGComponent | ICON | null | undefined>,
    color: PropTypes.oneOf([...Object.values(COLOR), '']), // TODO: some components are passing empty string here, review when migrating
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(ICON_SIZE)),
    useDetailed: PropTypes.bool,
    children: PropTypes.element as React.Validator<
        React.SVGProps<SVGSVGElement> | null | undefined
    >,
};
