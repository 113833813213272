import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FormContext } from './FormContext';

export type FormProps = React.ComponentPropsWithoutRef<'form'>;

const context = {
    formField: false,
    isLargeFormField: false,
};

export const Form: React.FC<FormProps> = ({ className, children, ...rest }) => (
    <FormContext.Provider value={context}>
        <form {...rest} className={cx(className)}>
            {children}
        </form>
    </FormContext.Provider>
);

Form.displayName = 'Form';
Form.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
