import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ref(): PropTypes.Validator<React.Ref<any> | undefined> {
    // @ts-expect-error classic PropTypes problem
    return PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.shape({}),
        }),
    ]);
}
