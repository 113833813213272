const ZERO = 0;
const UNIT_SECOND = 's';
const UNIT_MILLISECOND = 'ms';
const MILLISECOND_PER_SECOND = 1000;

export function parseDuration(duration?: string | number, fallbackDuration = ZERO): number {
    if (typeof duration === 'number' && duration >= ZERO) return duration;

    if (typeof duration === 'string') {
        const sanitizedString = duration.trim();
        const floatTimeout = Number.parseFloat(sanitizedString);

        if (floatTimeout === ZERO) return ZERO;
        if (!floatTimeout) return fallbackDuration;
        if (sanitizedString.endsWith(UNIT_MILLISECOND)) return Math.ceil(floatTimeout);
        if (sanitizedString.endsWith(UNIT_SECOND))
            return Math.ceil(floatTimeout * MILLISECOND_PER_SECOND);
    }

    return fallbackDuration;
}
