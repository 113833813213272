import PropTypes from 'prop-types';

import { getDisplayName, isElementOfComponent } from 'utilsTS/react';

import { createTypeChecker } from './createTypeChecker';

export function elementOfType<T = PropTypes.ReactElementLike>(
    components: PropTypes.ReactComponentLike[],
): PropTypes.Requireable<T> {
    const validate: PropTypes.Validator<T> = (props, propName, componentName) => {
        const element = props[propName];

        if (element === false) return null;

        const elementOfComponents = components.some((nameOrComponent) =>
            isElementOfComponent(element, nameOrComponent),
        );

        if (!elementOfComponents) {
            const componentNames = components.map((component) => getDisplayName(component));
            return new Error(
                `${propName} in ${componentName} needs to be an element of \`[${componentNames.join(
                    ', ',
                )}]\`, but is an element of \`${getDisplayName(element)}\``,
            );
        }

        return null;
    };

    return createTypeChecker(validate);
}
