import React from 'react';

function replaceNewlines(text: string) {
    if (typeof text !== 'string') return text;

    const lines = text.split('\n');
    if (lines.length === 1) return lines[0];

    // eslint-disable-next-line react/no-array-index-key
    const parts = lines.map((line, index) => [line || null, <br key={`${line}-${index}`} />]);

    return parts.flat().slice(0, -1);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function nl2br(text: any): any {
    if (text === 0) return '0';
    if (!text) return null;
    if (Array.isArray(text)) return text.map(replaceNewlines);
    if (typeof text !== 'string') return text;

    return replaceNewlines(text.trim());
}
