import React from 'react';
import PropTypes from 'prop-types';

import { TransitionCollapseHeight, TransitionCollapseHeightProps } from 'components/Transition';

import styles from '../Snackbar.scss';

export const SnackbarItemTransition: React.FC<TransitionCollapseHeightProps> = ({
    show = false,
    appear = false,
    children,
    ...rest
}) => (
    <TransitionCollapseHeight
        bottom
        unmountOnExit
        show={show}
        appear={appear}
        transition={styles}
        timeout={styles.timeout}
        {...rest}
    >
        <div className={styles.transition}>{children}</div>
    </TransitionCollapseHeight>
);

SnackbarItemTransition.displayName = 'SnackbarItemTransition';
SnackbarItemTransition.propTypes = {
    show: PropTypes.bool,
    appear: PropTypes.bool,
    children: PropTypes.node,
};
