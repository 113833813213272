import React from 'react';

import { generateId } from 'utilsTS/string';

import { useUpdateEffect } from './useUpdateEffect';

export function useId(prefix?: string, defaultId?: string): string {
    const [id, setId] = React.useState(defaultId || generateId(prefix));

    useUpdateEffect(() => {
        setId(defaultId || generateId(prefix));
    }, [prefix, defaultId]);

    return id;
}
