import React from 'react';
import { Manager, Reference, type ReferenceChildrenProps } from 'react-popper';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ThemeDetector } from 'containers/ThemeProvider';

import { Icon } from 'components/Icon';
import { Portal } from 'components/Utilities/Portal';
import { VisuallyHidden } from 'components/Utilities/VisuallyHidden';

import { deprecate } from 'utilsTS/error';
import { useDebounce } from 'utilsTS/hooks';
import { cloneElement, omitProps } from 'utilsTS/react';
import { generateId } from 'utilsTS/string';

import { TooltipContent } from './TooltipContent';

const MOUSE_LEAVE_DELAY = 100;

export enum TOOLTIP_ENLARGE_TRIGGER {
    NEVER = 'never',
    AUTO = 'auto',
    ALWAYS = 'always',
}

export interface TooltipProps {
    children?: React.ReactNode;
    className?: string;

    /** Defines the tag of the Popper reference element */
    tag?: React.ElementType;
    /** Defines whether the tooltip is shown by default */
    enabled?: boolean;
    /** Defines the content of the tooltip */
    content?: React.ReactNode;
    /** Defines Popper offset of the tooltip */
    offset?: number[];
    /** Defines whether the tooltip carries meaning */
    ornamental?: boolean;
    /** Defines whether a React Portal should be used */
    usePortal?: boolean;
    /** Defines the tooltip should react to mouse events */
    showOnHover?: boolean;
    /** Defines whether the touch target size of the tooltip trigger should be increased */
    enlargeTrigger?: TOOLTIP_ENLARGE_TRIGGER;
    /** Accepts additional className for the tooltip content wrapper */
    tooltipClassName?: string;
    /** Accepts additional className for the tooltip trigger */
    triggerClassName?: string;
}

export interface TooltipStatics {
    ENLARGE_TRIGGER: typeof TOOLTIP_ENLARGE_TRIGGER;
}

export const Tooltip: React.FC<TooltipProps> & TooltipStatics = ({
    tag: Tag = 'span',
    enabled = false,
    content,
    offset,
    ornamental,
    showOnHover = true,
    usePortal = true,
    enlargeTrigger = TOOLTIP_ENLARGE_TRIGGER.AUTO,
    className,
    tooltipClassName,
    triggerClassName,
    children,
    ...rest
}) => {
    const [isEnabled, setEnabled] = React.useState(enabled);
    const [id] = React.useState(generateId('tooltip'));

    React.useEffect(() => {
        setEnabled(enabled);
    }, [enabled]);

    const renderDeprecatedTrigger = () => {
        // DEPRECATED
        const isDeprecated =
            children && (!React.isValidElement(children) || children.type === React.Fragment);
        return isDeprecated ? children : null;
    };

    deprecate(
        !!renderDeprecatedTrigger(),
        'Tooltip',
        'Tooltip support for strings/arrays/fragments in children has been deprecated, please provide an element that can handle aria-labelledby instead. https://www.tpgi.com/short-note-on-aria-label-aria-labelledby-and-aria-describedby/',
    );

    const handleMouseLeave = useDebounce(() => {
        if (!showOnHover) return;

        setEnabled(false);
    }, MOUSE_LEAVE_DELAY);

    const handleMouseEnter = () => {
        if (!showOnHover) return;

        handleMouseLeave.cancel();
        setEnabled(true);
    };

    const renderTrigger = () => {
        const triggerProps = {
            'aria-labelledby': id,
            tabIndex: 0,
            className: cx(
                'g-tooltip-trigger',
                !children && 'g-tooltip-trigger-default',
                triggerClassName,
            ),
        };

        if (!children) {
            return (
                <Icon icon={Icon.ICON.INFO} role="img" aria-hidden={undefined} {...triggerProps} />
            );
        }
        return cloneElement(children, triggerProps);
    };

    const touchTargetClasses = cx(
        'g-tooltip-touch-target',
        `g-tooltip-touch-target-enlarge-${enlargeTrigger}`,
        className,
    );

    const renderReference = ({ ref }: ReferenceChildrenProps) => {
        const deprecatedTrigger = renderDeprecatedTrigger();

        return (
            <Tag
                ref={ref}
                className={touchTargetClasses}
                {...omitProps(rest, Tooltip)}
                onFocus={handleMouseEnter}
                onBlur={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {deprecatedTrigger || renderTrigger()}
                {!ornamental && (
                    <VisuallyHidden
                        id={id}
                        aria-hidden={deprecatedTrigger ? undefined : true}
                        className="g-tooltip-label"
                    >
                        {content}
                    </VisuallyHidden>
                )}
            </Tag>
        );
    };

    if (!content) {
        return (
            <Tag className={className} {...omitProps(rest, Tooltip)}>
                {children}
            </Tag>
        );
    }

    return (
        <>
            <ThemeDetector component={Tooltip} />
            <Manager>
                <Reference>{renderReference}</Reference>
                <Portal active={usePortal && isEnabled}>
                    {isEnabled ? (
                        <TooltipContent
                            offset={offset}
                            className={tooltipClassName}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {content}
                        </TooltipContent>
                    ) : undefined}
                </Portal>
            </Manager>
        </>
    );
};

Tooltip.ENLARGE_TRIGGER = TOOLTIP_ENLARGE_TRIGGER;

Tooltip.displayName = 'Tooltip';
Tooltip.propTypes = {
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
    enabled: PropTypes.bool,
    content: PropTypes.node,
    offset: PropTypes.arrayOf(PropTypes.number.isRequired),
    ornamental: PropTypes.bool,
    showOnHover: PropTypes.bool,
    usePortal: PropTypes.bool,
    enlargeTrigger: PropTypes.oneOf(Object.values(Tooltip.ENLARGE_TRIGGER)),
    className: PropTypes.string,
    tooltipClassName: PropTypes.string,
    triggerClassName: PropTypes.string,
    children: PropTypes.node,
};
