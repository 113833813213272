import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export type GroupProps = React.ComponentPropsWithoutRef<'div'>;

export const Group: React.FC<GroupProps> = ({ className, children, ...rest }) => (
    <div className={cx('g-group', className)} {...rest}>
        {children}
    </div>
);

Group.displayName = 'Group';
Group.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
