import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { useId, useInputStringValue } from 'utilsTS/hooks';

import { inputPropTypes } from './inputPropTypes';

export interface TextareaInputProps extends React.ComponentPropsWithoutRef<'textarea'> {
    innerRef?: React.Ref<HTMLTextAreaElement>;
    onBeforeChange?: (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        value: string,
    ) => boolean | void;
    processValue?: (value: string) => string;
    autoGrow?: boolean;
    maxHeight?: string | number;
}

const autoResize = (element: HTMLTextAreaElement) => {
    const { style } = element; // prevent no-param-reassign eslint error
    const { borderTopWidth, borderBottomWidth } = window.getComputedStyle(element);
    const extraHeight =
        Number.parseInt(borderTopWidth, 10) + Number.parseInt(borderBottomWidth, 10);

    style.height = 'inherit'; // reset scrollHeight when deleting text
    style.height = `${element.scrollHeight + extraHeight}px`; // resize element to accommodate potential scroll
};

export const TextareaInput: React.FC<TextareaInputProps> = ({
    innerRef,
    id: defaultId,
    rows = 2,
    value: controlledValue,
    defaultValue,
    placeholder,
    readOnly = false,
    autoGrow = true,
    maxHeight,
    className,
    processValue,
    onChange = noop,
    onBeforeChange = () => true,
    ...rest
}) => {
    const id = useId('textarea', defaultId);
    const [value, handleChange] = useInputStringValue<HTMLTextAreaElement>(
        defaultValue,
        controlledValue,
        {
            readOnly,
            onBeforeChange,
            onChange: (event) => {
                if (autoGrow) autoResize(event.target);
                onChange(event);
            },
            processValue,
        },
    );

    return (
        <textarea
            {...rest}
            ref={innerRef}
            id={id}
            rows={rows}
            value={value}
            placeholder={placeholder}
            readOnly={readOnly}
            className={cx('form-control', className)}
            style={{ maxHeight: autoGrow ? maxHeight : undefined }}
            onChange={handleChange as unknown as React.ChangeEventHandler<HTMLTextAreaElement>}
        />
    );
};

TextareaInput.displayName = 'TextareaInput';
TextareaInput.propTypes = {
    ...inputPropTypes,
    rows: PropTypes.number,
    placeholder: PropTypes.string,
    autoGrow: PropTypes.bool,
    maxHeight: PropTypes.string,
};
TextareaInput.defaultProps = {
    innerRef: noop, // needed for DropdownToggleWrapper to correctly recognise this as innerRef component
};
