import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';
import { createEnum } from 'utils/enum';
import { omitProps } from 'utils/react';
import { LanguageContext } from 'containers/LanguageProvider';
import { Icon } from 'components/Icon';
import { SpinnerSvg } from 'components/Spinner';
import { TransitionFade } from 'components/Transition';
import { Link } from 'components/Typography';

export const BUTTON_SIZE = createEnum({
    SMALL: 'S',
    MEDIUM: 'M',
    LARGE: 'L',
});

export const BUTTON_COLOR = createEnum(
    'none',
    'primary',
    'secondary',
    'store',
    'danger',
    'transparent',
);

export const propTypes = {
    innerRef: PropTypes.ref(),
    href: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.oneOf(BUTTON_SIZE.values()),
    icon: PropTypes.oneOf(Object.values(Icon.ICON)),
    iconLabel: PropTypes.string,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    link: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    children: PropTypes.node,
};

export const defaultProps = {
    innerRef: undefined,
    href: '',
    type: 'button',
    size: BUTTON_SIZE.MEDIUM,
    icon: null,
    iconLabel: '',
    disabled: false,
    outline: false,
    link: false,
    isLoading: false,
    loadingText: null,
    className: null,
    iconClassName: null,
    labelClassName: null,
    children: null,
};

const Button = ({
    innerRef,
    href,
    type,
    color,
    size,
    icon,
    iconLabel,
    link,
    outline,
    disabled,
    isLoading,
    loadingText,
    className,
    iconClassName,
    labelClassName,
    children,
    onClick,
    ...rest
}) => {
    const getTypeClassName = () => {
        if (!color || color === BUTTON_COLOR.NONE) return '';

        if (outline) return `g-btn-outline-${color}`;
        if (link) return `g-btn-link-${color}`;
        if (icon) return `g-btn-icon-${color}`;
        return `g-btn-${color}`;
    };

    const handleClick = (event) => {
        event.stopPropagation();
        onClick(event);
    };

    const { getString } = useContext(LanguageContext);

    const SIZE_CLASS = {
        [BUTTON_SIZE.SMALL]: 'btn-sm',
        [BUTTON_SIZE.LARGE]: 'btn-lg',
    };

    const isLink = !disabled && !!href;
    const ButtonComponent = isLink ? Link : 'button';
    const buttonClassName = cx(
        'btn',
        SIZE_CLASS[size],
        icon && children && 'g-btn-icon',
        icon && !children && 'g-btn-icon-only',
        getTypeClassName(),
        isLoading && 'g-button-loading',
        className,
    );

    const linkProps = isLink ? { ref: innerRef, href } : { ref: innerRef, type, disabled };
    const loadingProps = isLoading
        ? { 'aria-label': getString('spinner.message', loadingText) }
        : {};

    return (
        <ButtonComponent
            {...omitProps(rest, Button)}
            {...loadingProps}
            {...linkProps}
            className={buttonClassName}
            onClick={handleClick}
        >
            {isLoading && (
                <TransitionFade show appear>
                    <SpinnerSvg size={SpinnerSvg.SIZE.SMALL} />
                </TransitionFade>
            )}
            {icon && (
                <Icon
                    icon={icon}
                    className={iconClassName}
                    ariaLabel={iconLabel}
                    aria-hidden={!!children || undefined}
                />
            )}
            {children && <span className={labelClassName}>{children}</span>}
        </ButtonComponent>
    );
};

Button.displayName = 'Button';

Button.SIZE = BUTTON_SIZE;

Button.ICON = Icon.ICON;

Button.COLOR = BUTTON_COLOR;

Button.propTypes = {
    color: PropTypes.oneOf(BUTTON_COLOR.values()),
    onClick: PropTypes.func,
    ...propTypes,
};

Button.defaultProps = {
    color: BUTTON_COLOR.PRIMARY,
    onClick: noop,
    ...defaultProps,
};

export default Button;
