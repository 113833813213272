/* eslint-disable no-console */

import { DEV } from '../constants';

const warned: Record<string, boolean> = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function warning(condition: boolean, format: string, ...args: any[]): void {
    if (!DEV) return;
    if (!condition) return;

    let argumentIndex = 0;
    const message = format.replace(/%s/g, () => {
        const argument = args[argumentIndex];
        argumentIndex += 1;
        if (argument === undefined) return '%s';
        return argument.toString();
    });
    const argsRest = args.slice(argumentIndex);

    if (warned[message]) return;

    if (typeof console !== 'undefined' && console.warn) {
        console.warn(`Warning: ${message}`, ...argsRest);
    }

    try {
        throw new Error(message);
    } catch (error) {
        warned[message] = true;
    }
}
