import { checkDOM } from 'utilsTS/dom';

export function getComputedStyleProperty(element: HTMLElement, property: string): string {
    const style = window.getComputedStyle(element, null);
    return style.getPropertyValue(property);
}

export function isBodyOverflowing(): boolean {
    /* istanbul ignore if */
    if (!checkDOM()) return false;

    const { style, scrollHeight } = document.body;

    if (getComputedStyleProperty(document.body, 'overflow-y') === 'scroll') return true;
    if (style.overflowY === 'scroll') return true;
    return scrollHeight > window.innerHeight;
}

export function toggleBodyClass(className: string, toggle: boolean, checkForSelector = ''): void {
    /* istanbul ignore if */
    if (!checkDOM()) return;

    const { classList } = document.body;
    const hasClassName = classList.contains(className);
    const elements = checkForSelector ? document.querySelectorAll(checkForSelector) : [];
    const visibleElements = Array.prototype.filter.call(elements, (element) => {
        const style = window.getComputedStyle(element);
        return style.display !== 'none';
    });
    if (visibleElements.length > 1) return;

    if (!toggle) {
        classList.remove(className);
    } else if (!hasClassName) {
        classList.add(className);
    }
}

export function getElementPadding(element: HTMLElement): number {
    /* istanbul ignore if */
    if (!checkDOM()) return 0;

    const style = window.getComputedStyle(element);
    const padding = style.getPropertyValue('padding-right');
    return Number.parseInt(padding, 10) || 0;
}

export function setElementPadding(element: HTMLElement, padding: number): void {
    /* istanbul ignore if */
    if (!checkDOM()) return;

    const { style } = element;
    const paddingCurrent = getElementPadding(element);
    const paddingNew = paddingCurrent + padding;
    style.paddingRight = `${paddingNew}px`;
}

export function setBodyPadding(padding: number): void {
    setElementPadding(document.body, padding);
}

export function setClassNamePadding(selector: string, padding: number): void {
    const elements = document.querySelectorAll(selector);
    Array.prototype.forEach.call(elements, (element) => {
        setElementPadding(element, padding);
    });
}

export function getScrollbarWidth(): number {
    /* istanbul ignore if */
    if (!checkDOM()) return 0;

    const scrollDiv = document.createElement('div');
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';

    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    scrollDiv.remove();

    return scrollbarWidth;
}
