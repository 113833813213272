/* eslint-disable unicorn/prefer-reflect-apply */

import PropTypes from 'prop-types';

export function requiredIf<T>(
    type: PropTypes.Requireable<T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    condition: (props: { [key: string]: any }) => boolean,
): PropTypes.Validator<T> {
    return (props, ...rest) => {
        const test = condition(props) ? type.isRequired : type;
        // @ts-expect-error something is wrong here and I can't figure out what
        return test.apply(this, [props, ...rest]);
    };
}
