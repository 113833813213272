import { uniq, sortBy } from 'lodash';

import iconsSource from 'gds-assets/gds-icons.svg';

const SVG_SYMBOL_REGEX = /<symbol(.|\n)*?<\/symbol>/g;

function getSortedIcons() {
    const matched = [...iconsSource.match(SVG_SYMBOL_REGEX)];

    return uniq(sortBy(matched));
}

function getSortedIconsSvgFile() {
    return `
<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    ${getSortedIcons().join('\n\n    ')}
</svg>`;
}

export const iconsSvg = getSortedIconsSvgFile();
