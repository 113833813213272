import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon } from 'components/Icon';
import { Avatar } from 'components/Image/Avatar';

import { ICON } from 'utilsTS/icon';

export interface FunctionCardImageProps {
    /** Defines the icon of the card */
    icon?: ICON | React.ReactElement;
    /** Defines the image of the card */
    imageUrl?: string;
    /** Accepts additional className passed to the Icon component */
    iconClassName?: string;
}

export interface FunctionCardImageStatics {
    ICON: typeof ICON;
}

export const FunctionCardImage: React.FC<FunctionCardImageProps> & FunctionCardImageStatics = ({
    icon,
    imageUrl,
    iconClassName,
}) => {
    if (imageUrl) return <Avatar imageUrl={imageUrl} size={Avatar.SIZE.SIZE_64} />;
    if (icon) {
        if (React.isValidElement(icon)) return icon;
        return (
            <Icon
                icon={icon as ICON}
                size={Icon.SIZE.SIZE_48}
                className={cx('g-card-function-icon', iconClassName)}
            />
        );
    }

    return null;
};

FunctionCardImage.ICON = Icon.ICON;

FunctionCardImage.displayName = 'FunctionCardImage';
FunctionCardImage.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.oneOf(Object.values(Icon.ICON)), PropTypes.element]),
    imageUrl: PropTypes.string,
    iconClassName: PropTypes.string,
};
