import React from 'react';
import info from 'gds-common/info.js';
import { checkDOM } from 'utilsTS/dom';

import { GLOBAL_INFO, GLOBAL_DEBUG } from './constants';
import { highlightComponents } from './highlightComponents';

interface DebugWindow {
    [key: string]: Record<string, string | undefined> | typeof highlightComponents;
}

export function setDebugInfo(): boolean {
    /* istanbul ignore if */
    if (!checkDOM()) return false;

    const debugWindow = window as unknown as DebugWindow;

    if (debugWindow[GLOBAL_INFO]) return false;

    debugWindow[GLOBAL_INFO] = {
        VERSION_GDS: info.version,
        VERSION_REACT: React.version,
        ENV: process.env.NODE_ENV,
    };

    debugWindow[GLOBAL_DEBUG] = highlightComponents;

    return true;
}
