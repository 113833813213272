import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { Card, CardProps } from 'components/Card';
import { Icon } from 'components/Icon';
import { LiveAnnouncer } from 'components/Utilities';

import { deprecate } from 'utilsTS/error';
import { omitProps } from 'utilsTS/react';
import { useDebounce } from 'utilsTS/hooks';

import { SnackbarItemContent, SnackbarItemTransition } from './components';
import { SnackbarItemContext } from './SnackbarItemContext';

import styles from './Snackbar.scss';

const AUTO_CLOSEABLE_TIME = 8000;

export enum SNACKBAR_ITEM_AUTO_CLOSEABLE {
    DEFAULT = 'default',
    ENABLED = 'enabled',
    DISABLED = 'disabled',
}

export enum SNACKBAR_ITEM_VARIANT {
    DEFAULT = 'default',
    ERROR = 'error',
    SUCCESS = 'success',
    BUSY = 'busy',
}

const SNACKBAR_ITEM_ICON = {
    [SNACKBAR_ITEM_VARIANT.DEFAULT]: undefined,
    [SNACKBAR_ITEM_VARIANT.ERROR]: Icon.ICON.ERROR_CIRCLE,
    [SNACKBAR_ITEM_VARIANT.SUCCESS]: Icon.ICON.CHECKMARK_CIRCLE,
    [SNACKBAR_ITEM_VARIANT.BUSY]: undefined,
};

export interface SnackbarItemProps extends CardProps {
    /** Defines the text that should be announced */
    message?: string;
    /** Defines visual variant of the snack */
    variant?: SNACKBAR_ITEM_VARIANT;
    /** Defines whether the snack should close automatically */
    autoCloseable?: SNACKBAR_ITEM_AUTO_CLOSEABLE;
    /** Accepts a Button element to be shown */
    button?: React.ReactElement;
    /** Defines a11y label of the close button */
    closeLabel?: string;
    /** Defines whether the snack should animate in */
    in?: boolean;
    /** Is called right after component switched to collapsed state but before transition */
    onBeforeClose?: () => void;
    /** Is called after component switched to collapsed state and finished transition */
    onClose?: () => void;
}

export interface SnackbarItemStatics {
    AUTO_CLOSEABLE: typeof SNACKBAR_ITEM_AUTO_CLOSEABLE;
    VARIANT: typeof SNACKBAR_ITEM_VARIANT;
}

export const SnackbarItem: React.FC<SnackbarItemProps> & SnackbarItemStatics = ({
    message = null,
    variant = SNACKBAR_ITEM_VARIANT.DEFAULT,
    autoCloseable = SNACKBAR_ITEM_AUTO_CLOSEABLE.DEFAULT,
    button,
    closeLabel,
    in: show = true,
    children,
    className,
    onBeforeClose = noop,
    onClose = noop,
    ...rest
}) => {
    deprecate(!message, 'SnackBarItem.message', 'Please provide SnackBarItem.message.');

    const [hovered, setHovered] = React.useState(false);
    const [closed, setClosed] = React.useState(false);

    const close = React.useCallback(() => {
        setClosed(true);
        onBeforeClose();
    }, [onBeforeClose]);

    const autoCloseDebounced = useDebounce(close, AUTO_CLOSEABLE_TIME);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    React.useEffect(() => () => autoCloseDebounced.cancel(), [autoCloseDebounced]);

    React.useEffect(() => {
        const isAutoCloseable = (autoClose = autoCloseable) => {
            if (hovered) return false;
            if (autoClose === SNACKBAR_ITEM_AUTO_CLOSEABLE.ENABLED) return true;
            if (autoClose === SNACKBAR_ITEM_AUTO_CLOSEABLE.DISABLED) return false;
            if ([SNACKBAR_ITEM_VARIANT.ERROR, SNACKBAR_ITEM_VARIANT.BUSY].includes(variant))
                return false;
            return true;
        };

        if (!closed && !hovered && isAutoCloseable(autoCloseable)) {
            autoCloseDebounced();
        }
        if (closed || hovered) {
            autoCloseDebounced.cancel();
        }
    }, [variant, closed, hovered, autoCloseable, autoCloseDebounced]);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const icon = SNACKBAR_ITEM_ICON[variant];

    const isBusy = variant === SNACKBAR_ITEM_VARIANT.BUSY;

    const classNames = cx(
        styles.item,
        styles[variant],
        icon && styles.hasIcon,
        isBusy && styles.isBusy,
        className,
    );

    const omitted = ['appear', 'enter', 'exit', 'onExited'];

    const context = React.useMemo(
        () => ({
            close,
        }),
        [close],
    );

    const getNodeText = (node: React.ReactNode): string => {
        if (node === null) return '';
        switch (typeof node) {
            case 'string': {
                return node;
            }
            case 'number': {
                return node.toString();
            }
            case 'object': {
                if (Array.isArray(node)) return node.map(getNodeText).join('');
                if ('props' in node) return getNodeText(node.props.children);
            } // eslint-ignore-line no-fallthrough
            default: {
                return '';
            }
        }
    };

    return (
        <SnackbarItemContext.Provider value={context}>
            <LiveAnnouncer message={message === null ? getNodeText(children) : message} />
            <SnackbarItemTransition show={show && !closed} onExited={onClose}>
                <Card
                    className={classNames}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    {...omitProps(rest, omitted)}
                >
                    <SnackbarItemContent
                        icon={icon}
                        button={button}
                        spinner={isBusy}
                        closeLabel={closeLabel}
                        onRequestClose={close}
                    >
                        {children}
                    </SnackbarItemContent>
                </Card>
            </SnackbarItemTransition>
        </SnackbarItemContext.Provider>
    );
};

SnackbarItem.AUTO_CLOSEABLE = SNACKBAR_ITEM_AUTO_CLOSEABLE;
SnackbarItem.VARIANT = SNACKBAR_ITEM_VARIANT;

SnackbarItem.displayName = 'SnackbarItem';
SnackbarItem.propTypes = {
    message: PropTypes.string,
    in: PropTypes.bool,
    variant: PropTypes.oneOf(Object.values(SNACKBAR_ITEM_VARIANT)),
    autoCloseable: PropTypes.oneOf(Object.values(SNACKBAR_ITEM_AUTO_CLOSEABLE)),
    button: PropTypes.element,
    closeLabel: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onBeforeClose: PropTypes.func,
    onClose: PropTypes.func,
};
