import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Transition, TransitionProps } from './Transition';

import styles from './TransitionFade.scss';

export interface TransitionFadeProps extends Omit<TransitionProps, 'transition' | 'timeout'> {
    /** Defines whether the animation should play quickly */
    quick?: boolean;
}

export const TransitionFade: React.FC<TransitionFadeProps> = ({ quick, className, ...rest }) => (
    <Transition
        transition={styles}
        className={cx(styles.container, className, quick && styles.quick)}
        timeout={
            quick
                ? styles.timeoutQuick
                : {
                      appear: styles.timeoutAppear,
                      enter: styles.timeout,
                      exit: styles.timeout,
                  }
        }
        {...rest}
    />
);

TransitionFade.displayName = 'TransitionFade';
TransitionFade.propTypes = {
    show: PropTypes.bool,
    quick: PropTypes.bool,
    appear: PropTypes.bool,
    className: PropTypes.string,
};
