/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { Context } from 'internal/Provider';
import { omitProps } from 'utilsTS/react';
import { useBrowser } from 'utilsTS/hooks';

export const KEYBOARD_DETECTOR_CLASS_NAME = 'g-uses-keyboard';

/** @deprecated rely on CSS `:focus-visible` instead */
export interface KeyboardDetectorProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Is called on every trigger event */
    onTrigger?: (argument: { usesKeyboard: boolean; documentNode: Document }) => void;
}

/** @deprecated rely on CSS `:focus-visible` instead */
export const KeyboardDetector: React.FC<KeyboardDetectorProps> = ({
    onTrigger = noop,
    className,
    children,
    ...rest
}) => {
    const { document } = useBrowser(React.useContext(Context));
    const [usesKeyboard, setUsesKeyboard] = React.useState(false);

    const handleEvent = React.useCallback(
        (value: boolean) => {
            if (usesKeyboard !== value) {
                setUsesKeyboard(value);
                onTrigger({ usesKeyboard: value, documentNode: document });
            }
        },
        [document, onTrigger, usesKeyboard],
    );

    React.useEffect(() => {
        if (!document) {
            return () => {};
        }

        const handleClick = () => handleEvent(false);
        const handleKey = () => handleEvent(true);

        document.addEventListener('keydown', handleKey, {
            // use capture phase to prevent events not being triggered when event.preventDefault()
            // is called in target https://javascript.info/bubbling-and-capturing#capturing
            capture: true,
            passive: true,
        });
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('touchstart', handleClick);

        return () => {
            document.removeEventListener('keydown', handleKey, {
                capture: true,
            });
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchstart', handleClick);
        };
    }, [document, handleEvent]);

    if (!children) {
        return null;
    }

    const classNames = cx(className, usesKeyboard && KEYBOARD_DETECTOR_CLASS_NAME);

    return (
        <div {...omitProps(rest, KeyboardDetector)} className={classNames}>
            {children}
        </div>
    );
};

KeyboardDetector.displayName = 'KeyboardDetector';

KeyboardDetector.propTypes = {
    onTrigger: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
};
