import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components/Icon';

import { COLOR } from 'utilsTS/color';
import { ICON } from 'utilsTS/icon';

import { AVATAR_HOVER_ICON } from '../AvatarEnums';
import { AvatarContext } from '../AvatarContext';

export interface AvatarShrunkIconProps {
    color?: COLOR;
    shrinkIcon: ICON | AVATAR_HOVER_ICON;
    shrinkFactor: number;
    className?: string;
}

export const AvatarShrunkIcon: React.FC<AvatarShrunkIconProps> = ({
    color,
    shrinkIcon,
    shrinkFactor,
    className,
}) => {
    const { size } = React.useContext(AvatarContext);

    const SIZE_DETAILED_THRESHOLD = 41;
    const shouldUseDetailedIcon =
        Number.parseInt(size || '', 10) * shrinkFactor >= SIZE_DETAILED_THRESHOLD;

    return (
        <Icon
            icon={shrinkIcon as ICON}
            color={color}
            className={className}
            useDetailed={shouldUseDetailedIcon}
        />
    );
};

AvatarShrunkIcon.displayName = 'AvatarShrunkIcon';
AvatarShrunkIcon.propTypes = {
    color: PropTypes.oneOf(Object.values(COLOR)),
    shrinkIcon: PropTypes.oneOf(Object.values(ICON)).isRequired,
    shrinkFactor: PropTypes.number.isRequired,
    className: PropTypes.string,
};
