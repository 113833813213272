import type { Locale } from 'date-fns';
import { createContext } from 'utilsTS/react';

import { getStringFallback } from './getStringFallback';
import { getFormatStringFallback } from './getFormatStringFallback';
import { type LanguageGetString, type LanguageGetFormatString } from './types';

export interface LanguageContextTypes {
    lang: string;
    locale?: Locale;
    timeZone?: string;
    getString: LanguageGetString;
    getFormatString: LanguageGetFormatString;
}

export const LanguageContext = createContext<LanguageContextTypes>('Language', {
    lang: 'en',
    getString: getStringFallback,
    getFormatString: getFormatStringFallback,
});
