import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { omitProps } from 'utilsTS/react';
import { generateId } from 'utilsTS/string';
import {
    useToggleable,
    toggleablePropTypes,
    ToggleableProps,
    ToggleableContext,
} from 'utilsTS/hooks';

export interface CollapsibleProps extends React.ComponentPropsWithoutRef<'div'>, ToggleableProps {
    /** Accepts a component replacing the wrapper */
    component?: React.ElementType;

    // TODO: replace this with componentProps prop
    [index: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const Collapsible: React.FC<CollapsibleProps> = ({
    component: ContainerComponent,
    className,
    children,

    isOpen = false,
    onToggle = noop,
    onBeforeOpen = noop,
    onOpen = noop,
    onBeforeClose = noop,
    onClose = noop,

    ...rest
}) => {
    const [id] = React.useState(generateId('collapsible'));
    const [isToggled, { toggle, open, close }] = useToggleable(false, isOpen, {
        onToggle,
        onBeforeOpen,
        onBeforeClose,
    });

    const renderChildren = () => {
        if (!ContainerComponent) return children;

        return (
            <ContainerComponent {...omitProps(rest, Collapsible)} id={id} className={className}>
                {children}
            </ContainerComponent>
        );
    };

    const context = React.useMemo(
        () => ({
            id,
            isOpen: isToggled,
            toggle,
            open,
            close,
            onToggle,
            onBeforeOpen,
            onOpen,
            onBeforeClose,
            onClose,
        }),
        [
            close,
            id,
            isToggled,
            onBeforeClose,
            onBeforeOpen,
            onClose,
            onOpen,
            onToggle,
            open,
            toggle,
        ],
    );

    return (
        <ToggleableContext.Provider value={context}>{renderChildren()}</ToggleableContext.Provider>
    );
};

Collapsible.displayName = 'Collapsible';
Collapsible.propTypes = {
    ...toggleablePropTypes,
    component: PropTypes.elementType as React.Validator<React.ElementType>,
    className: PropTypes.string,
    children: PropTypes.node,
};
