import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'containers/ThemeProvider';
import { Grid, GridProps } from 'components/Layout';

import { StripeContext } from '../StripeContext';

import { StripeHeaderLeft } from './StripeHeaderLeft';
import styles from './StripeHeaderLargeGrid.scss';

export interface StripeHeaderLargeGridProps extends GridProps {
    /** Defines whether the left slot should be shown */
    showLeft?: boolean;
}

export const StripeHeaderLargeGrid: React.FC<StripeHeaderLargeGridProps> = ({
    showLeft,
    children,
    ...rest
}) => {
    const { useFluidGrid } = useTheme();
    const { fullWidth } = React.useContext(StripeContext);

    return (
        <Grid {...rest} fluid={fullWidth || useFluidGrid} className={styles.largeGrid}>
            {showLeft && (
                <div className={styles.left}>
                    <StripeHeaderLeft />
                </div>
            )}
            {children && <div className={styles.children}>{children}</div>}
        </Grid>
    );
};

StripeHeaderLargeGrid.displayName = 'StripeHeaderLargeGrid';
StripeHeaderLargeGrid.propTypes = {
    showLeft: PropTypes.bool,
    children: PropTypes.node,
};
