import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { LanguageContext } from 'containers/LanguageProvider';

import { NewButton as Button } from 'components/Button/NewButton';
import { Icon } from 'components/Icon';
import { Paragraph } from 'components/Typography';
import { Spinner } from 'components/Spinner';

import { ICON } from 'utilsTS/icon';

import styles from '../Snackbar.scss';

export interface SnackbarItemContentProps {
    children: React.ReactNode;

    icon?: ICON;
    button?: React.ReactElement;
    closeLabel?: string;
    spinner?: boolean;
    onRequestClose: () => void;
}

export const SnackbarItemContent: React.FC<SnackbarItemContentProps> = ({
    icon,
    button,
    closeLabel,
    children,
    spinner = false,
    onRequestClose,
}) => {
    const { getString } = React.useContext(LanguageContext);

    return (
        <>
            <div className={styles.content}>
                {icon && <Icon icon={icon} size={Icon.SIZE.SIZE_24} className={styles.icon} />}
                {spinner && (
                    <Spinner ornamental className={styles.spinner} size={Spinner.SIZE.SMALL} />
                )}
                <Paragraph className={styles.text}>{children}</Paragraph>
                <Button
                    hiddenLabel={Button.HIDDEN_LABEL.ALWAYS}
                    fullWidth={Button.FULL_WIDTH.NEVER}
                    variant={Button.VARIANT.TERTIARY}
                    size={Button.SIZE.SMALL}
                    icon={Button.ICON.ERROR}
                    className={cx('g-inverted', styles.close)}
                    onClick={onRequestClose}
                >
                    {getString('common.close', closeLabel)}
                </Button>
            </div>
            {button && React.Children.only(button)}
        </>
    );
};

SnackbarItemContent.displayName = 'SnackbarItemContent';
SnackbarItemContent.propTypes = {
    icon: PropTypes.oneOf(Object.values(Icon.ICON)),
    button: PropTypes.element,
    closeLabel: PropTypes.string,
    spinner: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onRequestClose: PropTypes.func.isRequired,
};
