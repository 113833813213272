import { noop } from 'lodash';
import getChildren from './getChildren';

const DEFAULT_DEPTH = 2;

export default function findElement(element, predicate = noop, options = {}) {
    const { onlySingleChildren = true, maxDepth = DEFAULT_DEPTH } = options;
    const nodes = Array.isArray(element) ? element : [element];

    return nodes.reduce((result, node, index) => {
        if (result) {
            return result;
        }

        if (predicate(node, index)) {
            return node;
        }

        const children = getChildren(node);
        const hasNoChildren = children.length <= 0;
        const hasSingleChild = children.length === 1;
        const canDiveChildren = hasSingleChild || !onlySingleChildren;
        const canDiveDeeper = maxDepth > 0;

        if (hasNoChildren || !canDiveDeeper || !canDiveChildren) {
            return undefined;
        }

        return findElement(children, predicate, {
            ...options,
            maxDepth: maxDepth - 1,
        });
    }, undefined);
}
