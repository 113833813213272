import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { NewButton as Button } from 'components/Button/NewButton';
import { SecondaryNavigation } from 'components/Navigation/SecondaryNavigation';
import { TransitionCollapseHeight } from 'components/Transition';
import { MatchMedia, ClickOutside } from 'components/Utilities';

import { LanguageContext } from 'containers/LanguageProvider';

import { generateId } from 'utilsTS/string';
import { omitProps, pickProps } from 'utilsTS/react';
import { findByIndexOrProperty } from 'utilsTS/array';
import { useToggleable, ToggleableProps, toggleablePropTypes } from 'utilsTS/hooks';
import { elementOfType } from 'utilsTS/prop-types';

import { StripeTitle } from './StripeTitle';

import styles from './StripeNavigation.scss';

const itemPropTypes = {
    id: PropTypes.string,
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    pill: PropTypes.number,
    onClick: PropTypes.func,
};

export interface StripeNavigationItemProps {
    id?: string;
    href: string;
    label: string;
    pill?: number;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export interface StripeNavigationProps
    extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>,
        ToggleableProps {
    /** Defines default title to be shown in case no item is selected */
    title: React.ReactNode;
    /** Accepts item objects to be shown */
    items: StripeNavigationItemProps[];
    /** Defines the selected item */
    selectedItem: string | number | StripeNavigationItemProps;
    /** Defines whether the navigation dropdown should close on selecting an item */
    closeOnClick?: boolean;
    /** Defines custom accessible label for toggle */
    toggleAriaLabel?: string;
}

export const StripeNavigation: React.FC<StripeNavigationProps> = (props) => {
    const { title, items, selectedItem, closeOnClick, isOpen, onBeforeOpen, onBeforeClose } = props;

    const [isToggled, { toggle, close }] = useToggleable(false, isOpen, {
        onBeforeOpen,
        onBeforeClose,
    });
    const [selectedItemSTATE, setSelectedItemSTATE] = React.useState<StripeNavigationItemProps>();
    const navigationId = generateId('nav');

    React.useEffect(() => {
        setSelectedItemSTATE(findByIndexOrProperty(items, selectedItem, 'href'));
    }, [items, selectedItem]);

    const { getString } = React.useContext(LanguageContext);

    const getTitle = () => {
        if (title) return title;
        if (!selectedItemSTATE) return '';

        return selectedItemSTATE.label;
    };

    const handleRequestClose = () => {
        if (closeOnClick) {
            close();
        }
    };

    const renderTitle = ({ overline }: { overline: boolean }) => {
        const dynamicTitle = getTitle();

        if (typeof dynamicTitle !== 'string') return dynamicTitle;

        const moreOmit = overline ? ['aria-label'] : ['aria-label', 'overline'];
        return (
            <StripeTitle {...omitProps(props, StripeNavigation, moreOmit)}>
                {dynamicTitle}
            </StripeTitle>
        );
    };

    const renderToggle = () => {
        const { toggleAriaLabel } = props;

        return (
            <Button
                variant={Button.VARIANT.TERTIARY}
                fullWidth={Button.FULL_WIDTH.NEVER}
                size={Button.SIZE.SMALL}
                hiddenLabel={Button.HIDDEN_LABEL.ALWAYS}
                className={styles.toggle}
                labelClassName={cx(styles.label, 'sr-only')}
                aria-controls={navigationId}
                aria-expanded={isToggled}
                onClick={toggle}
            >
                {getString('common.menu', toggleAriaLabel)}
            </Button>
        );
    };

    const renderNavigation = () => {
        const { items: itemsProps, selectedItem: selectedItemProps, ...rest } = props;

        return (
            <SecondaryNavigation
                {...pickProps(rest, SecondaryNavigation, ['aria-label'])}
                id={navigationId}
                items={itemsProps}
                selectedItem={selectedItemProps}
                stickyOffset="var(--stripe-height)"
                className={styles.navigation}
                listClassName={styles.list}
                itemClassName={styles.item}
                linkClassName={styles.link}
                onItemClick={handleRequestClose}
            />
        );
    };
    const renderDropdown = () => {
        const { onOpen, onClose } = props;

        return (
            <TransitionCollapseHeight show={isToggled} onEntered={onOpen} onExited={onClose}>
                {renderNavigation()}
            </TransitionCollapseHeight>
        );
    };

    const renderChildren = ({ isMatching }: { isMatching: boolean }) => {
        const { className, ...rest } = props;

        if (isMatching) return renderTitle({ overline: true });

        const classNames = cx(styles.container, isToggled && styles.open, className);

        return (
            <ClickOutside
                {...omitProps(rest, StripeNavigation, ['aria-label'])}
                active={isToggled}
                className={classNames}
                onTrigger={close}
            >
                <div className={styles.mobile}>
                    {renderTitle({ overline: false })}
                    {renderToggle()}
                </div>
                {renderDropdown()}
            </ClickOutside>
        );
    };

    return <MatchMedia min={MatchMedia.TIER.LG}>{renderChildren}</MatchMedia>;
};

StripeNavigation.displayName = 'StripeNavigation';
StripeNavigation.propTypes = {
    ...toggleablePropTypes,
    title: PropTypes.oneOfType([PropTypes.string, elementOfType([StripeTitle])]),
    // @ts-expect-error classic PropTypes problem
    items: PropTypes.arrayOf(PropTypes.shape(itemPropTypes).isRequired).isRequired,
    // @ts-expect-error classic PropTypes problem
    selectedItem: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape(itemPropTypes),
    ]).isRequired,
    closeOnClick: PropTypes.bool,
    toggleAriaLabel: PropTypes.string,
};
