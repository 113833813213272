import React from 'react';
import PropTypes from 'prop-types';

import { Transition } from 'components/Transition';

import { useWindowScroll } from 'utilsTS/hooks';

import styles from './StripeHeaderStickyContainer.scss';

const SCROLL_THRESHOLD = 10;

export interface StripeHeaderStickyContainerProps {
    children: React.ReactNode;

    /** Defines the offset from the top of the viewport */
    offset?: number | string;
}

export const StripeHeaderStickyContainer: React.FC<StripeHeaderStickyContainerProps> = ({
    offset = 0,
    children,
}) => {
    const { scrollY, deltaY } = useWindowScroll();
    const style = offset ? { marginBottom: offset } : undefined;
    const showSticky = deltaY < 0 || scrollY <= SCROLL_THRESHOLD;

    return (
        <Transition show={showSticky} transition={styles} timeout={styles.timeout}>
            <div className={styles.sticky} style={style}>
                {children}
            </div>
        </Transition>
    );
};

StripeHeaderStickyContainer.displayName = 'StripeHeaderStickyContainer';
StripeHeaderStickyContainer.propTypes = {
    offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children: PropTypes.node.isRequired,
};
