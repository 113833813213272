import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { SplitButton, SplitButtonProps } from 'components/Dropdown/SplitButton';

import { StripeContext } from './StripeContext';
import { StripeHeaderStickyContainer } from './components';

import styles from './StripeActionSplitButton.scss';

export interface StripeActionSplitButtonProps extends SplitButtonProps {
    /** Defines whether the StripeActionButton should be sticky by the bottom of the viewport */
    sticky?: boolean;
    /** Defines the offset from the bottom of the viewport */
    stickyOffset?: string | number;
}

export const StripeActionSplitButton: React.FC<StripeActionSplitButtonProps> = ({
    sticky = true,
    stickyOffset,
    className,
    button,
    dropdown,
    ...rest
}) => {
    const { isMinMediumTier } = React.useContext(StripeContext);

    if (sticky && !isMinMediumTier) {
        return (
            <StripeHeaderStickyContainer offset={stickyOffset}>
                <SplitButton
                    {...rest}
                    button={button}
                    dropdown={dropdown}
                    className={cx(
                        'g-no-inverted',
                        styles.stripeAction,
                        styles.stickyButton,
                        className,
                    )}
                />
            </StripeHeaderStickyContainer>
        );
    }

    if (isMinMediumTier) {
        return (
            <SplitButton
                {...rest}
                button={button}
                dropdown={dropdown}
                className={cx(styles.stripeAction, className)}
            />
        );
    }

    return null;
};

StripeActionSplitButton.displayName = 'StripeActionSplitButton';
StripeActionSplitButton.propTypes = {
    ...SplitButton.propTypes,
    sticky: PropTypes.bool,
    stickyOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
