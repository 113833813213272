import React from 'react';
import PropTypes from 'prop-types';

import { Context } from 'internal/Provider';

import { eventHasKey, KEY } from 'utilsTS/dom';
import { useBrowser, useLatest } from 'utilsTS/hooks';
import { omitProps } from 'utilsTS/react';

export interface ClickOutsideProps extends React.ComponentPropsWithoutRef<'div'> {
    children: React.ReactNode;

    /** Accepts a tag or a component replacing the wrapper */
    tag?: React.ElementType;
    /** Defines whether the functionality is activated */
    active?: boolean;
    /** Defines whether an ESC keyboard event should trigger */
    triggerOnEsc?: boolean;
    /** Defines whether a click event should trigger */
    triggerOnClick?: boolean;
    /** Is called on every keyboard/click event */
    onTrigger: (event: MouseEvent | KeyboardEvent, isClick: boolean) => void;
}

export const ClickOutside: React.FC<ClickOutsideProps> = ({
    tag: Tag = 'div',
    active = true,
    triggerOnEsc = true,
    triggerOnClick = true,
    onTrigger,
    children,
    ...rest
}) => {
    const ref = React.useRef<HTMLElement>();
    const activeRef = useLatest(active);
    const triggerOnEscRef = useLatest(triggerOnEsc);
    const triggerOnClickRef = useLatest(triggerOnClick);
    const { document } = useBrowser(React.useContext(Context));

    React.useEffect(() => {
        if (!document) {
            return () => {};
        }

        const handleClick = (event: MouseEvent) => {
            const { target, type } = event;
            const isClick = type === 'mousedown';

            if (!triggerOnClickRef.current) return;
            if (!activeRef.current) return;
            if (!ref.current) return;
            if (ref.current.contains(target as Node)) return;

            onTrigger(event, isClick);
        };

        const handleKeyDown = (event: KeyboardEvent) => {
            if (!triggerOnEscRef.current) return;
            if (!activeRef.current) return;

            if (eventHasKey(event, KEY.ESC)) {
                onTrigger(event, false);
            }
        };

        document.addEventListener('mousedown', handleClick);
        document.addEventListener('keydown', handleKeyDown, {
            // use capture phase to prevent events not being triggered when event.preventDefault()
            // is called in target https://javascript.info/bubbling-and-capturing#capturing
            capture: true,
            passive: true,
        });

        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('keydown', handleKeyDown, {
                capture: true,
            });
        };
        // the listeners need to be rebound on prop changes because of variable scoping in their handlers
    }, [activeRef, document, onTrigger, triggerOnClickRef, triggerOnEscRef]);

    return (
        <Tag {...omitProps(rest, ClickOutside)} ref={ref}>
            {children}
        </Tag>
    );
};

ClickOutside.displayName = 'ClickOutside';
ClickOutside.propTypes = {
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
    active: PropTypes.bool,
    triggerOnEsc: PropTypes.bool,
    triggerOnClick: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onTrigger: PropTypes.func.isRequired,
};
