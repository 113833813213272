import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/Layout';

import { omitProps } from 'utilsTS/react';

import styles from './Heading.scss';

export interface HeadingProps
    extends React.ComponentPropsWithoutRef<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'> {
    /** Defines which heading element to render */
    level: 1 | 2 | 3 | 4 | 5 | 6;
    /** Defines whether heading is the very first on page */
    first?: boolean;
    /** Accepts ButtonGroup component to be rendered */
    buttonGroup?: React.ReactNode;
    /**
     * Defines whether a line should be rendered below heading
     * @deprecated can safely be removed, as it has no effect anymore
     */
    noLine?: boolean; // DEPRECATED
}

export const Heading: React.FC<HeadingProps> = ({
    level,
    first,
    buttonGroup,
    className,
    children,
    ...rest
}) => {
    const heading = (
        <Text
            {...omitProps(rest, ['noLine'])}
            always
            tag={`h${level}`}
            className={cx({ 'g-first': first }, className)}
        >
            {children}
        </Text>
    );

    if (buttonGroup) {
        return (
            <div className={styles.container}>
                {heading}
                {buttonGroup}
            </div>
        );
    }

    return heading;
};

Heading.displayName = 'Heading';
Heading.propTypes = {
    level: PropTypes.oneOf<HeadingProps['level']>([1, 2, 3, 4, 5, 6]).isRequired,
    first: PropTypes.bool,
    buttonGroup: PropTypes.node,
    noLine: PropTypes.bool, // DEPRECATED
    className: PropTypes.string,
    children: PropTypes.node,
};
