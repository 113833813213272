import React from 'react';
import type { WrapperFC } from 'types/custom';

export function getDisplayName(
    elementOrComponent:
        | string
        | RegExp
        | React.ReactNode
        | React.ComponentType
        | React.ForwardRefExoticComponent<React.RefAttributes<unknown>>,
    { clean = true } = {},
): string {
    const element = elementOrComponent;
    const type = React.isValidElement(element) ? element.type : element;

    if (typeof type === 'string') return type;

    const { displayName, name, wrapsComponent } = (type as WrapperFC) || {};

    if (wrapsComponent) return getDisplayName(wrapsComponent);

    if (displayName) {
        if (!clean) return displayName;
        return displayName.replace(/^(.*?\()?(.*?)(\))?$/, '$2');
    }

    return name || 'Component';
}
