import React from 'react';
import { noop } from 'lodash';

import { getChildren } from './getChildren';

export function findElement(
    element: React.ReactNode,
    predicate: (node: React.ReactNode, index: number) => boolean | void = noop,
    { onlySingleChildren = true, maxDepth = 2 } = {},
): React.ReactNode | undefined {
    const nodes = Array.isArray(element) ? element : [element];

    return nodes.reduce((previousValue, currentValue, index) => {
        if (previousValue) return previousValue;
        if (predicate(currentValue, index)) return currentValue;

        const children = getChildren(currentValue);
        const hasNoChildren = children.length <= 0;
        const hasSingleChild = children.length === 1;
        const canDiveChildren = hasSingleChild || !onlySingleChildren;
        const canDiveDeeper = maxDepth > 0;

        if (hasNoChildren || !canDiveDeeper || !canDiveChildren) return undefined;

        return findElement(children, predicate, {
            onlySingleChildren,
            maxDepth: maxDepth - 1,
        });
    }, undefined);
}
