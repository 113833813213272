/* eslint-disable @typescript-eslint/no-explicit-any */

import { omit } from 'lodash';
import { getPropKeys, keysOrComponent } from './getPropKeys';

export function omitProps(
    props: Record<string, any>,
    ...keysOrComponents: keysOrComponent[]
): Record<string, any> {
    const keys = getPropKeys(...keysOrComponents);
    if (keys.length === 0) return props;
    return omit(props, keys);
}
