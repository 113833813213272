import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export type CardBodyProps = React.ComponentPropsWithoutRef<'div'>;

export const CardBody: React.FC<CardBodyProps> = ({ className, children, ...rest }) => (
    <div
        className={cx(
            'card-body', // DEPRECATED
            'g-card-body',
            className,
        )}
        {...rest}
    >
        {children}
    </div>
);

CardBody.displayName = 'CardBody';
CardBody.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
