/* eslint-disable unicorn/prefer-reflect-apply */

import { isEmpty } from 'lodash';
import { warning } from 'utils/error';

export default function requiredIf(type, condition) {
    if (typeof type !== 'function' || !type.isRequired) {
        warning(
            true,
            'Invalid argument `type` supplied to requiredIf, expected a `PropType function`.',
        );
    }
    if (typeof condition !== 'string' && typeof condition !== 'function') {
        warning(
            true,
            'Invalid argument `condition` supplied to requiredIf, expected an string or function.',
        );
    }

    const isRequired = (props) => {
        if (typeof condition === 'function') {
            return condition(props);
        }
        return !isEmpty(props[condition]);
    };

    return (props, ...rest) => {
        const args = [props, ...rest];
        if (isRequired(props)) {
            return type.isRequired.apply(this, args);
        }
        return type.apply(this, args);
    };
}
