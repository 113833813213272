import React from 'react';

import { TIER } from 'utilsTS/dom';
import { createContext } from 'utilsTS/react';

export interface StripeContextTypes {
    left?: React.ReactNode;
    center?: React.ReactNode;
    right?: React.ReactNode;
    toolbar?: React.ReactNode;
    fullWidth?: boolean;
    isLargeVariant?: boolean;
    isTransparentVariant?: boolean;
    useTransition?: boolean;
    isMinMediumTier?: boolean;
    isMinLargeTier?: boolean;
    matchingTier?: TIER;
}

export const StripeContext = createContext<StripeContextTypes>('Stripe', {
    left: null,
    center: null,
    right: null,
    toolbar: null,
    fullWidth: false,
    isLargeVariant: false,
    isTransparentVariant: false,
    isMinMediumTier: false,
    isMinLargeTier: false,
    useTransition: false,
    matchingTier: TIER.LG,
});

export const StripeProvider: React.FC<StripeContextTypes & React.PropsWithChildren> = ({
    left,
    center,
    right,
    toolbar,
    fullWidth,
    isLargeVariant,
    isTransparentVariant,
    isMinMediumTier,
    isMinLargeTier,
    useTransition,
    matchingTier,
    children,
}) => {
    const context = React.useMemo(
        () => ({
            left,
            center,
            right,
            toolbar,
            fullWidth,
            isLargeVariant,
            isTransparentVariant,
            isMinMediumTier,
            isMinLargeTier,
            useTransition,
            matchingTier,
        }),
        [
            left,
            center,
            right,
            toolbar,
            fullWidth,
            isLargeVariant,
            isTransparentVariant,
            isMinMediumTier,
            isMinLargeTier,
            useTransition,
            matchingTier,
        ],
    );
    return <StripeContext.Provider value={context}>{children}</StripeContext.Provider>;
};
StripeProvider.displayName = 'StripeProvider';
