import React from 'react';
import PropTypes from 'prop-types';
import { CardBody } from 'components/Card';

import { ButtonGroup } from 'components/Button/ButtonGroup';
import { Text } from 'components/Layout';

export interface FunctionCardButtonsProps {
    /** Defines text shown below the buttons */
    buttonsText?: React.ReactNode;
    /** Accepts buttons to be shown */
    buttons?: React.ReactNode[];
}

export const FunctionCardButtons: React.FC<FunctionCardButtonsProps> = ({
    buttons = [],
    buttonsText,
}) => {
    const hasButtons = buttons.length > 0;

    if (!hasButtons && !buttonsText) return null;

    return (
        <CardBody className="g-card-function-postscript">
            <Text>{buttonsText}</Text>
            {hasButtons ? (
                <ButtonGroup className="g-card-function-buttons">{buttons}</ButtonGroup>
            ) : null}
        </CardBody>
    );
};

FunctionCardButtons.displayName = 'FunctionCardButtons';
FunctionCardButtons.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.node),
    buttonsText: PropTypes.node,
};
