import React, { Children } from 'react';
import { cloneElement } from './cloneElement';

export function cloneChildren(
    children: React.ReactNode = null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...props: Record<string, any>[]
): React.ReactNode {
    return Children.map(children, (child) => cloneElement(child, ...props));
}
