import PropTypes from 'prop-types';

export default function ref() {
    return PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            // eslint-disable-next-line react/forbid-prop-types
            current: PropTypes.object,
        }),
    ]);
}
