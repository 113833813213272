module.exports = {
    common: [
        { name: 'black', value: '#000000', valueDark: '#000000' },
        { name: 'white', value: '#ffffff', valueDark: '#ffffff' },
        { name: 'transparent', value: 'transparent', valueDark: 'transparent' },
    ],
    group: [
        { name: 'george', value: '#21416c', valueDark: undefined, deprecated: true },
        { name: 'ocean', value: '#1d69ec', valueDark: undefined, deprecated: true },
        { name: 'sky', value: '#e7f3fb', valueDark: undefined, deprecated: true },
        { name: 'cloud', value: '#e7f3fb', valueDark: undefined, deprecated: true },
        { name: 'grey-light', value: '#f4f6fa', valueDark: undefined, deprecated: true },
        { name: 'grey', value: '#f4f6fa', valueDark: undefined, deprecated: true },
        { name: 'grey-dark', value: '#e4eaf0', valueDark: undefined, deprecated: true },
    ],
    named: [
        { name: 'honey', value: '#ffd32a', valueDark: '#b27700' },
        { name: 'honey-weak', value: '#fffaeb', valueDark: '#332000', hidden: true },
        { name: 'honey-strong', value: '#995900', valueDark: '#ffcf80', hidden: true },
        { name: 'carrot', value: '#ff6130', valueDark: '#7a3300' },
        { name: 'carrot-weak', value: '#fff7f5', valueDark: '#521d0b', hidden: true },
        { name: 'carrot-strong', value: '#b14321', valueDark: '#de99b3', hidden: true },
        { name: 'berry', value: '#e1015b', valueDark: '#66052d' },
        { name: 'berry-weak', value: '#fdf2f7', valueDark: '#45001a', hidden: true },
        { name: 'berry-strong', value: '#b40048', valueDark: '#de99b3', hidden: true },
        { name: 'rose', value: '#eb4c79', valueDark: '#721d43' },
        { name: 'rose-weak', value: '#fef6f8', valueDark: '#471b2b', hidden: true },
        { name: 'rose-strong', value: '#ca2f5b', valueDark: '#dca9bc', hidden: true },
        { name: 'lavender', value: '#aa4bb3', valueDark: '#5f3663' },
        { name: 'lavender-weak', value: '#fbf6fb', valueDark: '#361839', hidden: true },
        { name: 'lavender-strong', value: '#9a44a2', valueDark: '#b78abb', hidden: true },
        { name: 'lila', value: '#721c7a', valueDark: '#4d2a6a' },
        { name: 'lila-weak', value: '#f7f3f8', valueDark: '#3e0f42', hidden: true },
        { name: 'lila-strong', value: '#721c7a', valueDark: '#b8a0ba', hidden: true },
        { name: 'indigo', value: '#2f2d91', valueDark: '#2e306b' },
        { name: 'indigo-weak', value: '#f5f5f9', valueDark: '#13113d', hidden: true },
        { name: 'indigo-strong', value: '#2c2a88', valueDark: '#a5a4c2', hidden: true },
        { name: 'pool', value: '#199cf9', valueDark: '#194666' },
        { name: 'pool-weak', value: '#f3faff', valueDark: '#082348', hidden: true },
        { name: 'pool-strong', value: '#0f5d95', valueDark: '#739bd3', hidden: true },
        { name: 'teal', value: '#02a3a4', valueDark: '#144748' },
        { name: 'teal-weak', value: '#f2fafa', valueDark: '#002d2e', hidden: true },
        { name: 'teal-strong', value: '#017273', valueDark: '#66abac', hidden: true },
        { name: 'lime', value: '#0cb43f', valueDark: '#305f3e' },
        { name: 'lime-weak', value: '#f3fbf5', valueDark: '#032f10', hidden: true },
        { name: 'lime-strong', value: '#08802c', valueDark: '#6aae7f', hidden: true },
        { name: 'forest', value: '#028661', valueDark: '#003a29' },
        { name: 'forest-weak', value: '#f2f9f7', valueDark: '#00261b', hidden: true },
        { name: 'forest-strong', value: '#027455', valueDark: '#66a08f', hidden: true },
        { name: 'stone', value: '#245375', valueDark: '#294051' },
        { name: 'stone-weak', value: '#f4f6f8', valueDark: '#132c3e', hidden: true },
        { name: 'stone-strong', value: '#1f4865', valueDark: '#a2afb8', hidden: true },
    ],
    blue: [
        { name: 'blue-100', value: '#eef4fe', valueDark: '#398bfc' },
        { name: 'blue-200', value: '#a2ceff', valueDark: '#1c457d' },
        { name: 'blue-300', value: '#135ee2', valueDark: undefined },
        { name: 'blue-400', value: '#0a285c', valueDark: undefined },
    ],
    gray: [
        { name: 'gray-100', value: '#eef0f4', valueDark: '#9696aa' },
        { name: 'gray-200', value: '#e4eaf0', valueDark: '#373945' },
        { name: 'gray-300', value: '#a3b5c9', valueDark: '#22222c' },
        { name: 'gray-400', value: '#537091', valueDark: '#14141a' },
    ],
    ui: [
        { name: 'ui-blue', value: '#135ee2', valueDark: '#6daafd' },
        { name: 'ui-blue-weak', value: '#e7effd', valueDark: '#102a4c', hidden: true },
        { name: 'ui-green', value: '#07a721', valueDark: '#59d05e' },
        { name: 'ui-green-weak', value: '#ecffe7', valueDark: '#003808', hidden: true },
        { name: 'ui-red', value: '#cf2a1e', valueDark: '#ff6369' },
        { name: 'ui-red-weak', value: '#feebea', valueDark: '#3c181a', hidden: true },
        { name: 'ui-yellow', value: '#f2a91e', valueDark: '#ffd15e' },
        { name: 'ui-yellow-weak', value: '#fff4d5', valueDark: '#341c00', hidden: true },
        { name: 'ui-yellow-strong', value: '#ad5700', valueDark: '#ffd15e', hidden: true },
        { name: 'ui-gray', value: '#5d6a97', valueDark: '#9ba4c1', hidden: true },
        { name: 'ui-gray-weak', value: '#eff1f5', valueDark: '#272d40', hidden: true },
    ],

    // APP ONLY
    neutral: [
        { name: 'neutral-100', value: '#ffffff', valueDark: '#ffffff' },
        { name: 'neutral-200', value: '#e5e5e5', valueDark: '#7c7c7c' },
        { name: 'neutral-300', value: '#c4c4c4', valueDark: '#636363' },
        { name: 'neutral-400', value: '#000000', valueDark: '#000000' },
    ],

    // DEPRECATED
    shaded: [
        {
            name: 'primary',
            value: {
                100: '#96c2ea',
                200: '#96c2ea',
                300: '#96c2ea',
                400: '#1d69ec',
                500: '#1d69ec',
                600: '#1d69ec',
                700: '#1d69ec',
                800: '#21416c',
                900: '#21416c',
            },
            deprecated: true,
        },
        {
            name: 'secondary',
            value: {
                100: '#e4eaf0',
                200: '#e4eaf0',
                300: '#a3b5c9',
                400: '#a3b5c9',
                500: '#a3b5c9',
                600: '#5c7999',
                700: '#5c7999',
                800: '#5c7999',
                900: '#21416c',
            },
            deprecated: true,
        },
        {
            name: 'grey',
            value: {
                100: '#f4f6fa',
                200: '#e4eaf0',
                300: '#e4eaf0',
                400: '#e4eaf0',
                500: '#a3b5c9',
                600: '#a3b5c9',
                700: '#a3b5c9',
                800: '#5c7999',
                900: '#5c7999',
            },
            deprecated: true,
        },
        {
            name: 'active',
            value: {
                25: '#ffffff',
                50: '#f4f6fa',
                75: '#f4f6fa',
                100: '#f4f6fa',
                200: '#f4f6fa',
                300: '#1d69ec',
                400: '#1d69ec',
                500: '#1d69ec',
                600: '#1d69ec',
                700: '#1d69ec',
                800: '#1d69ec',
                900: '#21416c',
            },
            deprecated: true,
        },
        {
            name: 'red',
            value: {
                25: '#ffffff',
                50: '#e72222',
                75: '#e72222',
                100: '#e72222',
                200: '#e72222',
                300: '#e72222',
                400: '#e72222',
                500: '#e72222',
                600: '#e72222',
                700: '#e72222',
                800: '#e72222',
                900: '#e72222',
            },
            deprecated: true,
        },
        {
            name: 'orange',
            value: {
                25: '#ffffff',
                50: '#f2a91e',
                75: '#f2a91e',
                100: '#f2a91e',
                200: '#f2a91e',
                300: '#f2a91e',
                400: '#f2a91e',
                500: '#f2a91e',
                600: '#f2a91e',
                700: '#f2a91e',
                800: '#f2a91e',
                900: '#f2a91e',
            },
            deprecated: true,
        },
        {
            name: 'yellow',
            value: {
                25: '#ffffff',
                50: '#f2a91e',
                75: '#f2a91e',
                100: '#f2a91e',
                200: '#f2a91e',
                300: '#f2a91e',
                400: '#f2a91e',
                500: '#f2a91e',
                600: '#f2a91e',
                700: '#f2a91e',
                800: '#f2a91e',
                900: '#f2a91e',
            },
            deprecated: true,
        },
        {
            name: 'green',
            value: {
                100: '#00ab56',
                200: '#00ab56',
                300: '#00ab56',
                400: '#00ab56',
                500: '#00ab56',
                600: '#00ab56',
                700: '#00ab56',
                800: '#00ab56',
                900: '#00ab56',
            },
            deprecated: true,
        },
    ],
    // EXTERNAL
    external: [
        { name: 'g-pro', value: '#022e67', valueDark: undefined },
        { name: 'blue-bright', value: '#2870ed', valueDark: undefined },
    ],
    // HIDDEN
    hidden: [
        {
            name: 'secondary',
            value: { 25: '#ffffff', 50: '#e4eaf0', 75: '#e4eaf0' },
            deprecated: true,
        },
        { name: 'sky', value: '#bce4fa', valueDark: undefined },
    ],
};
