import { ICON } from 'utilsTS/icon';

export enum AVATAR_VARIANT {
    DEFAULT = 'default',
    /** @deprecated, use strong/weak colours combination */
    TRANSLUCENT = 'translucent',
    DARK = 'dark',
    BORDERLESS = 'borderless',
}

export enum AVATAR_SIZE {
    SIZE_48 = '48',
    SIZE_64 = '64',
    SIZE_96 = '96',
    SIZE_128 = '128',
}

export enum AVATAR_HOVER_ICON {
    CAMERA = ICON.CAMERA,
}
