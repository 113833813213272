/* eslint-disable @typescript-eslint/no-explicit-any */

import PropTypes, { Requireable, InferProps, ValidationMap } from 'prop-types';
import { isObject } from 'lodash';

import { StructureType } from './constants';

function transformStructureArrayToPropTypes(array: string[]) {
    if (array.length === 0) return {};

    return array.reduce(
        (aggregate, key) => ({
            ...aggregate,
            [key]: PropTypes.string,
        }),
        {},
    );
}

export function transformStructureToPropTypes(
    structure: StructureType | string[],
): Requireable<InferProps<ValidationMap<any>>> {
    if (!isObject(structure)) return PropTypes.shape({});

    if (Array.isArray(structure))
        return PropTypes.shape(transformStructureArrayToPropTypes(structure));

    const shape: ValidationMap<any> = Object.keys(structure).reduce(
        (accumulator, key) => ({
            ...accumulator,
            [key]: transformStructureToPropTypes(structure[key]),
        }),
        {},
    );

    return PropTypes.shape(shape);
}
