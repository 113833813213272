import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cx from 'classnames';

import { Collapsible, CollapsibleHeader, CollapsibleContent } from 'components/Collapsible';
import { Icon } from 'components/Icon';
import { Pill } from 'components/Misc/Pill';
import { Inline } from 'components/Typography';
import { ToggleableContext } from 'utilsTS/hooks/useToggleable';
import { ICON } from 'utilsTS/icon';
import { SecondaryNavigationItemProps } from './SecondaryNavigationItem';

export interface SecondaryNavigationHeadingItemProps extends SecondaryNavigationItemProps {
    label: string;
    icon?: ICON;
    pill?: number;
    badge?: React.ReactNode;
    showIcon?: boolean;
    collapsible?: boolean;
    isOpen?: boolean;
    className?: string;
    onToggle?: (isToggled?: boolean) => void;
}

export const SecondaryNavigationHeadingItem: React.FC<SecondaryNavigationHeadingItemProps> = ({
    label,
    icon,
    pill,
    badge,
    showIcon,
    collapsible = true,
    isOpen = false,
    className,
    children,
    onToggle = noop,
    ...rest
}) => {
    const labelClassName = showIcon && !icon ? 'g-sidenav-offseted-label' : '';

    const heading = (contextIsOpen: boolean | undefined): ReactNode => (
        <>
            <span {...rest} className={cx(collapsible || 'g-sidenav-noninteractive-header')}>
                {icon && showIcon && collapsible && (
                    <Icon
                        icon={icon}
                        color={contextIsOpen ? Icon.COLOR.BLUE_400 : Icon.COLOR.GREY_500}
                        size={Icon.SIZE.SIZE_24}
                        className="g-sidenav-icon"
                    />
                )}
                <Inline emphasis={contextIsOpen} className={cx(labelClassName)}>
                    {label}
                </Inline>
            </span>
            {collapsible && badge}
            {!!pill && collapsible && <Pill value={pill} />}
        </>
    );

    if (!collapsible) {
        return (
            <li className="g-sidenav-section">
                {heading(false)}
                <ul>{children}</ul>
            </li>
        );
    }

    return (
        <Collapsible component="li" className={className} isOpen={isOpen} onToggle={onToggle}>
            <CollapsibleHeader showLabel={false} className="g-sidenav-heading">
                <ToggleableContext.Consumer>
                    {({ isOpen: contextIsOpen }) => heading(contextIsOpen)}
                </ToggleableContext.Consumer>
            </CollapsibleHeader>
            <CollapsibleContent>
                <ul>{children}</ul>
            </CollapsibleContent>
        </Collapsible>
    );
};

SecondaryNavigationHeadingItem.displayName = 'SecondaryNavigationHeadingItem';
SecondaryNavigationHeadingItem.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(Object.values(Icon.ICON)),
    pill: PropTypes.number,
    badge: PropTypes.node,
    showIcon: PropTypes.bool,
    collapsible: PropTypes.bool,
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    onToggle: PropTypes.func,
};
