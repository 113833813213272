import { COMPONENT_REGEXES } from './constants';

// this would need types from @types/react-reconciler which seems wasteful
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getDisplayNameFromNode(node: any): string {
    const component = node && node.type;
    const { displayName } = component || {};
    return displayName || component;
}

// this would need types from @types/react-reconciler which seems wasteful
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function findDomNode(node: any): HTMLElement | null {
    if (!node) return null;

    const { type, stateNode } = node;
    if (typeof type === 'string') return stateNode;
    return findDomNode(node.child);
}

// this would need types from @types/react-reconciler which seems wasteful
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function collectSiblingsAndChildren(node?: any): Element[] {
    if (!node) return [];

    const children = collectSiblingsAndChildren(node.child);
    const siblings = collectSiblingsAndChildren(node.sibling);
    const nodes = [node as Element, ...siblings, ...children];

    return nodes;
}

export function findComponentsInDom(
    element: Element | null,
): ({ displayName: string; element: HTMLElement | null; node: Element } | null)[] {
    if (!element) return [];

    const fiberKey = Object.keys(element).find(
        (key) => key.startsWith('__reactInternalInstance$') || key.startsWith('__reactFiber$'),
    );
    const nodes = collectSiblingsAndChildren(element[fiberKey as keyof typeof element]);

    return nodes
        .map((node) => {
            const displayName = getDisplayNameFromNode(node);
            const domNode = findDomNode(node);

            if (!domNode || !displayName) return null;
            if (!COMPONENT_REGEXES.some((regex) => regex.test(String(displayName)))) return null;

            return {
                displayName,
                element: domNode,
                node,
            };
        })
        .filter(Boolean);
}
