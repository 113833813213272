// *******************************************************************
// * This file is automatically generated with each and every build, *
// * your edits will vanish and your efforts will be in vain... 🤷   *
// *******************************************************************

export enum COLOR {
    BLACK = 'black',
    WHITE = 'white',
    TRANSPARENT = 'transparent',
    GEORGE = 'george',
    OCEAN = 'ocean',
    SKY = 'sky',
    CLOUD = 'cloud',
    GREY_LIGHT = 'grey-light',
    GREY = 'grey',
    GREY_DARK = 'grey-dark',
    HONEY = 'honey',
    HONEY_WEAK = 'honey-weak',
    HONEY_STRONG = 'honey-strong',
    CARROT = 'carrot',
    CARROT_WEAK = 'carrot-weak',
    CARROT_STRONG = 'carrot-strong',
    BERRY = 'berry',
    BERRY_WEAK = 'berry-weak',
    BERRY_STRONG = 'berry-strong',
    ROSE = 'rose',
    ROSE_WEAK = 'rose-weak',
    ROSE_STRONG = 'rose-strong',
    LAVENDER = 'lavender',
    LAVENDER_WEAK = 'lavender-weak',
    LAVENDER_STRONG = 'lavender-strong',
    LILA = 'lila',
    LILA_WEAK = 'lila-weak',
    LILA_STRONG = 'lila-strong',
    INDIGO = 'indigo',
    INDIGO_WEAK = 'indigo-weak',
    INDIGO_STRONG = 'indigo-strong',
    POOL = 'pool',
    POOL_WEAK = 'pool-weak',
    POOL_STRONG = 'pool-strong',
    TEAL = 'teal',
    TEAL_WEAK = 'teal-weak',
    TEAL_STRONG = 'teal-strong',
    LIME = 'lime',
    LIME_WEAK = 'lime-weak',
    LIME_STRONG = 'lime-strong',
    FOREST = 'forest',
    FOREST_WEAK = 'forest-weak',
    FOREST_STRONG = 'forest-strong',
    STONE = 'stone',
    STONE_WEAK = 'stone-weak',
    STONE_STRONG = 'stone-strong',
    BLUE_100 = 'blue-100',
    BLUE_200 = 'blue-200',
    BLUE_300 = 'blue-300',
    BLUE_400 = 'blue-400',
    GRAY_100 = 'gray-100',
    GRAY_200 = 'gray-200',
    GRAY_300 = 'gray-300',
    GRAY_400 = 'gray-400',
    UI_BLUE = 'ui-blue',
    UI_BLUE_WEAK = 'ui-blue-weak',
    UI_GREEN = 'ui-green',
    UI_GREEN_WEAK = 'ui-green-weak',
    UI_RED = 'ui-red',
    UI_RED_WEAK = 'ui-red-weak',
    UI_YELLOW = 'ui-yellow',
    UI_YELLOW_WEAK = 'ui-yellow-weak',
    UI_YELLOW_STRONG = 'ui-yellow-strong',
    UI_GRAY = 'ui-gray',
    UI_GRAY_WEAK = 'ui-gray-weak',
    NEUTRAL_100 = 'neutral-100',
    NEUTRAL_200 = 'neutral-200',
    NEUTRAL_300 = 'neutral-300',
    NEUTRAL_400 = 'neutral-400',
    PRIMARY_100 = 'primary-100',
    PRIMARY_200 = 'primary-200',
    PRIMARY_300 = 'primary-300',
    PRIMARY_400 = 'primary-400',
    PRIMARY_500 = 'primary-500',
    PRIMARY_600 = 'primary-600',
    PRIMARY_700 = 'primary-700',
    PRIMARY_800 = 'primary-800',
    PRIMARY_900 = 'primary-900',
    SECONDARY_100 = 'secondary-100',
    SECONDARY_200 = 'secondary-200',
    SECONDARY_300 = 'secondary-300',
    SECONDARY_400 = 'secondary-400',
    SECONDARY_500 = 'secondary-500',
    SECONDARY_600 = 'secondary-600',
    SECONDARY_700 = 'secondary-700',
    SECONDARY_800 = 'secondary-800',
    SECONDARY_900 = 'secondary-900',
    GREY_100 = 'grey-100',
    GREY_200 = 'grey-200',
    GREY_300 = 'grey-300',
    GREY_400 = 'grey-400',
    GREY_500 = 'grey-500',
    GREY_600 = 'grey-600',
    GREY_700 = 'grey-700',
    GREY_800 = 'grey-800',
    GREY_900 = 'grey-900',
    ACTIVE_25 = 'active-025',
    ACTIVE_50 = 'active-050',
    ACTIVE_75 = 'active-075',
    ACTIVE_100 = 'active-100',
    ACTIVE_200 = 'active-200',
    ACTIVE_300 = 'active-300',
    ACTIVE_400 = 'active-400',
    ACTIVE_500 = 'active-500',
    ACTIVE_600 = 'active-600',
    ACTIVE_700 = 'active-700',
    ACTIVE_800 = 'active-800',
    ACTIVE_900 = 'active-900',
    RED_25 = 'red-025',
    RED_50 = 'red-050',
    RED_75 = 'red-075',
    RED_100 = 'red-100',
    RED_200 = 'red-200',
    RED_300 = 'red-300',
    RED_400 = 'red-400',
    RED_500 = 'red-500',
    RED_600 = 'red-600',
    RED_700 = 'red-700',
    RED_800 = 'red-800',
    RED_900 = 'red-900',
    ORANGE_25 = 'orange-025',
    ORANGE_50 = 'orange-050',
    ORANGE_75 = 'orange-075',
    ORANGE_100 = 'orange-100',
    ORANGE_200 = 'orange-200',
    ORANGE_300 = 'orange-300',
    ORANGE_400 = 'orange-400',
    ORANGE_500 = 'orange-500',
    ORANGE_600 = 'orange-600',
    ORANGE_700 = 'orange-700',
    ORANGE_800 = 'orange-800',
    ORANGE_900 = 'orange-900',
    YELLOW_25 = 'yellow-025',
    YELLOW_50 = 'yellow-050',
    YELLOW_75 = 'yellow-075',
    YELLOW_100 = 'yellow-100',
    YELLOW_200 = 'yellow-200',
    YELLOW_300 = 'yellow-300',
    YELLOW_400 = 'yellow-400',
    YELLOW_500 = 'yellow-500',
    YELLOW_600 = 'yellow-600',
    YELLOW_700 = 'yellow-700',
    YELLOW_800 = 'yellow-800',
    YELLOW_900 = 'yellow-900',
    GREEN_100 = 'green-100',
    GREEN_200 = 'green-200',
    GREEN_300 = 'green-300',
    GREEN_400 = 'green-400',
    GREEN_500 = 'green-500',
    GREEN_600 = 'green-600',
    GREEN_700 = 'green-700',
    GREEN_800 = 'green-800',
    GREEN_900 = 'green-900',
    G_PRO = 'g-pro',
    BLUE_BRIGHT = 'blue-bright',
    SECONDARY_25 = 'secondary-025',
    SECONDARY_50 = 'secondary-050',
    SECONDARY_75 = 'secondary-075',
}
