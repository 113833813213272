import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getColorClassName, COLOR } from 'utilsTS/color';
import { deprecate } from 'utilsTS/error';
import { isElementInChildren } from 'utilsTS/react';

import { ModalContainer, ModalHeader, ModalFooter, ModalSpinner } from './components';
import { ModalProps, propTypes, splitProps } from './props';

/** @deprecated */
export enum SMALL_MODAL_COLOR {
    DEFAULT = 'default',
    GREEN = 'green',
    RED = 'red',
}

export enum SMALL_MODAL_VARIANT {
    DEFAULT = 'default',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface SmallModalProps extends Omit<ModalProps, 'headerIcon' | 'stepping'> {
    /**
     * Defines colour of the title area
     * @deprecated use variant prop instead
     */
    smallColor?: SMALL_MODAL_COLOR;
    /** Defines colour of the title area */
    variant?: SMALL_MODAL_VARIANT;
}

export interface SmallModalStatics {
    /** @deprecated */
    SMALL_COLOR: typeof SMALL_MODAL_COLOR;
    VARIANT: typeof SMALL_MODAL_VARIANT;
}

export const SmallModal: React.FC<SmallModalProps> & SmallModalStatics = ({
    smallColor = SMALL_MODAL_COLOR.DEFAULT,
    variant = SMALL_MODAL_VARIANT.DEFAULT,
    ...rest
}) => {
    deprecate(
        smallColor === SMALL_MODAL_COLOR.GREEN || smallColor === SMALL_MODAL_COLOR.RED,
        'SmallModal.smallColor',
        'Please use SmallModal.variant instead',
    );

    const getColor = () => {
        if (variant === SMALL_MODAL_VARIANT.SUCCESS) return COLOR.UI_GREEN;
        if (variant === SMALL_MODAL_VARIANT.ERROR) return COLOR.UI_RED;
        if (smallColor === SMALL_MODAL_COLOR.GREEN) return COLOR.UI_GREEN;
        if (smallColor === SMALL_MODAL_COLOR.RED) return COLOR.UI_RED;
        return '';
    };

    const { children, containerProps, headerProps, footerProps, loadingProps } = splitProps(rest);
    const color = getColor();

    return (
        <ModalContainer {...containerProps} small>
            <ModalHeader
                {...headerProps}
                small
                icon={undefined}
                stepping={undefined}
                className={cx(
                    getColorClassName(color),
                    headerProps.className,
                    color !== '' && 'g-inverted',
                )}
            />
            <ModalSpinner {...loadingProps}>
                {children}
                {!isElementInChildren(children, ModalFooter) && <ModalFooter {...footerProps} />}
            </ModalSpinner>
        </ModalContainer>
    );
};

SmallModal.SMALL_COLOR = SMALL_MODAL_COLOR;
SmallModal.VARIANT = SMALL_MODAL_VARIANT;

SmallModal.displayName = 'SmallModal';
SmallModal.propTypes = {
    ...propTypes,
    smallColor: PropTypes.oneOf(Object.values(SMALL_MODAL_COLOR)), // DEPRECATED
    variant: PropTypes.oneOf(Object.values(SMALL_MODAL_VARIANT)),
};
