import React from 'react';

import { UIButton } from 'components/Button/index.js';
import { NewButton as Button } from 'components/Button/NewButton';

import { error } from 'utilsTS/error';
import { cloneElement, isElementOfComponent } from 'utilsTS/react';

import { StripeContext } from '../StripeContext';

export const StripeHeaderLeft: React.FC = () => {
    const {
        left = null,
        isLargeVariant,
        isMinMediumTier,
        isMinLargeTier,
    } = React.useContext(StripeContext);

    error(
        !isLargeVariant &&
            !!left &&
            !(
                isElementOfComponent(left, Button) ||
                isElementOfComponent(left, 'StripeProductInfo')
            ),
        'Use Button or StripeProductInfo in the `left` prop',
    );

    /* istanbul ignore if */
    if (isElementOfComponent(left, UIButton)) {
        return cloneElement(left, {
            iconLeft: true,
            color: UIButton.COLOR.WHITE,
        }) as React.ReactElement;
    }

    if (isElementOfComponent(left, Button)) {
        return cloneElement(left, {
            variant: Button.VARIANT.TERTIARY,
            size: isMinMediumTier ? Button.SIZE.MEDIUM : Button.SIZE.SMALL,
            hiddenLabel: Button.HIDDEN_LABEL.BELOW_MD,
            fullWidth: Button.FULL_WIDTH.NEVER,
        }) as React.ReactElement;
    }

    if (isElementOfComponent(left, 'StripeTitle')) {
        if (!isMinLargeTier) return null;
        return left as React.ReactElement;
    }

    if (isMinMediumTier) {
        return left as React.ReactElement;
    }

    if (isElementOfComponent(left, 'StripeProductInfo') && React.isValidElement(left)) {
        const { props } = left as React.ReactElement;
        const { backButton } = props; // eslint-disable-line react/prop-types

        if (backButton) {
            return cloneElement(backButton, {
                variant: Button.VARIANT.TERTIARY,
                icon: Button.ICON.CARET_LEFT,
                size: Button.SIZE.SMALL,
                hiddenLabel: Button.HIDDEN_LABEL.ALWAYS,
                fullWidth: Button.FULL_WIDTH.NEVER,
            }) as React.ReactElement;
        }
    }

    return null;
};

StripeHeaderLeft.displayName = 'StripeHeaderLeft';
