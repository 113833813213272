import { checkDOM } from './checkDOM';

export function toggleClass(
    selector: string,
    className: string,
    force?: boolean,
    { documentNode = document } = {},
): void {
    /* istanbul ignore if */
    if (!checkDOM()) return;

    const elements = selector ? documentNode.querySelectorAll(selector) : [];

    Array.prototype.filter.call(elements, (element: Element) => {
        const { classList } = element;

        /* istanbul ignore if */
        if (!classList) return;

        classList.toggle(className, force);
    });
}
