import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export interface VisuallyHiddenProps extends React.ComponentPropsWithoutRef<'span'> {
    /** Defines whether content should be visually hidden */
    active?: boolean;
    /** Defines whether content should be excluded from user selection */
    noClipboard?: boolean;
    /** Accepts a tag or a component replacing the wrapper */
    tag?: React.ElementType;
}

export const VisuallyHidden: React.FC<VisuallyHiddenProps> = ({
    active = true,
    noClipboard = false,
    children,
    className,
    tag: Tag = 'span',
    ...rest
}) => {
    if (!children && typeof Tag === 'string') return null;
    const classNames = cx(active && 'sr-only', noClipboard && 'no-clipboard', className);

    return (
        <Tag {...rest} className={classNames}>
            {children}
        </Tag>
    );
};

VisuallyHidden.displayName = 'VisuallyHidden';
VisuallyHidden.propTypes = {
    active: PropTypes.bool,
    noClipboard: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
};
