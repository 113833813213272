import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { LanguageContext } from 'containers/LanguageProvider';

import { Text } from 'components/Layout';
import { Icon } from 'components/Icon';

import styles from './Link.scss';

export interface LinkProps extends React.ComponentPropsWithRef<'a'> {
    /** Defines href attribute */
    href?: string;
    /** Defines target attribute */
    target?: string;
    /** Defines rel attribute */
    rel?: string;
    /** Defines whether the link should be rendered as disabled */
    disabled?: boolean;
    /** Defines whether href points to an external URL */
    external?: boolean;
}

export const Link: React.ForwardRefExoticComponent<LinkProps> = React.forwardRef(
    (
        {
            href = '',
            target,
            rel,
            disabled,
            external,
            onClick = noop,
            className,
            children,
            ...rest
        },
        forwardedRef,
    ) => {
        const { getString } = React.useContext(LanguageContext);

        const linkProps = disabled
            ? {
                  role: 'link',
                  'aria-disabled': true,
              }
            : {
                  href,
                  target: external ? '_blank' : target,
                  rel: external ? 'noopener noreferrer' : rel,
              };

        const handleClick: React.MouseEventHandler = (event) => {
            event.stopPropagation();
            if (!href) event.preventDefault();

            onClick(event);
        };

        return (
            <Text
                {...rest}
                // @ts-expect-error forwardedRef is type of HTMLAnchorElement
                ref={forwardedRef}
                always
                tag="a"
                className={className}
                {...linkProps}
                onClick={handleClick}
            >
                {children}
                {external && (
                    <Icon
                        ariaLabel={` ${getString('common.externalLink')}`}
                        icon={Icon.ICON.ARROW_OUT}
                        size={Icon.SIZE.SIZE_24}
                        className={cx(styles.external)}
                    />
                )}
            </Text>
        );
    },
);

Link.displayName = 'Link';
Link.propTypes = {
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
};
