import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from '../Modal.scss';

export type ModalBackdropProps = React.ComponentPropsWithoutRef<'div'>;

export const ModalBackdrop: React.FC<ModalBackdropProps> = ({ className, ...rest }) => (
    <div {...rest} className={cx('modal-backdrop', styles.backdrop, className)} />
);

ModalBackdrop.displayName = 'ModalBackdrop';

ModalBackdrop.propTypes = {
    className: PropTypes.string,
};
