import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export interface RowProps extends React.ComponentPropsWithRef<'div'> {
    /** Accepts a tag or a component replacing the wrapper */
    tag?: React.ElementType;
    /** Defines whether column gutters should be used */
    noGutters?: boolean;
}

export const Row: React.ForwardRefExoticComponent<RowProps> = React.forwardRef(
    ({ tag: Tag = 'div', noGutters, className, ...rest }, forwardedRef) => {
        const classNames = cx('row', noGutters && 'no-gutters', className);

        return <Tag ref={forwardedRef} {...rest} className={classNames} />;
    },
);

Row.displayName = 'Row';
Row.propTypes = {
    tag: PropTypes.elementType as React.Validator<React.ElementType>,
    noGutters: PropTypes.bool,
    className: PropTypes.string,
};
