import React from 'react';
import PropTypes from 'prop-types';
import type { Locale } from 'date-fns';

import { processTemplateString } from 'utilsTS/string';

import { KEYS, STRUCTURE } from './constants';
import { type LanguageGetString, type LanguageGetFormatString } from './types';
import { LanguageContext, LanguageContextTypes } from './LanguageContext';
import { getKeyFromStrings } from './getKeyFromStrings';
import { transformStructureToKeys } from './transformStructureToKeys';
import { transformStructureToPropTypes } from './transformStructureToPropTypes';

export interface StringsStructure {
    common?: {
        clear?: string;
        close?: string;
        collapse?: string;
        expand?: string;
        externalLink?: string;
        help?: string;
        more?: string;
        previous?: string;
        next?: string;
        page?: string;
    };
    format?: {
        date?: string;
        isoDate?: string;
        screenreaderDate?: string;
    };
    accountNumber?: {
        mask?: string;
    };
    amount?: {
        currency?: {
            [key: string]: string;
        };
    };
    dropdown?: {
        selectYear?: string;
    };
    fixed?: {
        fixedButton?: {
            backToTop?: string;
        };
    };
    form?: {
        label?: {
            optional?: string;
        };
    };
    input?: {
        date?: {
            button?: string;
            rangeButton?: string;
            nextMonth?: string;
            previousMonth?: string;
        };
        file?: {
            browse?: string;
            delete?: string;
            drag?: string;
            drop?: string;
            uploading?: string;
        };
        select?: {
            selectAll?: string;
            selectedItems?: string;
        };
        productSelect?: {
            selectAll?: string;
            selectedItems?: string;
        };
        time?: {
            hoursAnnouncement?: string;
            minutesAnnouncement?: string;
        };
    };
    spinner?: {
        message?: string;
    };
    stepper?: {
        compactInfo?: string;
    };
    table?: {
        sort?: {
            column?: string;
            select?: string;
            ascending?: string;
            descending?: string;
        };
    };
    transaction?: {
        header?: {
            date?: string;
            title?: string;
            info?: string;
            titleSecondary?: string;
            infoSecondary?: string;
            amount?: string;
            amountSecondary?: string;
            avatar?: string;
            badges?: string;
            indicators?: string;
            misc?: string;
            statement?: string;
            valuta?: string;
            menu?: string;
            overlay?: string;
            detail?: string;
            selectable?: string;
        };
        format?: {
            date?: string;
            largeDate?: string;
            largeDateYear?: string;
        };
        indicator?: {
            unread?: string;
            star?: string;
            attachment?: string;
            file?: string;
            location?: string;
            additionalCard?: string;
        };
    };
}

/** @deprecated */
export interface StringsShape {
    additionalInformation: string;
    amount: string;
    browseFiles: string;
    categoriesOrState: string;
    close: string;
    collapse: string;
    date: string;
    dateButton: string;
    delete: string;
    description: string;
    details: string;
    dragFiles: string;
    dropFiles: string;
    expand: string;
    help: string;
    indicators: string;
    loading: string;
    optional: string;
    selectable: string;
    selectAll: string;
    selectAllProducts: string;
    selectedItems: string;
    selectedProducts: string;
    stepperLabel: string;
    title: string;
    type: string;
    uploading: string;
}

export interface LanguageProviderProps {
    children: React.ReactNode;

    /** Defines the locale of the output, either as iso code or complete date-fns locale object */
    locale: string | Locale;
    /** Defines all the different langauge strings to be passed to other components through context */
    strings: StringsStructure | StringsShape;
    /** Defines the time zone to format the date in, by default system time zone */
    timeZone?: string;
}

export interface LanguageProviderStatics {
    KEYS: typeof KEYS;
    STRUCTURE_KEYS: string[];
}

export const LanguageProvider: React.FC<LanguageProviderProps> & LanguageProviderStatics = ({
    locale,
    strings,
    timeZone,
    children,
}) => {
    const previousContext = React.useContext(LanguageContext);

    const getString = React.useCallback<LanguageGetString>(
        (key, value = null, replacements = {}) => {
            if (value && typeof value !== 'string') return value;
            const string =
                value === null ? getKeyFromStrings(key, strings as Record<string, string>) : value; // TODO: unify the types used in props and in utils
            return processTemplateString(string as string, replacements);
        },
        [strings],
    );

    const getFormatString = React.useCallback<LanguageGetFormatString>(
        (key, fallback, value = null) => {
            const string = (value ?? getString(key)) || fallback;
            if (typeof string !== 'string') return fallback;
            if (string === key) return fallback;
            return string;
        },
        [getString],
    );

    const isString = typeof locale === 'string';
    const localLang = isString ? locale : locale?.code;
    const localLocale = isString ? undefined : locale;

    const lang = localLang || previousContext.lang; // or default context

    const context: LanguageContextTypes = React.useMemo(
        () => ({
            ...previousContext,
            lang,
            locale: localLocale,
            timeZone,
            getString,
            getFormatString,
        }),
        [previousContext, lang, localLocale, timeZone, getString, getFormatString],
    );

    return <LanguageContext.Provider value={context}>{children}</LanguageContext.Provider>;
};
LanguageProvider.KEYS = KEYS;
LanguageProvider.STRUCTURE_KEYS = transformStructureToKeys(STRUCTURE);

LanguageProvider.displayName = 'LanguageProvider';
LanguageProvider.propTypes = {
    locale: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    strings: PropTypes.oneOfType([
        transformStructureToPropTypes(STRUCTURE),
        // DEPRECATED
        PropTypes.shape(
            KEYS.reduce(
                (previous, key) => ({
                    ...previous,
                    [key]: PropTypes.string,
                }),
                {},
            ),
        ),
    ]).isRequired,
    timeZone: PropTypes.string,
    children: PropTypes.node.isRequired,
};
