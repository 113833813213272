import { deprecations, legacy } from 'gds-common/icons.js';
import { deprecate, error } from 'utilsTS/error';
import { LARGE_ICON_SUFFIX, ICON_SIZE } from './size';
import { iconsSvg as icons } from './iconsSvg';

export type IconData = {
    id: string;
    viewBox: string;
    svg: string;
};

function extractIcon(id: string, iconsSvg: string): IconData {
    const regex = new RegExp(`<symbol\\s+id="${id}"\\s+viewBox="(.*?)".*?>([\\s\\S]*?)</symbol>`);
    const [, viewBox, svg] = regex.exec(iconsSvg) || [];

    return {
        id,
        viewBox,
        svg,
    };
}

function getSmallIconData(id: string) {
    if (id.endsWith(LARGE_ICON_SUFFIX)) {
        return extractIcon(id.slice(0, id.length - LARGE_ICON_SUFFIX.length), icons);
    }

    return extractIcon(id, icons);
}

function getLargeIconData(id: string) {
    if (id.endsWith(LARGE_ICON_SUFFIX)) {
        return extractIcon(id, icons);
    }

    return extractIcon(id + LARGE_ICON_SUFFIX, icons);
}

function getIconData(name: string, size?: ICON_SIZE, useDetailed?: boolean) {
    const id = `ico-${name}`;
    const isLegacy = legacy.includes(name);
    const isLarge = Number.parseInt(size || '', 10) >= Number.parseInt(ICON_SIZE.SIZE_48, 10);
    const shouldUseDetailed = useDetailed || isLarge;

    if (isLegacy) {
        return shouldUseDetailed ? getSmallIconData(id) : extractIcon(id, '');
    }
    if (shouldUseDetailed) {
        return getLargeIconData(id);
    }
    return getSmallIconData(id);
}

export function getIcon(name: string, size?: ICON_SIZE, useDetailed?: boolean): IconData {
    const deprecation = deprecations.find(({ icon }: { icon: string }) => icon === name) || {};
    const iconData = getIconData(deprecation.alias || name, size, useDetailed || false);

    deprecate(
        deprecation?.icon,
        `The ${deprecation.icon} icon`,
        `Please use ${deprecation.alias} instead.`,
    );

    error(
        !iconData.svg,
        `Icon "%s" does not exist in size "${size}"! Please check the list of available icons and whether it exists in this size.`,
        name,
    );

    return iconData;
}
