import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './PageContent.module.scss';

export interface PageContentProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Defines whether Card paddings inside should be increased */
    increasePaddings?: boolean;
}

export const PageContent: React.FC<PageContentProps> = ({
    increasePaddings = true,
    className,
    ...rest
}) => (
    <div
        className={cx('g-page-content', increasePaddings && styles.content, className)}
        {...rest}
    />
);

PageContent.displayName = 'PageContent';
PageContent.propTypes = {
    increasePaddings: PropTypes.bool,
    className: PropTypes.string,
};
