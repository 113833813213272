import React from 'react';

export function useLatest<T>(value: T): React.RefObject<T> {
    const ref = React.useRef(value);

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref;
}
