/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import PropTypes from 'prop-types';
import FocusTrapComponent from 'focus-trap-react';

import { checkDOM } from 'utilsTS/dom';
import { omitProps } from 'utilsTS/react';

import styles from './FocusTrap.scss';

export interface FocusTrapProps extends FocusTrapComponent.Props {
    innerRef?: React.Ref<HTMLElement>;
    /** Accepts a tag or a component replacing the wrapper */
    tag?: React.ElementType;
    /** Defines whether a fake button should be used for the functionality */
    useFakeButton?: boolean;
    /** Defines whether focus should be restored upon deactivation */
    restoreFocus?: boolean;
    /** Is called when the container is clicked */
    onClick?: React.MouseEventHandler;
    /** Is called when the component is activated */
    onActivate?: () => void;
    /** Is called when the component is deactivated */
    onDeactivate?: () => void;
}

export class FocusTrap extends React.Component<
    FocusTrapProps,
    {
        activeElement: DocumentOrShadowRoot['activeElement'];
        blurred: boolean;
        hasNoFocusableElements: boolean;
    }
> {
    static displayName = 'FocusTrap';

    static propTypes = {
        innerRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.shape({}) }),
        ]),
        active: PropTypes.bool,
        tag: PropTypes.elementType as React.Validator<React.ElementType>,
        useFakeButton: PropTypes.bool,
        restoreFocus: PropTypes.bool,
        onClick: PropTypes.func,
        onActivate: PropTypes.func,
        onDeactivate: PropTypes.func,
        children: PropTypes.node,
    };

    static defaultProps = {
        innerRef: null,
        active: true,
        tag: 'div',
        useFakeButton: true,
        restoreFocus: true,
        onClick: null,
        onActivate: null,
        onDeactivate: null,
        children: null,
    };

    state = {
        activeElement: null,
        blurred: false,
        hasNoFocusableElements: false,
    };

    componentDidMount() {
        this.saveFocus();
    }

    componentDidCatch() {
        this.setState({ hasNoFocusableElements: true });
    }

    getSnapshotBeforeUpdate(previousProps: FocusTrapProps) {
        const { active } = this.props;

        if (active && !previousProps.active) {
            this.saveFocus();
        }

        return null;
    }

    componentDidUpdate(previousProps: FocusTrapProps) {
        const { active } = this.props;

        if (!active && previousProps.active) {
            this.restoreFocus();
        }
    }

    componentWillUnmount() {
        const { active } = this.props;

        if (active) this.restoreFocus();
    }

    handleBlur = () => {
        this.setState({ blurred: true });
    };

    handleClick: React.MouseEventHandler = (event) => {
        const { onClick } = this.props;

        event.stopPropagation();
        onClick?.(event);
    };

    saveFocus() {
        /* istanbul ignore else */
        if (checkDOM()) {
            this.setState({ activeElement: document.activeElement, blurred: false });
        }
    }

    restoreFocus() {
        const { restoreFocus } = this.props;
        const { activeElement } = this.state;

        if (!restoreFocus) return;

        /* istanbul ignore else */
        // @ts-expect-error activeElement is of type never
        if (activeElement && activeElement.focus) activeElement.focus();
        this.setState({ activeElement: null, blurred: false });
    }

    renderFakeButton() {
        const { active, useFakeButton } = this.props;
        const { blurred, hasNoFocusableElements } = this.state;

        if (!active || blurred) return null;
        if (!useFakeButton && !hasNoFocusableElements) return null;

        return (
            <button
                aria-hidden
                type="button"
                tabIndex={0}
                className={styles.button}
                onBlur={this.handleBlur}
            />
        );
    }

    render() {
        const {
            innerRef,
            active,
            tag: Tag = 'div',
            onActivate,
            onDeactivate,
            focusTrapOptions,
            children,
            ...rest
        } = this.props;

        const options: FocusTrapProps['focusTrapOptions'] = {
            onActivate,
            onDeactivate,
            clickOutsideDeactivates: true,
            returnFocusOnDeactivate: false,
            ...focusTrapOptions,
        };

        return (
            <FocusTrapComponent active={active} focusTrapOptions={options}>
                <Tag {...omitProps(rest, FocusTrap)} ref={innerRef} onClick={this.handleClick}>
                    {this.renderFakeButton()}
                    {children}
                </Tag>
            </FocusTrapComponent>
        );
    }
}
