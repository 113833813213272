import React from 'react';
import PropTypes from 'prop-types';

import { requiredIf } from 'utilsTS/prop-types';

import {
    AlertContainer,
    AlertContainerProps,
    AlertContainerStatics,
    AlertContent,
    AlertContentProps,
} from './components';

export interface AlertProps extends AlertContainerProps, AlertContentProps {}

export const Alert: React.FC<AlertProps> & AlertContainerStatics = ({
    heading,
    buttons,
    children,
    ...rest
}) => (
    <AlertContainer {...rest} cta={false} heading={heading}>
        <AlertContent heading={heading} buttons={buttons}>
            {children}
        </AlertContent>
    </AlertContainer>
);

Alert.VARIANT = AlertContainer.VARIANT;
Alert.SIZE = AlertContainer.SIZE;
Alert.ICON = AlertContainer.ICON;

Alert.displayName = 'Alert';
Alert.propTypes = {
    ...AlertContainer.propTypes,
    ...AlertContent.propTypes,
    heading: requiredIf(PropTypes.node, ({ closeable }) => closeable),
};
