import React from 'react';
import { ThemeContext } from './ThemeContext';
import type { ThemeContextType } from './ThemeContext';

export function useTheme(context?: ThemeContextType): ThemeContextType {
    const defaultContext: ThemeContextType = {
        useFluidGrid: false,
        useExtendedGrid: false,
    };
    const currentContext = React.useContext(ThemeContext) || {};

    return {
        ...defaultContext,
        ...currentContext,
        ...context,
    };
}
