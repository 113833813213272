import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { COLOR, getColorClassName } from 'utilsTS/color';

import type { OptionalTiersObject } from 'utilsTS/react';
import { tieredProp } from 'utilsTS/prop-types';
import { useMatchMediaTierProp } from 'containers/MatchMediaProvider';

import { StripeContext } from '../StripeContext';
import { StripeHeaderBasicGrid } from './StripeHeaderBasicGrid';
import { StripeHeaderLargeGrid } from './StripeHeaderLargeGrid';
import { StripeHeaderToolbarGrid } from './StripeHeaderToolbarGrid';
import { StripeHeaderScrollTransition } from './StripeHeaderScrollTransition';

import styles from './StripeHeaderBasicGrid.scss';

export interface StripeHeaderGridProps extends React.ComponentPropsWithoutRef<'div'> {
    /** Defines the background colour of the Stripe */
    color?: COLOR;
    /** Accepts a hex colour to override the background colour */
    colorValue?: string;
    /** Defines whether the Stripe should be sticky to the top of the viewport */
    sticky?: boolean;
    /** Defines the (tiered) offset from the top of the viewport */
    stickyOffset?: number | OptionalTiersObject<number>;
}

export const StripeHeaderGrid: React.FC<StripeHeaderGridProps> = ({
    color = COLOR.BLUE_BRIGHT,
    colorValue,
    style = {},
    sticky,
    stickyOffset = 0,
    className,
    children,
    ...rest
}) => {
    const { toolbar, isLargeVariant, isTransparentVariant, isMinLargeTier, useTransition } =
        React.useContext(StripeContext);

    const showLargeGrid = isLargeVariant || useTransition;
    const showBasicGrid = !isLargeVariant || useTransition;
    const isLargeSticky = sticky && !useTransition;
    const hasToolbar = toolbar && isLargeVariant;
    const colorClassName = isTransparentVariant ? undefined : getColorClassName(color);
    const classNames = cx(
        'g-stripe',
        !isTransparentVariant && 'g-inverted',
        !colorValue && colorClassName,
        styles.container,
        className,
    );
    const appliedStickyOffset = useMatchMediaTierProp(stickyOffset);

    const colorStyle = colorValue
        ? {
              '--color-bg': colorValue,

              backgroundColor: colorValue,
          }
        : {};

    const stickyStyle = {
        top: sticky ? appliedStickyOffset : undefined,
    };

    const largeStickyStyle = isLargeSticky ? stickyStyle : {};

    return (
        <StripeHeaderScrollTransition stickyOffset={appliedStickyOffset}>
            {({
                largeRef,
                stickyRef,
                toolbarRef,
                heights,
                scrollProgress,
                isTransitioningToSticky,
            }) => (
                <>
                    {showLargeGrid ? (
                        <div
                            {...rest}
                            ref={largeRef}
                            className={cx(classNames, isLargeSticky && styles.sticky)}
                            style={{ ...style, ...colorStyle, ...largeStickyStyle }}
                        >
                            {isMinLargeTier ? null : (
                                <StripeHeaderBasicGrid
                                    className={cx(
                                        useTransition && isTransitioningToSticky && styles.hidden,
                                    )}
                                />
                            )}
                            <StripeHeaderLargeGrid
                                showLeft={isMinLargeTier}
                                style={useTransition ? { opacity: 1 - scrollProgress.large } : {}}
                            >
                                {children}
                            </StripeHeaderLargeGrid>
                        </div>
                    ) : null}
                    {showBasicGrid ? (
                        <div
                            {...rest}
                            ref={stickyRef}
                            className={cx(
                                classNames,
                                sticky && styles.sticky,
                                useTransition && styles.useTransition,
                                useTransition && !isTransitioningToSticky && styles.hidden,
                            )}
                            style={{ ...style, ...colorStyle, ...stickyStyle }}
                        >
                            <StripeHeaderBasicGrid
                                style={useTransition ? { opacity: scrollProgress.sticky } : {}}
                            />
                        </div>
                    ) : null}
                    {hasToolbar ? (
                        <StripeHeaderToolbarGrid
                            ref={toolbarRef}
                            sticky={isLargeSticky}
                            className={colorClassName}
                            style={{
                                ...colorStyle,

                                top: isLargeSticky
                                    ? appliedStickyOffset + heights.large - 1
                                    : undefined,
                            }}
                        />
                    ) : null}
                </>
            )}
        </StripeHeaderScrollTransition>
    );
};

StripeHeaderGrid.displayName = 'StripeHeaderGrid';
StripeHeaderGrid.propTypes = {
    color: PropTypes.oneOf(Object.values(COLOR)),
    colorValue: PropTypes.string,
    sticky: PropTypes.bool,
    stickyOffset: tieredProp(PropTypes.number),
    style: PropTypes.shape({}),
    className: PropTypes.string,
    children: PropTypes.node,
};
