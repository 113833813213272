import { warning } from 'utilsTS/error';

import { type LanguageGetFormatString } from './types';

export const getFormatStringFallback: LanguageGetFormatString = (key, fallback, value = null) => {
    if (value !== null) return value;

    warning(
        true,
        '"%s" language format string was requested by a component, but there is no LanguageProvider or component-specific string present',
        key,
    );

    return fallback;
};
