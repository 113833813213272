import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CardBody } from 'components/Card';
import { Text } from 'components/Layout';

export interface FunctionCardContentProps
    extends Omit<React.ComponentPropsWithoutRef<'div'>, 'color'> {
    /** Defines the card heading */
    heading?: React.ReactNode;
    /** Defines the heading tag */
    headingTag?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const FunctionCardContent: React.FC<FunctionCardContentProps> = ({
    heading,
    headingTag = 'h4',
    className,
    children,
    ...rest
}) => {
    if (!children && !heading) return null;

    const renderHeading = () => {
        if (React.isValidElement(heading)) return heading;
        return <Text tag={headingTag}>{heading}</Text>;
    };

    return (
        <CardBody className={cx('g-card-function-body', className)} {...rest}>
            {renderHeading()}
            <Text>{children}</Text>
        </CardBody>
    );
};

FunctionCardContent.displayName = 'FunctionCardContent';
FunctionCardContent.propTypes = {
    heading: PropTypes.node,
    headingTag: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6']),
    className: PropTypes.string,
    children: PropTypes.node,
};
