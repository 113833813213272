import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { NewButton as Button, ButtonProps } from 'components/Button/NewButton';

import { SnackbarItemContext } from './SnackbarItemContext';
import styles from './Snackbar.scss';

export const SnackbarButton: React.FC<ButtonProps> = ({ className, onClick = noop, ...rest }) => {
    const { close } = React.useContext(SnackbarItemContext);

    const handleClick: React.MouseEventHandler = (event) => {
        onClick(event);
        close();
    };

    return (
        <Button
            {...rest}
            variant={Button.VARIANT.SECONDARY}
            fullWidth={Button.FULL_WIDTH.NEVER}
            size={Button.SIZE.MEDIUM}
            className={cx(styles.button, 'g-inverted', className)}
            onClick={handleClick}
        />
    );
};

SnackbarButton.displayName = 'SnackbarButton';
SnackbarButton.propTypes = {
    ...Button.propTypes,
};
