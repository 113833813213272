import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { createContext } from 'utilsTS/react';

export interface ToggleableProps {
    /** Defines whether component should load in open state */
    isOpen?: boolean;
    /** Is called immediatelly when state changes */
    onToggle?: (isToggled?: boolean) => void;
    /** Is called immediately when state changes to open, return false to cancel */
    onBeforeOpen?: () => void | boolean;
    /** Is called after animations finish when state changes to open */
    onOpen?: () => void;
    /** Is called immediatelly when state changes to closed, return false to cancel */
    onBeforeClose?: () => void | boolean;
    /** Is called after animations finish when state changes to closed */
    onClose?: () => void;
}

export const toggleablePropTypes = {
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    onBeforeOpen: PropTypes.func,
    onOpen: PropTypes.func,
    onBeforeClose: PropTypes.func,
    onClose: PropTypes.func,
};

export interface ToggleableContextTypes {
    isOpen?: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;
    onToggle: () => void;
    onBeforeOpen: () => void | boolean;
    onOpen: () => void;
    onBeforeClose: () => void | boolean;
    onClose: () => void;
    id?: string;
}

export const toggleableContextDefaults = {
    isOpen: false,

    open: noop,
    close: noop,
    toggle: noop,

    onToggle: noop,
    onBeforeOpen: noop,
    onOpen: noop,
    onBeforeClose: noop,
    onClose: noop,

    id: '',
};

export const ToggleableContext = createContext<ToggleableContextTypes>('ToggleableContext', {
    ...toggleableContextDefaults,
});

export function useToggleable(
    defaultValue = false,
    controlledValue?: boolean,
    {
        onToggle = noop,
        onBeforeOpen = () => true,
        onOpen = noop,
        onBeforeClose = () => true,
        onClose = noop,
    }: Omit<ToggleableProps, 'isOpen'> = {},
): [
    boolean,
    {
        open: () => void;
        close: () => void;
        toggle: () => void;
    },
] {
    const [value, setValue] = React.useState(controlledValue ?? defaultValue);

    const open = React.useCallback(() => {
        if (onBeforeOpen() !== false) {
            setValue(true);
            onToggle(true);
            onOpen();
        }
    }, [onToggle, onBeforeOpen, onOpen]);

    const close = React.useCallback(() => {
        if (onBeforeClose() !== false) {
            setValue(false);
            onToggle(false);
            onClose();
        }
    }, [onToggle, onBeforeClose, onClose]);

    const toggle = React.useCallback(() => {
        if (value) close();
        else open();
    }, [value, open, close]);

    React.useEffect(() => {
        if (controlledValue !== undefined && controlledValue !== value) {
            if (controlledValue === true) open();
            else close();
        }
    }, [controlledValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return [value, { open, close, toggle }];
}
